import React from 'react';
import PropTypes from 'prop-types';
import styles from './pie.module.scss';
import classNames from 'classnames/bind';
import { Colors } from '@components/variables';
import { Title, Content } from '@components/text';
const cx = classNames.bind(styles);
import { ResponsivePie } from '@nivo/pie';

const PieChart = (props) => {
  let classes = cx('chart', props.className, props.data);
  return (
    <div className={classes}>
      <ResponsivePie
        colors={(d) => `rgba(var(--color-${d.color}), 1)`}
        data={props.data}
        innerRadius={0.7}
        padAngle={3}
        fit={true}
        enableRadialLabels={false}
        enableSlicesLabels={false}
        //Legends is hidden with CSS
        legends={[]}
        tooltip={({ id, label, value, percentage }) => {
          return (
            <div className={styles.tooltip}>
              <Content text={label} align={'center'} />
              <Title text={percentage ? percentage + '%' : value} size={'h2'} align={'center'} />
            </div>
          );
        }}
        theme={{
          tooltip: {
            container: {
              borderRadius: 'var(--radius-m)',
              boxShadow: 'var(--shadow-s)',
              padding: 'var(--spacing-xxs) var(--spacing-s)',
              border: '1px solid rgba(var(--color-stable-700), 1)',
            },
          },
        }}
      />
    </div>
  );
};

PieChart.propTypes = {
  /** The data of the chart */
  data: PropTypes.arrayOf(PropTypes.shape(ResponsivePie.propTypes)),
};

//Needed for Storybook
PieChart.displayName = 'PieChart';

export default PieChart;

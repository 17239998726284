import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={20}
      height={21}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 19.477v.026c0 .55-.448.945-1 .945H2v.052c-1.105 0-2-.84-2-1.942V5.592a.998.998 0 011-.998c.552 0 1 .446 1 .998V17.56c0 .55.448.892 1 .892h12c.552 0 1 .473 1 1.024zm2-5.906c0 .55-.448.893-1 .893H7c-.552 0-1-.342-1-.893V3.597c0-.552.448-1.102 1-1.102h5v4.094c0 1.101.895 1.89 2 1.89h4v5.092zm1.707-7.3L14.293.844C14.105.658 13.851.5 13.586.5H6v.052c-1.105 0-2 .92-2 2.02v11.996c0 1.102.895 1.942 2 1.942v-.052h12v.052c1.105 0 2-.866 2-1.969V7.002c0-.264-.105-.544-.293-.731z'
        fill='currentColor'
      />
    </svg>
  );
}

export default SvgComponent;


import * as React from 'react';

function SvgCirclesBottomRight(props) {
  return (
    <svg width={506} height={255} fill='none' {...props}>
      <g opacity={0.08}>
        <mask
          id='circles-bottom-right_svg__a'
          maskUnits='userSpaceOnUse'
          x={0}
          y={0}
          width={506}
          height={256}
        >
          <path
            d='M164.415 256S19.213 230.841 2.612 159C-17.49 72.008 82.248 2.948 171.407 5c78.499 1.806 78.415 121.54 156.809 126C414.282 135.897 506 0 506 0v256H164.415z'
            fill='#fff'
          />
        </mask>
        <g
          clipPath='url(#circles-bottom-right_svg__clip0)'
          fill='#E8A32E'
          mask='url(#circles-bottom-right_svg__a)'
        >
          <g clipPath='url(#circles-bottom-right_svg__clip1)'>
            <circle cx={2.622} cy={11.5} r={4.5} />
            <circle cx={26.622} cy={11.5} r={4.5} />
            <circle cx={50.622} cy={11.5} r={4.5} />
            <circle cx={74.622} cy={11.5} r={4.5} />
            <circle cx={98.622} cy={11.5} r={4.5} />
            <circle cx={122.622} cy={11.5} r={4.5} />
            <circle cx={146.622} cy={11.5} r={4.5} />
            <circle cx={170.622} cy={11.5} r={4.5} />
            <circle cx={194.622} cy={11.5} r={4.5} />
            <circle cx={218.622} cy={11.5} r={4.5} />
            <circle cx={242.622} cy={11.5} r={4.5} />
            <circle cx={266.622} cy={11.5} r={4.5} />
            <circle cx={290.622} cy={11.5} r={4.5} />
            <circle cx={314.622} cy={11.5} r={4.5} />
            <circle cx={338.622} cy={11.5} r={4.5} />
            <circle cx={362.622} cy={11.5} r={4.5} />
            <circle cx={386.622} cy={11.5} r={4.5} />
            <circle cx={410.622} cy={11.5} r={4.5} />
            <circle cx={434.622} cy={11.5} r={4.5} />
            <circle cx={458.622} cy={11.5} r={4.5} />
            <circle cx={482.622} cy={11.5} r={4.5} />
            <circle cx={506.622} cy={11.5} r={4.5} />
          </g>
          <g clipPath='url(#circles-bottom-right_svg__clip2)'>
            <circle cx={2.622} cy={35.5} r={4.5} />
            <circle cx={26.622} cy={35.5} r={4.5} />
            <circle cx={50.622} cy={35.5} r={4.5} />
            <circle cx={74.622} cy={35.5} r={4.5} />
            <circle cx={98.622} cy={35.5} r={4.5} />
            <circle cx={122.622} cy={35.5} r={4.5} />
            <circle cx={146.622} cy={35.5} r={4.5} />
            <circle cx={170.622} cy={35.5} r={4.5} />
            <circle cx={194.622} cy={35.5} r={4.5} />
            <circle cx={218.622} cy={35.5} r={4.5} />
            <circle cx={242.622} cy={35.5} r={4.5} />
            <circle cx={266.622} cy={35.5} r={4.5} />
            <circle cx={290.622} cy={35.5} r={4.5} />
            <circle cx={314.622} cy={35.5} r={4.5} />
            <circle cx={338.622} cy={35.5} r={4.5} />
            <circle cx={362.622} cy={35.5} r={4.5} />
            <circle cx={386.622} cy={35.5} r={4.5} />
            <circle cx={410.622} cy={35.5} r={4.5} />
            <circle cx={434.622} cy={35.5} r={4.5} />
            <circle cx={458.622} cy={35.5} r={4.5} />
            <circle cx={482.622} cy={35.5} r={4.5} />
            <circle cx={506.622} cy={35.5} r={4.5} />
          </g>
          <g clipPath='url(#circles-bottom-right_svg__clip3)'>
            <circle cx={2.622} cy={59.5} r={4.5} />
            <circle cx={26.622} cy={59.5} r={4.5} />
            <circle cx={50.622} cy={59.5} r={4.5} />
            <circle cx={74.622} cy={59.5} r={4.5} />
            <circle cx={98.622} cy={59.5} r={4.5} />
            <circle cx={122.622} cy={59.5} r={4.5} />
            <circle cx={146.622} cy={59.5} r={4.5} />
            <circle cx={170.622} cy={59.5} r={4.5} />
            <circle cx={194.622} cy={59.5} r={4.5} />
            <circle cx={218.622} cy={59.5} r={4.5} />
            <circle cx={242.622} cy={59.5} r={4.5} />
            <circle cx={266.622} cy={59.5} r={4.5} />
            <circle cx={290.622} cy={59.5} r={4.5} />
            <circle cx={314.622} cy={59.5} r={4.5} />
            <circle cx={338.622} cy={59.5} r={4.5} />
            <circle cx={362.622} cy={59.5} r={4.5} />
            <circle cx={386.622} cy={59.5} r={4.5} />
            <circle cx={410.622} cy={59.5} r={4.5} />
            <circle cx={434.622} cy={59.5} r={4.5} />
            <circle cx={458.622} cy={59.5} r={4.5} />
            <circle cx={482.622} cy={59.5} r={4.5} />
            <circle cx={506.622} cy={59.5} r={4.5} />
          </g>
          <g clipPath='url(#circles-bottom-right_svg__clip4)'>
            <circle cx={2.622} cy={83.5} r={4.5} />
            <circle cx={26.622} cy={83.5} r={4.5} />
            <circle cx={50.622} cy={83.5} r={4.5} />
            <circle cx={74.622} cy={83.5} r={4.5} />
            <circle cx={98.622} cy={83.5} r={4.5} />
            <circle cx={122.622} cy={83.5} r={4.5} />
            <circle cx={146.622} cy={83.5} r={4.5} />
            <circle cx={170.622} cy={83.5} r={4.5} />
            <circle cx={194.622} cy={83.5} r={4.5} />
            <circle cx={218.622} cy={83.5} r={4.5} />
            <circle cx={242.622} cy={83.5} r={4.5} />
            <circle cx={266.622} cy={83.5} r={4.5} />
            <circle cx={290.622} cy={83.5} r={4.5} />
            <circle cx={314.622} cy={83.5} r={4.5} />
            <circle cx={338.622} cy={83.5} r={4.5} />
            <circle cx={362.622} cy={83.5} r={4.5} />
            <circle cx={386.622} cy={83.5} r={4.5} />
            <circle cx={410.622} cy={83.5} r={4.5} />
            <circle cx={434.622} cy={83.5} r={4.5} />
            <circle cx={458.622} cy={83.5} r={4.5} />
            <circle cx={482.622} cy={83.5} r={4.5} />
            <circle cx={506.622} cy={83.5} r={4.5} />
          </g>
          <g clipPath='url(#circles-bottom-right_svg__clip5)'>
            <circle cx={2.622} cy={107.5} r={4.5} />
            <circle cx={26.622} cy={107.5} r={4.5} />
            <circle cx={50.622} cy={107.5} r={4.5} />
            <circle cx={74.622} cy={107.5} r={4.5} />
            <circle cx={98.622} cy={107.5} r={4.5} />
            <circle cx={122.622} cy={107.5} r={4.5} />
            <circle cx={146.622} cy={107.5} r={4.5} />
            <circle cx={170.622} cy={107.5} r={4.5} />
            <circle cx={194.622} cy={107.5} r={4.5} />
            <circle cx={218.622} cy={107.5} r={4.5} />
            <circle cx={242.622} cy={107.5} r={4.5} />
            <circle cx={266.622} cy={107.5} r={4.5} />
            <circle cx={290.622} cy={107.5} r={4.5} />
            <circle cx={314.622} cy={107.5} r={4.5} />
            <circle cx={338.622} cy={107.5} r={4.5} />
            <circle cx={362.622} cy={107.5} r={4.5} />
            <circle cx={386.622} cy={107.5} r={4.5} />
            <circle cx={410.622} cy={107.5} r={4.5} />
            <circle cx={434.622} cy={107.5} r={4.5} />
            <circle cx={458.622} cy={107.5} r={4.5} />
            <circle cx={482.622} cy={107.5} r={4.5} />
            <circle cx={506.622} cy={107.5} r={4.5} />
          </g>
          <g clipPath='url(#circles-bottom-right_svg__clip6)'>
            <circle cx={2.622} cy={131.5} r={4.5} />
            <circle cx={26.622} cy={131.5} r={4.5} />
            <circle cx={50.622} cy={131.5} r={4.5} />
            <circle cx={74.622} cy={131.5} r={4.5} />
            <circle cx={98.622} cy={131.5} r={4.5} />
            <circle cx={122.622} cy={131.5} r={4.5} />
            <circle cx={146.622} cy={131.5} r={4.5} />
            <circle cx={170.622} cy={131.5} r={4.5} />
            <circle cx={194.622} cy={131.5} r={4.5} />
            <circle cx={218.622} cy={131.5} r={4.5} />
            <circle cx={242.622} cy={131.5} r={4.5} />
            <circle cx={266.622} cy={131.5} r={4.5} />
            <circle cx={290.622} cy={131.5} r={4.5} />
            <circle cx={314.622} cy={131.5} r={4.5} />
            <circle cx={338.622} cy={131.5} r={4.5} />
            <circle cx={362.622} cy={131.5} r={4.5} />
            <circle cx={386.622} cy={131.5} r={4.5} />
            <circle cx={410.622} cy={131.5} r={4.5} />
            <circle cx={434.622} cy={131.5} r={4.5} />
            <circle cx={458.622} cy={131.5} r={4.5} />
            <circle cx={482.622} cy={131.5} r={4.5} />
            <circle cx={506.622} cy={131.5} r={4.5} />
          </g>
          <g clipPath='url(#circles-bottom-right_svg__clip7)'>
            <circle cx={2.622} cy={155.5} r={4.5} />
            <circle cx={26.622} cy={155.5} r={4.5} />
            <circle cx={50.622} cy={155.5} r={4.5} />
            <circle cx={74.622} cy={155.5} r={4.5} />
            <circle cx={98.622} cy={155.5} r={4.5} />
            <circle cx={122.622} cy={155.5} r={4.5} />
            <circle cx={146.622} cy={155.5} r={4.5} />
            <circle cx={170.622} cy={155.5} r={4.5} />
            <circle cx={194.622} cy={155.5} r={4.5} />
            <circle cx={218.622} cy={155.5} r={4.5} />
            <circle cx={242.622} cy={155.5} r={4.5} />
            <circle cx={266.622} cy={155.5} r={4.5} />
            <circle cx={290.622} cy={155.5} r={4.5} />
            <circle cx={314.622} cy={155.5} r={4.5} />
            <circle cx={338.622} cy={155.5} r={4.5} />
            <circle cx={362.622} cy={155.5} r={4.5} />
            <circle cx={386.622} cy={155.5} r={4.5} />
            <circle cx={410.622} cy={155.5} r={4.5} />
            <circle cx={434.622} cy={155.5} r={4.5} />
            <circle cx={458.622} cy={155.5} r={4.5} />
            <circle cx={482.622} cy={155.5} r={4.5} />
            <circle cx={506.622} cy={155.5} r={4.5} />
          </g>
          <g clipPath='url(#circles-bottom-right_svg__clip8)'>
            <circle cx={2.622} cy={179.5} r={4.5} />
            <circle cx={26.622} cy={179.5} r={4.5} />
            <circle cx={50.622} cy={179.5} r={4.5} />
            <circle cx={74.622} cy={179.5} r={4.5} />
            <circle cx={98.622} cy={179.5} r={4.5} />
            <circle cx={122.622} cy={179.5} r={4.5} />
            <circle cx={146.622} cy={179.5} r={4.5} />
            <circle cx={170.622} cy={179.5} r={4.5} />
            <circle cx={194.622} cy={179.5} r={4.5} />
            <circle cx={218.622} cy={179.5} r={4.5} />
            <circle cx={242.622} cy={179.5} r={4.5} />
            <circle cx={266.622} cy={179.5} r={4.5} />
            <circle cx={290.622} cy={179.5} r={4.5} />
            <circle cx={314.622} cy={179.5} r={4.5} />
            <circle cx={338.622} cy={179.5} r={4.5} />
            <circle cx={362.622} cy={179.5} r={4.5} />
            <circle cx={386.622} cy={179.5} r={4.5} />
            <circle cx={410.622} cy={179.5} r={4.5} />
            <circle cx={434.622} cy={179.5} r={4.5} />
            <circle cx={458.622} cy={179.5} r={4.5} />
            <circle cx={482.622} cy={179.5} r={4.5} />
            <circle cx={506.622} cy={179.5} r={4.5} />
          </g>
          <g clipPath='url(#circles-bottom-right_svg__clip9)'>
            <circle cx={2.622} cy={203.5} r={4.5} />
            <circle cx={26.622} cy={203.5} r={4.5} />
            <circle cx={50.622} cy={203.5} r={4.5} />
            <circle cx={74.622} cy={203.5} r={4.5} />
            <circle cx={98.622} cy={203.5} r={4.5} />
            <circle cx={122.622} cy={203.5} r={4.5} />
            <circle cx={146.622} cy={203.5} r={4.5} />
            <circle cx={170.622} cy={203.5} r={4.5} />
            <circle cx={194.622} cy={203.5} r={4.5} />
            <circle cx={218.622} cy={203.5} r={4.5} />
            <circle cx={242.622} cy={203.5} r={4.5} />
            <circle cx={266.622} cy={203.5} r={4.5} />
            <circle cx={290.622} cy={203.5} r={4.5} />
            <circle cx={314.622} cy={203.5} r={4.5} />
            <circle cx={338.622} cy={203.5} r={4.5} />
            <circle cx={362.622} cy={203.5} r={4.5} />
            <circle cx={386.622} cy={203.5} r={4.5} />
            <circle cx={410.622} cy={203.5} r={4.5} />
            <circle cx={434.622} cy={203.5} r={4.5} />
            <circle cx={458.622} cy={203.5} r={4.5} />
            <circle cx={482.622} cy={203.5} r={4.5} />
            <circle cx={506.622} cy={203.5} r={4.5} />
          </g>
          <g clipPath='url(#circles-bottom-right_svg__clip10)'>
            <circle cx={2.622} cy={227.5} r={4.5} />
            <circle cx={26.622} cy={227.5} r={4.5} />
            <circle cx={50.622} cy={227.5} r={4.5} />
            <circle cx={74.622} cy={227.5} r={4.5} />
            <circle cx={98.622} cy={227.5} r={4.5} />
            <circle cx={122.622} cy={227.5} r={4.5} />
            <circle cx={146.622} cy={227.5} r={4.5} />
            <circle cx={170.622} cy={227.5} r={4.5} />
            <circle cx={194.622} cy={227.5} r={4.5} />
            <circle cx={218.622} cy={227.5} r={4.5} />
            <circle cx={242.622} cy={227.5} r={4.5} />
            <circle cx={266.622} cy={227.5} r={4.5} />
            <circle cx={290.622} cy={227.5} r={4.5} />
            <circle cx={314.622} cy={227.5} r={4.5} />
            <circle cx={338.622} cy={227.5} r={4.5} />
            <circle cx={362.622} cy={227.5} r={4.5} />
            <circle cx={386.622} cy={227.5} r={4.5} />
            <circle cx={410.622} cy={227.5} r={4.5} />
            <circle cx={434.622} cy={227.5} r={4.5} />
            <circle cx={458.622} cy={227.5} r={4.5} />
            <circle cx={482.622} cy={227.5} r={4.5} />
            <circle cx={506.622} cy={227.5} r={4.5} />
          </g>
          <g clipPath='url(#circles-bottom-right_svg__clip11)'>
            <circle cx={2.622} cy={251.5} r={4.5} />
            <circle cx={26.622} cy={251.5} r={4.5} />
            <circle cx={50.622} cy={251.5} r={4.5} />
            <circle cx={74.622} cy={251.5} r={4.5} />
            <circle cx={98.622} cy={251.5} r={4.5} />
            <circle cx={122.622} cy={251.5} r={4.5} />
            <circle cx={146.622} cy={251.5} r={4.5} />
            <circle cx={170.622} cy={251.5} r={4.5} />
            <circle cx={194.622} cy={251.5} r={4.5} />
            <circle cx={218.622} cy={251.5} r={4.5} />
            <circle cx={242.622} cy={251.5} r={4.5} />
            <circle cx={266.622} cy={251.5} r={4.5} />
            <circle cx={290.622} cy={251.5} r={4.5} />
            <circle cx={314.622} cy={251.5} r={4.5} />
            <circle cx={338.622} cy={251.5} r={4.5} />
            <circle cx={362.622} cy={251.5} r={4.5} />
            <circle cx={386.622} cy={251.5} r={4.5} />
            <circle cx={410.622} cy={251.5} r={4.5} />
            <circle cx={434.622} cy={251.5} r={4.5} />
            <circle cx={458.622} cy={251.5} r={4.5} />
            <circle cx={482.622} cy={251.5} r={4.5} />
            <circle cx={506.622} cy={251.5} r={4.5} />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id='circles-bottom-right_svg__clip6'>
          <path
            fill='#fff'
            transform='translate(-1.878 127)'
            d='M0 0h1449v9H0z'
          />
        </clipPath>
        <clipPath id='circles-bottom-right_svg__clip0'>
          <path
            fill='#fff'
            transform='translate(-.999 -785)'
            d='M0 0h1447.24v1041H0z'
          />
        </clipPath>
        <clipPath id='circles-bottom-right_svg__clip2'>
          <path
            fill='#fff'
            transform='translate(-1.878 31)'
            d='M0 0h1449v9H0z'
          />
        </clipPath>
        <clipPath id='circles-bottom-right_svg__clip3'>
          <path
            fill='#fff'
            transform='translate(-1.878 55)'
            d='M0 0h1449v9H0z'
          />
        </clipPath>
        <clipPath id='circles-bottom-right_svg__clip4'>
          <path
            fill='#fff'
            transform='translate(-1.878 79)'
            d='M0 0h1449v9H0z'
          />
        </clipPath>
        <clipPath id='circles-bottom-right_svg__clip5'>
          <path
            fill='#fff'
            transform='translate(-1.878 103)'
            d='M0 0h1449v9H0z'
          />
        </clipPath>
        <clipPath id='circles-bottom-right_svg__clip1'>
          <path
            fill='#fff'
            transform='translate(-1.878 7)'
            d='M0 0h1449v9H0z'
          />
        </clipPath>
        <clipPath id='circles-bottom-right_svg__clip7'>
          <path
            fill='#fff'
            transform='translate(-1.878 151)'
            d='M0 0h1449v9H0z'
          />
        </clipPath>
        <clipPath id='circles-bottom-right_svg__clip8'>
          <path
            fill='#fff'
            transform='translate(-1.878 175)'
            d='M0 0h1449v9H0z'
          />
        </clipPath>
        <clipPath id='circles-bottom-right_svg__clip9'>
          <path
            fill='#fff'
            transform='translate(-1.878 199)'
            d='M0 0h1449v9H0z'
          />
        </clipPath>
        <clipPath id='circles-bottom-right_svg__clip10'>
          <path
            fill='#fff'
            transform='translate(-1.878 223)'
            d='M0 0h1449v9H0z'
          />
        </clipPath>
        <clipPath id='circles-bottom-right_svg__clip11'>
          <path
            fill='#fff'
            transform='translate(-1.878 247)'
            d='M0 0h1449v9H0z'
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgCirclesBottomRight;

import React from 'react';
import ExpandableCard from './expandable';
import CaseCard from '../case/case';
import { Divider } from '@components/ui';

export default {
  title: 'Remark/card/ExpandableCard',
  component: ExpandableCard,
};

const Template = (args) => <ExpandableCard {...args} />;

export const Default = Template.bind({});

Default.args = {
  file: {
    text: 'DoctorReport-and-Xray.pdf',
    icon: 'pdf',
    action: '#',
  },
  content: [
    {
      text:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras et faucibus leo, in sagittis nisi. Nullam imperdiet pharetra urna vitae venenatis. Sed non mattis sem, at facilisis orci. Donec eu dui viverra, sagittis risus eu, aliquam nunc. Aliquam rhoncus...',
    },
    {
      text: 'Collapsed content',
    },
  ],
};

export const Colors = () => (
  <>
    <ExpandableCard {...Default.args} />
    <Divider height={20} color={'transparent'} />
    <ExpandableCard {...Default.args} color={'stable-300'} />
  </>
);

export const WithRemoveAndDate = () => (
  <>
    <ExpandableCard
      {...Default.args}
      remove={{ action: '#' }}
      date={{ text: '29-09-2020 9:45 PM' }}
    />
  </>
);

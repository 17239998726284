import React from 'react';
import ActionCard from './action';
import { Spacings, Colors as ColorNames } from '@components/variables';

export default {
  title: 'Remark/card/ActionCard',
  component: ActionCard,
};

const Template = (args) => <ActionCard {...args} />;

export const Default = Template.bind({});

Default.args = {
  title: {
    text: 'Action required',
  },
  content: {
    text: 'Missing document',
  },
  buttons: [
    {
      text: 'Upload documents',
      iconLeft: 'download',
    },
  ],
  icon: {
    icon: 'warning',
    stroke: true,
  },
};

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      return <ActionCard key={index} color={color} {...Default.args} />;
    })}
  </>
);

export const Sizes = () => (
  <>
    {Spacings.map((space, index) => {
      return <ActionCard key={index} size={space} {...Default.args} />;
    })}
  </>
);

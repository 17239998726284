import React from 'react';
import Alert from './alert';
import { Content } from '@components/text';

export default {
  title: 'Remark/modal/Alert',
  component: Alert,
};

const Template = (args) => (
  <div>
    <Alert {...args} open />
    <Content
      text={
        'When leaving a page and there are changes present, this alert will be shown. The detection of changes happens on all the onInput events on form elements'
      }
    />
  </div>
);

export const Default = Template.bind({});
Default.args = {
  title: {
    text: 'Are you sure you want to leave?',
  },
  content: {
    text:
      'You seem to have some unchanged changes. If you leave this page without saving, any unsaved data will be lost.',
  },
  buttons: [
    {
      text: 'Cancel',
      color: 'stable-700',
      type: 'cancel',
    },
    {
      text: 'Ok',
      color: 'positive',
      type: 'continue',
    },
  ],
};

import React from 'react';
import PropTypes from 'prop-types';
import styles from './notifications.module.scss';
import { Button } from '@components/button';
import { Count } from '@components/badge';
import { ActionSheet, Tooltip } from '@components/ui';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const Notifications = ({ notifications, position }) => {
  let classes = cx({
    notifications: true,
  });
  return (
    <div className={classes}>
      {notifications.actions ? (
        <Tooltip
          content={<ActionSheet actions={notifications.actions} />}
          position={position}
          simple={false}
        >
          <Button
            icon='bell'
            link={false}
            color='transparent'
            text='Notifications'
            count={notifications.count}
            column={true}
          />
        </Tooltip>
      ) : (
        <div />
      )}
    </div>
  );
};

Notifications.propTypes = {
  /** Array of notifications */
  notifications: PropTypes.shape({
    actions: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
    count: PropTypes.shape(Count.propTypes),
  }),
  /** The position of the dropdown with notifications based on the position of the triggers */
  position: PropTypes.oneOf([
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
    'right',
    'right-start',
    'right-end',
  ]),
};

Notifications.defaultProps = {
  notifications: {
    count: {
      text: 0,
    },
  },
  position: 'bottom-end',
};

//Needed for Storybook
Notifications.displayName = 'Notifications';

export default Notifications;

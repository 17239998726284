import React from 'react';
import InsuranceCard from './insurance';

export default {
  title: 'Remark/card/InsuranceCard',
  component: InsuranceCard,
};

const Template = (args) => <InsuranceCard {...args} />;

export const Default = Template.bind({});

Default.args = {
  title: {
    text: 'Premier Life Protection',
  },
  details: [
    {
      label: {
        text: 'Policy number',
      },
      value: {
        text: 'LA7160501-6AD',
      },
    },
    {
      label: {
        text: 'Coverage',
      },
      value: {
        text: '$750.00',
      },
    },
    {
      label: {
        text: 'Other data',
      },
      value: {
        text: 'xxxxxxxxxxxxx',
      },
    },
  ],
  buttons: [
    {
      text: 'Next',
      iconRight: 'chevron-right-round',
      action: '#',
    },
  ],
  icon: {
    icon: 'folder-file',
  },
};

import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './table.module.scss';
import classNames from 'classnames/bind';
import { Colors } from '@components/variables';
import { Icon, Tooltip, Divider } from '@components/ui';
import { Content } from '@components/text';
import { Button } from '@components/button';
import { Element } from '@components/form';
import { Pagination } from '@components/layout';

const cx = classNames.bind(styles);

const Table = (props) => {
  const refs = props.selectable && props.body.map((b) => useRef());
  const selectable = useRef();
  const allRef = useRef();
  const [checked, setChecked] = useState([]);
  let classes = cx(props.color, props.className, {
    table: true,
    inverse: props.inverse,
  });

  const bottomBarClasses = cx({
    bottomBar: true,
    'u-hide':
      checked.length == 0 &&
      (!props.pagination || (props.pagination && props.pagination.max === 1)),
    hiddenButtons: checked.length == 0,
  });

  useEffect(() => {
    if (props.bottom) {
      const id = props.bottom.startsWith('#')
        ? props.bottom
        : `#${props.bottom}`;
      const elem = document.querySelector(id);
      if (elem && selectable.current) {
        selectable.current.appendChild(elem);
        elem.classList.remove('u-hide');
        ReactRailsUJS.mountComponents(elem);
      }
    }
  }, []);

  const onChangeHandler = (e) => {
    e.currentTarget.checked ? setChecked(checked + 1) : setChecked(checked - 1);
    if (e.target.value == 'all') {
      let toCheck = [];
      refs.forEach((r) => {
        r.current.checked = e.currentTarget.checked;
        if (r.current.checked) {
          toCheck.push(r.current.value);
        }
      });
      setChecked(toCheck);
    } else {
      e.currentTarget.checked
        ? setChecked([...checked, e.currentTarget.value])
        : setChecked(checked.filter((i) => i != e.currentTarget.value));
      if (!e.currentTarget.checked) {
        allRef.current.checked = false;
      }
    }
  };
  const _renderHead = (data) => {
    return (
      <tr>
        {data.map((column, index) => {
          const iconFilteredClass = cx({
            icon: true,
            filtered:
              column.filterable &&
              props.params &&
              props.params[column.filterable] &&
              props.params[column.filterable].length > 0,
          });
          const iconSortedClass = cx({
            icon: true,
            sorted:
              column.sortable &&
              props.params &&
              props.params['s'] &&
              new RegExp('\\b' + column.sortable + '\\b').test(
                props.params['s']
              ),
          });
          return (
            <th key={index}>
              <div className={styles.inner}>
                {props.selectable && index == 0 && (
                  <Element
                    reference={allRef}
                    type={'checkbox'}
                    name={'all'}
                    value={'all'}
                    onChange={(e) => {
                      onChangeHandler(e);
                    }}
                  />
                )}
                {column.action && (
                  <Button
                    color={column.color || 'dark'}
                    action={column.action}
                    text={column.text}
                    link={true}
                    emphasis={false}
                    {...column}
                  />
                )}
                {!column.action && column.text && (
                  <Content color={column.color || 'dark'} text={column.text} />
                )}
                {column.dropdown && (
                  <Tooltip
                    selector={column.dropdown}
                    position={'bottom'}
                    content={'Loading...'}
                    simple={true}
                  >
                    <div className={iconFilteredClass}>
                      <Icon
                        icon={'arrow-down'}
                        width={10}
                        height={10}
                        color={
                          column.filterable &&
                          props.params &&
                          props.params[column.filterable] &&
                          props.params[column.filterable].length > 0
                            ? 'positive'
                            : 'stable-900'
                        }
                      />
                    </div>
                  </Tooltip>
                )}
                {column.action && (
                  <a href={column.action} className={iconSortedClass}>
                    <Icon
                      icon={'order-up'}
                      color={
                        props.params &&
                        props.params['s'] &&
                        props.params['s'].includes(column.sortable) &&
                        props.params['s'].includes('asc')
                          ? 'positive'
                          : 'stable-900'
                      }
                      width={8}
                      height={5}
                    />
                    <Divider height={1} color={'transparent'} />
                    <Icon
                      icon={'order-down'}
                      color={
                        props.params &&
                        props.params['s'] &&
                        props.params['s'].includes(column.sortable) &&
                        props.params['s'].includes('desc')
                          ? 'positive'
                          : 'stable-900'
                      }
                      width={8}
                      height={5}
                    />
                  </a>
                )}
              </div>
            </th>
          );
        })}
      </tr>
    );
  };

  const _renderRow = (row, i) => {
    return (
      <tr key={i}>
        {row.data.map((column, index) => {
          const cellClass = cx({
            inner: true,
            hasAction: column.action,
            truncated: column.truncate,
          });
          const id = column.truncate
            ? Math.random().toString(36).substr(2, 9) + index
            : '';
          return (
            <td
              key={index}
              data-content={
                props.head && props.head[index] && index > 0
                  ? props.head[index].text
                  : ''
              }
            >
              <div className={cellClass} id={id}>
                {props.selectable && index == 0 && (
                  <Element
                    reference={refs[i]}
                    type={'checkbox'}
                    name={column.name || 'checked'}
                    value={row.key}
                    onChange={(e) => {
                      onChangeHandler(e);
                    }}
                    customData={{ form: props.selectable }}
                  />
                )}
                {column.action && (
                  <Button
                    color={column.color || 'positive'}
                    action={column.action}
                    text={column.text}
                    link={true}
                    icon={column.icon}
                    {...column}
                  />
                )}
                {!column.action && column.text && (
                  <Content color={column.color || 'dark'} text={column.text} />
                )}
                {column.truncate && (
                  <Tooltip
                    content={column.text}
                    simple={true}
                    trigger={'mouseenter'}
                    appendto={id}
                  >
                    <div className={styles.iconTruncate}>
                      <Icon
                        icon={'question-round'}
                        color={'stable-900'}
                        width={12}
                        height={12}
                      />
                    </div>
                  </Tooltip>
                )}
              </div>
            </td>
          );
        })}
      </tr>
    );
  };

  return (
    <div className={styles.wrapper}>
      <table className={classes}>
        {props.head && <thead>{_renderHead(props.head, true)}</thead>}
        {props.body && (
          <tbody>
            {props.body.map((row, index) => {
              return _renderRow(row, index);
            })}
          </tbody>
        )}
      </table>
      {props.bottom && (
        <div className={bottomBarClasses}>
          <Divider height={1} width={'auto'} />
          <div className={styles.bottomHolder}>
            <div className={styles.bottomInner} ref={selectable}></div>
            {props.pagination && props.pagination.max > 1 && (
              <Pagination className={styles.pagination} {...props.pagination} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

Table.propTypes = {
  /** When enabled, all rows will have a checkbox in the first cell. Value needs to be the id of the connected form */
  selectable: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** Inverse the zebra styling of the table */
  inverse: PropTypes.bool,
  /** The head data of the table */
  head: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.oneOf(Colors),
      text: PropTypes.string,
      action: PropTypes.string,
      dropdown: PropTypes.string,
    })
  ),
  /** The body data of the table */
  body: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          color: PropTypes.oneOf(Colors),
          text: PropTypes.string,
          action: PropTypes.string,
          icon: PropTypes.string,
        })
      ),
    })
  ),
  /** The id of the content for the bottom bar */
  bottom: PropTypes.string,
};

Table.defaultProps = {};

//Needed for Storybook
Table.displayName = 'Table';

export default Table;

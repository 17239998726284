import React from 'react';
import PropTypes from 'prop-types';
import styles from './avatar.module.scss';
import classNames from 'classnames/bind';
import { Count } from '@components/badge';
import { Image } from '@components/ui';
import { Colors } from '@components/variables';

const cx = classNames.bind(styles);

const Avatar = (props) => {
  let classes = cx(props.color, props.className);

  const AvatarContent = () => {
    if (props.image && props.image.src !== '') {
      return (
        props.image && (
          <Image round={true} width={45} height={45} {...props.image} />
        )
      );
    } else {
      return (
        <Count
          text={props.name[0].toUpperCase()}
          color={props.color}
          size={'l'}
          textSize={'h4'}
          round={true}
        />
      );
    }
  };

  return (
    <div className={classes}>
      <AvatarContent />
    </div>
  );
};

Avatar.propTypes = {
  color: PropTypes.oneOf(Colors),
  /** The Avatar image */
  image: PropTypes.shape(Image.propTypes),
  /** The name of the user */
  name: PropTypes.string,
};

Avatar.defaultProps = {
  color: 'positive',
};

//Needed for Storybook
Avatar.displayName = 'Avatar';

export default Avatar;

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes, { element } from 'prop-types';
import styles from './tooltip.module.scss';
import classNames from 'classnames/bind';
import { Content } from '@components/text';
import { Button } from '@components/button';
import Wrapper from '../../card/wrapper/wrapper';
import ActionSheet from '../action-sheet/action-sheet';
import { Tooltip as Tippy } from 'react-tippy';
const cx = classNames.bind(styles);

const Tooltip = (props) => {
  const classes = cx(props.className, {
    simple: props.simple,
  });
  return (
    <Tippy
      interactive={true}
      html={
        props.simple ? (
          <Wrapper size={'s'} color={'light'}>
            <Content text={props.content} size={'label'} />
          </Wrapper>
        ) : props.actions ? (
          <ActionSheet actions={props.actions} />
        ) : (
          props.content
        )
      }
      onShown={() => {
        if (props.selector) {
          const tooltip = document.querySelector('.tippy-tooltip-content');
          const id = props.selector.startsWith('#')
            ? props.selector
            : `#${props.selector}`;
          const elem = document.querySelector(id);
          if (tooltip && elem) {
            ReactDOM.unmountComponentAtNode(tooltip);
            tooltip.appendChild(elem);
            elem.classList.remove('u-hide');
            ReactRailsUJS.mountComponents(elem);
          }
        }
      }}
      onHide={() => {
        if (props.selector) {
          const form = document.querySelector('#table-form');
          const id = props.selector.startsWith('#')
            ? props.selector
            : `#${props.selector}`;
          const elem = document.querySelector(id);
          if (form && elem) {
            elem.classList.add('u-hide');
            form.appendChild(elem);
            ReactRailsUJS.mountComponents(elem);
          }
        }
      }}
      duration={props.selector ? 0 : 350}
      position={props.position}
      trigger={props.trigger}
      interactiveBorder={10}
      className={classes}
      animateFill={false}
      arrow={props.simple}
      appendTo={
        props.appendto ? () => document.getElementById(props.appendto) : false
      }
      theme={'light'}
      popperOptions={{
        modifiers: {
          preventOverflow: {
            enabled: false,
          },
          hide: {
            enabled: false,
          },
          flip: {
            enabled: false,
          },
        },
      }}
      {...props.tippyOptions}
    >
      {props.button ? (
        <Button
          action={(e) => {
            document.activeElement.blur();
            e.preventDefault();
          }}
          {...props.button}
        />
      ) : (
        props.children
      )}
    </Tippy>
  );
};

Tooltip.propTypes = {
  /** The content of the tooltip, can be another Component */
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** The position of the tooltip */
  position: PropTypes.oneOf([
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
    'right',
    'right-start',
    'right-end',
  ]),
  /** The trigger for the tooltip */
  trigger: PropTypes.oneOf(['mouseenter', 'click']),
  /** Whether a simple tooltip should be shown */
  simple: PropTypes.bool,
  /** When you want to show a (hidden) div in the tooltip, add its identifier as selector */
  selector: PropTypes.string,
  /** Additional tippy options */
  tippyOptions: PropTypes.object,
};

Tooltip.defaultProps = {
  position: 'top',
  trigger: 'click',
};

//Needed for Storybook
Tooltip.displayName = 'Tooltip';

export default Tooltip;

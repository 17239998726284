import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './modal.module.scss';
import classNames from 'classnames/bind';
import { Colors, Spacings } from '@components/variables';
import ReactModal from 'react-modal';
import { Wrapper } from '@components/card';
import { Title, Detail } from '@components/text';
import { Button } from '@components/button';
import { Divider } from '@components/ui';
const cx = classNames.bind(styles);

const Modal = (props) => {
  const [modalIsOpen, setIsOpen] = useState(props.isOpen);
  const [isTransformed, setisTransformed] = useState(false);
  const [modalHasFooter, setmodalHasFooter] = useState(props.hasFooter);
  const contentWrapper = useRef();
  const footerWrapper = useRef();
  const toggleModal = () => {
    if (modalIsOpen) {
      document && document.querySelector('.portal-node').remove();
    }
    setIsOpen(!modalIsOpen);
  };
  let classes = cx(
    {
      modal: true,
      loading: props.loading,
      inverted: props.inverted,
    },
    props.color,
    props.size,
    props.className
  );
  let overlayClasses = cx({
    overlay: true,
  });

  useEffect(() => {
    if (props.id && !isTransformed) {
      const id = props.id.startsWith('#') ? props.id : `#${props.id}`;
      const elem = document ? document.querySelector(id) : false;
      if (elem) {
        setisTransformed(true);
        toggleModal();
      } else {
        alert('Selector not found');
      }
    }
  });

  const _moveContent = () => {
    if (props.id) {
      const id = props.id.startsWith('#') ? props.id : `#${props.id}`;
      const elem = document ? document.querySelector(id) : false;
      if (elem && contentWrapper.current) {
        const footer = elem.querySelector('[data-footer]');
        contentWrapper.current.appendChild(elem);
        ReactRailsUJS.mountComponents(elem);
        if (footer && footerWrapper.current) {
          footerWrapper.current.appendChild(footer);
          ReactRailsUJS.mountComponents(footer);
        }
      }
    }
  };

  return (
    <>
      {props.trigger && (
        <Button
          className={styles.trigger}
          link={true}
          text={'Open Modal'}
          action={toggleModal}
          {...props.trigger}
        />
      )}
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        className={classes}
        overlayClassName={overlayClasses}
        ariaHideApp={false}
        onAfterOpen={_moveContent}
        portalClassName={'portal-node'}
      >
        <Divider height={20} color={'transparent'} />
        <Wrapper>
          {props.children ? (
            props.children
          ) : (
            <>
              <div className={styles.heading}>
                {props.title && <Title {...props.title} />}
                <Button
                  color={'dark'}
                  className={styles.remove}
                  link={true}
                  text={false}
                  icon={'cross'}
                  action={toggleModal}
                />
              </div>
              {props.details && (
                <div className={styles.details}>
                  {
                    props.details.map((item, index) => {
                      return (
                        <Detail className={styles.detail} key={index} {...item} />
                      )
                    })
                  }
                </div>
              )}
              <Divider height={30} color={'transparent'} />
              <div className={styles.content} ref={contentWrapper}></div>
              {modalHasFooter && (
                <div className={styles.footer} ref={footerWrapper}></div>
              )}
            </>
          )}
        </Wrapper>
        <Divider height={20} color={'transparent'} />
      </ReactModal>
    </>
  );
};
Modal.propTypes = {
  /** The color variant of the modal */
  color: PropTypes.oneOf(Colors).isRequired,
  /** To size of the modal (spacing between modal & content) */
  size: PropTypes.oneOf(['no-p', ...Spacings]).isRequired,
  /** The data for the trigger button */
  trigger: PropTypes.shape(Button.propTypes),
  /** The title inside the modal */
  title: PropTypes.shape(Title.propTypes),
  /** The details inside the modal */
  details: PropTypes.arrayOf(PropTypes.shape(Detail.propTypes)),
};

Modal.defaultProps = {
  color: 'light',
  size: 'xl',
};

//Needed for Storybook
Modal.displayName = 'Modal';

export default Modal;

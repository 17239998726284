import React from 'react';
import PieChartCard from './pie-chart';
import { Spacings, Colors as ColorNames } from '@components/variables';

export default {
  title: 'Remark/card/PieChartCard',
  component: PieChartCard,
};

const Template = (args) => <PieChartCard {...args} />;

export const Default = Template.bind({});

Default.args = {
  header: {
    text: 'Claim statusses',
  },
  data: [
    {
      id: '1',
      value: 56,
      label: 'Initiated',
      color: 'positive',
    },
    {
      id: '2',
      value: 34,
      label: 'Ready for review',
      color: 'assertive',
    },
    {
      id: '3',
      value: 34,
      label: 'Pending expert review',
      color: 'energized',
    },
    {
      id: '4',
      value: 34,
      label: 'Pending expert review',
      color: 'balanced',
    },
    {
      id: '5',
      value: 34,
      label: 'Pending expert review',
      color: 'royal',
    },
  ],
};

export const WithPercentage = () => {
  return (
    <PieChartCard
      header={{
        text: 'Claim statusses',
      }}
      data={[
        {
          id: '1',
          value: 56,
          label: 'Initiated',
          color: 'positive',
          percentage: 50,
        },
        {
          id: '2',
          value: 34,
          label: 'Ready for review',
          color: 'assertive',
        },
        {
          id: '3',
          value: 34,
          label: 'Pending expert review',
          color: 'energized',
        },
        {
          id: '4',
          value: 34,
          label: 'Pending expert review',
          color: 'balanced',
        },
        {
          id: '5',
          value: 34,
          label: 'Pending expert review',
          color: 'royal',
        },
      ]}
    />
  );
};

import React from 'react';
import OngoingCasesCard from './ongoing-cases';

export default {
  title: 'Remark/card/OngoingCasesCard',
  component: OngoingCasesCard,
};

const Template = (args) => <OngoingCasesCard {...args} />;

export const Default = Template.bind({});

Default.args = {
  title: {
    text: 'My ongoing cases',
  },
  content: {
    text: 'Below is a summary  of your ongoing cases.',
  },
  cases: [
    {
      action: '3510396',
      badge: {
        text: 'Action required',
        color: 'energized',
      },
      details: [
        {
          label: {
            text: 'Case no.',
          },
          value: {
            text: '3510396',
          },
        },
        {
          label: {
            text: 'Policy contract no.',
          },
          value: {
            text: 'LA7160501-6AD',
          },
        },
      ],
    },
    {
      action: '3510396',
      badge: {
        text: 'Pending',
        color: 'stable-500',
      },
      details: [
        {
          label: {
            text: 'Case no.',
          },
          value: {
            text: '3510396',
          },
        },
        {
          label: {
            text: 'Policy contract no.',
          },
          value: {
            text: 'LA7160501-6AD',
          },
        },
      ],
    },
    {
      badge: {
        text: 'Rejected',
        color: 'assertive',
      },
      details: [
        {
          label: {
            text: 'Case no.',
          },
          value: {
            text: '3510396',
          },
        },
        {
          label: {
            text: 'Policy contract no.',
          },
          value: {
            text: 'LA7160501-6AD',
          },
        },
      ],
    },
    {
      details: [
        {
          label: {
            text: 'Case no.',
          },
          value: {
            text: '3510396',
          },
        },
        {
          label: {
            text: 'Policy contract no.',
          },
          value: {
            text: 'LA7160501-6AD',
          },
        },
      ],
    },
  ],
  buttons: [
    {
      text: 'View all my cases',
      iconRight: 'chevron-right-round',
      size: 'l',
      action: '#',
    },
  ],
};

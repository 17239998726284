import * as React from 'react';

function SvgCirclesBottomLeft(props) {
  return (
    <svg width={507} height={255} fill='none' {...props}>
      <g opacity={0.08}>
        <mask
          id='circles-bottom-left_svg__a'
          maskUnits='userSpaceOnUse'
          x={0}
          y={0}
          width={507}
          height={256}
        >
          <path
            d='M342 256s145.379-25.159 162-97C524.127 72.008 424.267 2.948 335 5c-78.595 1.806-78.511 121.54-157 126C91.83 135.897 0 0 0 0v256h342z'
            fill='#fff'
          />
        </mask>
        <g mask='url(#circles-bottom-left_svg__a)' fill='#E8A32E'>
          <circle cx={3.5} cy={11.5} r={4.5} />
          <circle cx={27.5} cy={11.5} r={4.5} />
          <circle cx={51.5} cy={11.5} r={4.5} />
          <circle cx={75.5} cy={11.5} r={4.5} />
          <circle cx={99.5} cy={11.5} r={4.5} />
          <circle cx={123.5} cy={11.5} r={4.5} />
          <circle cx={147.5} cy={11.5} r={4.5} />
          <circle cx={171.5} cy={11.5} r={4.5} />
          <circle cx={195.5} cy={11.5} r={4.5} />
          <circle cx={219.5} cy={11.5} r={4.5} />
          <circle cx={243.5} cy={11.5} r={4.5} />
          <circle cx={267.5} cy={11.5} r={4.5} />
          <circle cx={291.5} cy={11.5} r={4.5} />
          <circle cx={315.5} cy={11.5} r={4.5} />
          <circle cx={339.5} cy={11.5} r={4.5} />
          <circle cx={363.5} cy={11.5} r={4.5} />
          <circle cx={387.5} cy={11.5} r={4.5} />
          <circle cx={411.5} cy={11.5} r={4.5} />
          <circle cx={435.5} cy={11.5} r={4.5} />
          <circle cx={459.5} cy={11.5} r={4.5} />
          <circle cx={483.5} cy={11.5} r={4.5} />
          <circle cx={507.5} cy={11.5} r={4.5} />
          <circle cx={3.5} cy={35.5} r={4.5} />
          <circle cx={27.5} cy={35.5} r={4.5} />
          <circle cx={51.5} cy={35.5} r={4.5} />
          <circle cx={75.5} cy={35.5} r={4.5} />
          <circle cx={99.5} cy={35.5} r={4.5} />
          <circle cx={123.5} cy={35.5} r={4.5} />
          <circle cx={147.5} cy={35.5} r={4.5} />
          <circle cx={171.5} cy={35.5} r={4.5} />
          <circle cx={195.5} cy={35.5} r={4.5} />
          <circle cx={219.5} cy={35.5} r={4.5} />
          <circle cx={243.5} cy={35.5} r={4.5} />
          <circle cx={267.5} cy={35.5} r={4.5} />
          <circle cx={291.5} cy={35.5} r={4.5} />
          <circle cx={315.5} cy={35.5} r={4.5} />
          <circle cx={339.5} cy={35.5} r={4.5} />
          <circle cx={363.5} cy={35.5} r={4.5} />
          <circle cx={387.5} cy={35.5} r={4.5} />
          <circle cx={411.5} cy={35.5} r={4.5} />
          <circle cx={435.5} cy={35.5} r={4.5} />
          <circle cx={459.5} cy={35.5} r={4.5} />
          <circle cx={483.5} cy={35.5} r={4.5} />
          <circle cx={507.5} cy={35.5} r={4.5} />
          <circle cx={3.5} cy={59.5} r={4.5} />
          <circle cx={27.5} cy={59.5} r={4.5} />
          <circle cx={51.5} cy={59.5} r={4.5} />
          <circle cx={75.5} cy={59.5} r={4.5} />
          <circle cx={99.5} cy={59.5} r={4.5} />
          <circle cx={123.5} cy={59.5} r={4.5} />
          <circle cx={147.5} cy={59.5} r={4.5} />
          <circle cx={171.5} cy={59.5} r={4.5} />
          <circle cx={195.5} cy={59.5} r={4.5} />
          <circle cx={219.5} cy={59.5} r={4.5} />
          <circle cx={243.5} cy={59.5} r={4.5} />
          <circle cx={267.5} cy={59.5} r={4.5} />
          <circle cx={291.5} cy={59.5} r={4.5} />
          <circle cx={315.5} cy={59.5} r={4.5} />
          <circle cx={339.5} cy={59.5} r={4.5} />
          <circle cx={363.5} cy={59.5} r={4.5} />
          <circle cx={387.5} cy={59.5} r={4.5} />
          <circle cx={411.5} cy={59.5} r={4.5} />
          <circle cx={435.5} cy={59.5} r={4.5} />
          <circle cx={459.5} cy={59.5} r={4.5} />
          <circle cx={483.5} cy={59.5} r={4.5} />
          <circle cx={507.5} cy={59.5} r={4.5} />
          <circle cx={3.5} cy={83.5} r={4.5} />
          <circle cx={27.5} cy={83.5} r={4.5} />
          <circle cx={51.5} cy={83.5} r={4.5} />
          <circle cx={75.5} cy={83.5} r={4.5} />
          <circle cx={99.5} cy={83.5} r={4.5} />
          <circle cx={123.5} cy={83.5} r={4.5} />
          <circle cx={147.5} cy={83.5} r={4.5} />
          <circle cx={171.5} cy={83.5} r={4.5} />
          <circle cx={195.5} cy={83.5} r={4.5} />
          <circle cx={219.5} cy={83.5} r={4.5} />
          <circle cx={243.5} cy={83.5} r={4.5} />
          <circle cx={267.5} cy={83.5} r={4.5} />
          <circle cx={291.5} cy={83.5} r={4.5} />
          <circle cx={315.5} cy={83.5} r={4.5} />
          <circle cx={339.5} cy={83.5} r={4.5} />
          <circle cx={363.5} cy={83.5} r={4.5} />
          <circle cx={387.5} cy={83.5} r={4.5} />
          <circle cx={411.5} cy={83.5} r={4.5} />
          <circle cx={435.5} cy={83.5} r={4.5} />
          <circle cx={459.5} cy={83.5} r={4.5} />
          <circle cx={483.5} cy={83.5} r={4.5} />
          <circle cx={507.5} cy={83.5} r={4.5} />
          <circle cx={3.5} cy={107.5} r={4.5} />
          <circle cx={27.5} cy={107.5} r={4.5} />
          <circle cx={51.5} cy={107.5} r={4.5} />
          <circle cx={75.5} cy={107.5} r={4.5} />
          <circle cx={99.5} cy={107.5} r={4.5} />
          <circle cx={123.5} cy={107.5} r={4.5} />
          <circle cx={147.5} cy={107.5} r={4.5} />
          <circle cx={171.5} cy={107.5} r={4.5} />
          <circle cx={195.5} cy={107.5} r={4.5} />
          <circle cx={219.5} cy={107.5} r={4.5} />
          <circle cx={243.5} cy={107.5} r={4.5} />
          <circle cx={267.5} cy={107.5} r={4.5} />
          <circle cx={291.5} cy={107.5} r={4.5} />
          <circle cx={315.5} cy={107.5} r={4.5} />
          <circle cx={339.5} cy={107.5} r={4.5} />
          <circle cx={363.5} cy={107.5} r={4.5} />
          <circle cx={387.5} cy={107.5} r={4.5} />
          <circle cx={411.5} cy={107.5} r={4.5} />
          <circle cx={435.5} cy={107.5} r={4.5} />
          <circle cx={459.5} cy={107.5} r={4.5} />
          <circle cx={483.5} cy={107.5} r={4.5} />
          <circle cx={507.5} cy={107.5} r={4.5} />
          <circle cx={3.5} cy={131.5} r={4.5} />
          <circle cx={27.5} cy={131.5} r={4.5} />
          <circle cx={51.5} cy={131.5} r={4.5} />
          <circle cx={75.5} cy={131.5} r={4.5} />
          <circle cx={99.5} cy={131.5} r={4.5} />
          <circle cx={123.5} cy={131.5} r={4.5} />
          <circle cx={147.5} cy={131.5} r={4.5} />
          <circle cx={171.5} cy={131.5} r={4.5} />
          <circle cx={195.5} cy={131.5} r={4.5} />
          <circle cx={219.5} cy={131.5} r={4.5} />
          <circle cx={243.5} cy={131.5} r={4.5} />
          <circle cx={267.5} cy={131.5} r={4.5} />
          <circle cx={291.5} cy={131.5} r={4.5} />
          <circle cx={315.5} cy={131.5} r={4.5} />
          <circle cx={339.5} cy={131.5} r={4.5} />
          <circle cx={363.5} cy={131.5} r={4.5} />
          <circle cx={387.5} cy={131.5} r={4.5} />
          <circle cx={411.5} cy={131.5} r={4.5} />
          <circle cx={435.5} cy={131.5} r={4.5} />
          <circle cx={459.5} cy={131.5} r={4.5} />
          <circle cx={483.5} cy={131.5} r={4.5} />
          <circle cx={507.5} cy={131.5} r={4.5} />
          <circle cx={3.5} cy={155.5} r={4.5} />
          <circle cx={27.5} cy={155.5} r={4.5} />
          <circle cx={51.5} cy={155.5} r={4.5} />
          <circle cx={75.5} cy={155.5} r={4.5} />
          <circle cx={99.5} cy={155.5} r={4.5} />
          <circle cx={123.5} cy={155.5} r={4.5} />
          <circle cx={147.5} cy={155.5} r={4.5} />
          <circle cx={171.5} cy={155.5} r={4.5} />
          <circle cx={195.5} cy={155.5} r={4.5} />
          <circle cx={219.5} cy={155.5} r={4.5} />
          <circle cx={243.5} cy={155.5} r={4.5} />
          <circle cx={267.5} cy={155.5} r={4.5} />
          <circle cx={291.5} cy={155.5} r={4.5} />
          <circle cx={315.5} cy={155.5} r={4.5} />
          <circle cx={339.5} cy={155.5} r={4.5} />
          <circle cx={363.5} cy={155.5} r={4.5} />
          <circle cx={387.5} cy={155.5} r={4.5} />
          <circle cx={411.5} cy={155.5} r={4.5} />
          <circle cx={435.5} cy={155.5} r={4.5} />
          <circle cx={459.5} cy={155.5} r={4.5} />
          <circle cx={483.5} cy={155.5} r={4.5} />
          <circle cx={507.5} cy={155.5} r={4.5} />
          <circle cx={3.5} cy={179.5} r={4.5} />
          <circle cx={27.5} cy={179.5} r={4.5} />
          <circle cx={51.5} cy={179.5} r={4.5} />
          <circle cx={75.5} cy={179.5} r={4.5} />
          <circle cx={99.5} cy={179.5} r={4.5} />
          <circle cx={123.5} cy={179.5} r={4.5} />
          <circle cx={147.5} cy={179.5} r={4.5} />
          <circle cx={171.5} cy={179.5} r={4.5} />
          <circle cx={195.5} cy={179.5} r={4.5} />
          <circle cx={219.5} cy={179.5} r={4.5} />
          <circle cx={243.5} cy={179.5} r={4.5} />
          <circle cx={267.5} cy={179.5} r={4.5} />
          <circle cx={291.5} cy={179.5} r={4.5} />
          <circle cx={315.5} cy={179.5} r={4.5} />
          <circle cx={339.5} cy={179.5} r={4.5} />
          <circle cx={363.5} cy={179.5} r={4.5} />
          <circle cx={387.5} cy={179.5} r={4.5} />
          <circle cx={411.5} cy={179.5} r={4.5} />
          <circle cx={435.5} cy={179.5} r={4.5} />
          <circle cx={459.5} cy={179.5} r={4.5} />
          <circle cx={483.5} cy={179.5} r={4.5} />
          <circle cx={507.5} cy={179.5} r={4.5} />
          <circle cx={3.5} cy={203.5} r={4.5} />
          <circle cx={27.5} cy={203.5} r={4.5} />
          <circle cx={51.5} cy={203.5} r={4.5} />
          <circle cx={75.5} cy={203.5} r={4.5} />
          <circle cx={99.5} cy={203.5} r={4.5} />
          <circle cx={123.5} cy={203.5} r={4.5} />
          <circle cx={147.5} cy={203.5} r={4.5} />
          <circle cx={171.5} cy={203.5} r={4.5} />
          <circle cx={195.5} cy={203.5} r={4.5} />
          <circle cx={219.5} cy={203.5} r={4.5} />
          <circle cx={243.5} cy={203.5} r={4.5} />
          <circle cx={267.5} cy={203.5} r={4.5} />
          <circle cx={291.5} cy={203.5} r={4.5} />
          <circle cx={315.5} cy={203.5} r={4.5} />
          <circle cx={339.5} cy={203.5} r={4.5} />
          <circle cx={363.5} cy={203.5} r={4.5} />
          <circle cx={387.5} cy={203.5} r={4.5} />
          <circle cx={411.5} cy={203.5} r={4.5} />
          <circle cx={435.5} cy={203.5} r={4.5} />
          <circle cx={459.5} cy={203.5} r={4.5} />
          <circle cx={483.5} cy={203.5} r={4.5} />
          <circle cx={507.5} cy={203.5} r={4.5} />
          <circle cx={3.5} cy={227.5} r={4.5} />
          <circle cx={27.5} cy={227.5} r={4.5} />
          <circle cx={51.5} cy={227.5} r={4.5} />
          <circle cx={75.5} cy={227.5} r={4.5} />
          <circle cx={99.5} cy={227.5} r={4.5} />
          <circle cx={123.5} cy={227.5} r={4.5} />
          <circle cx={147.5} cy={227.5} r={4.5} />
          <circle cx={171.5} cy={227.5} r={4.5} />
          <circle cx={195.5} cy={227.5} r={4.5} />
          <circle cx={219.5} cy={227.5} r={4.5} />
          <circle cx={243.5} cy={227.5} r={4.5} />
          <circle cx={267.5} cy={227.5} r={4.5} />
          <circle cx={291.5} cy={227.5} r={4.5} />
          <circle cx={315.5} cy={227.5} r={4.5} />
          <circle cx={339.5} cy={227.5} r={4.5} />
          <circle cx={363.5} cy={227.5} r={4.5} />
          <circle cx={387.5} cy={227.5} r={4.5} />
          <circle cx={411.5} cy={227.5} r={4.5} />
          <circle cx={435.5} cy={227.5} r={4.5} />
          <circle cx={459.5} cy={227.5} r={4.5} />
          <circle cx={483.5} cy={227.5} r={4.5} />
          <circle cx={507.5} cy={227.5} r={4.5} />
          <circle cx={3.5} cy={251.5} r={4.5} />
          <circle cx={27.5} cy={251.5} r={4.5} />
          <circle cx={51.5} cy={251.5} r={4.5} />
          <circle cx={75.5} cy={251.5} r={4.5} />
          <circle cx={99.5} cy={251.5} r={4.5} />
          <circle cx={123.5} cy={251.5} r={4.5} />
          <circle cx={147.5} cy={251.5} r={4.5} />
          <circle cx={171.5} cy={251.5} r={4.5} />
          <circle cx={195.5} cy={251.5} r={4.5} />
          <circle cx={219.5} cy={251.5} r={4.5} />
          <circle cx={243.5} cy={251.5} r={4.5} />
          <circle cx={267.5} cy={251.5} r={4.5} />
          <circle cx={291.5} cy={251.5} r={4.5} />
          <circle cx={315.5} cy={251.5} r={4.5} />
          <circle cx={339.5} cy={251.5} r={4.5} />
          <circle cx={363.5} cy={251.5} r={4.5} />
          <circle cx={387.5} cy={251.5} r={4.5} />
          <circle cx={411.5} cy={251.5} r={4.5} />
          <circle cx={435.5} cy={251.5} r={4.5} />
          <circle cx={459.5} cy={251.5} r={4.5} />
          <circle cx={483.5} cy={251.5} r={4.5} />
          <circle cx={507.5} cy={251.5} r={4.5} />
        </g>
      </g>
    </svg>
  );
}

export default SvgCirclesBottomLeft;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './button.module.scss';
import classNames from 'classnames/bind';
import { Icon } from '@components/ui';
import { Title } from '@components/text';
import { Count } from '@components/badge';
import { Colors, Spacings } from '@components/variables';
const cx = classNames.bind(styles);

const Button = (props) => {
  let classes = cx(
    {
      button: true,
      outline: props.outline,
      disabled: props.disabled,
      loading: props.loading,
      reverse: props.iconRight,
      rounded: props.rounded,
      link: props.link,
      column: props.column,
    },
    props.color,
    props.size,
    props.className
  );
  let iconClasses = cx({
    title: true,
    icon: true,
    withoutTitle: !props.text,
  });
  const CustomComponent =
    props.action && typeof props.action == 'string' ? 'a' : 'button';
  const textColor = props.textColor
    ? props.textColor
    : ['stable-300', 'stable-500', 'stable-700'].includes(props.color)
    ? 'stable-900'
    : ['positive-300'].includes(props.color)
    ? 'positive'
    : props.outline || props.link
    ? props.color
    : ['light'].includes(props.color)
    ? 'positive'
    : ['transparent'].includes(props.color)
    ? 'stable-900'
    : 'light';

  if (props.count) {
    props.count.className = props.count.className
      ? props.count.className
      : props.icon
      ? styles.iconCount
      : styles.count;
    props.count.style = {
      boxShadow: `0 0 0 2px rgba(var(--color-${props.color}))`,
    };
  }

  const _dataToAttr = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [`data-${key}`, value])
    );
  };
  const dataAttributes = props.data ? _dataToAttr(props.data) : [];

  const _customDataToAttr = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, value])
    );
  };
  const attributes = props.customData
    ? _customDataToAttr(props.customData)
    : [];
  return (
    <CustomComponent
      className={classes}
      href={
        props.action && typeof props.action == 'string' ? props.action : null
      }
      onClick={
        props.action && typeof props.action == 'function' ? props.action : null
      }
      type={props.type}
      style={props.style}
      {...dataAttributes}
      {...attributes}
    >
      {props.icon && (
        <Icon
          icon={props.icon.icon ? props.icon.icon : props.icon}
          color={textColor}
          className={iconClasses}
          badge={props.badge}
          width={props.icon.width}
          height={props.icon.height}
        />
      )}
      {props.count && <Count color={textColor} {...props.count} />}
      {props.text && (
        <Title
          text={props.text}
          inline={true}
          className={styles.title}
          size={props.size == 's' ? 'label' : 'body'}
          color={textColor}
          emphasis={props.emphasis == undefined ? true : props.emphasis}
        />
      )}
    </CustomComponent>
  );
};

Button.propTypes = {
  /** The label of the button */
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  /** The color variant of the button */
  color: PropTypes.oneOf(Colors),
  /** Size of the button */
  size: PropTypes.oneOf(Spacings),
  /** Outlined button  */
  outline: PropTypes.bool,
  /** Rounded button  */
  rounded: PropTypes.bool,
  /** Regular link  */
  link: PropTypes.bool,
  /** The action onclick can be an url or a (js) function */
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /** All props of Count badge, rendered inside the button */
  count: PropTypes.shape(Count.propTypes),
  /** Array of key: value pairs of data-attributes (the key should be added without the data- part) */
  data: PropTypes.object,
  /** Array of key: value pairs of custom attributes (like target="_blank", download) */
  customData: PropTypes.object,
};

Button.defaultProps = {
  text: 'Please add a title',
  color: 'positive-300',
  outline: false,
  size: 'm',
  rounded: false,
  link: false,
  action: () => {},
};

//Needed for Storybook
Button.displayName = 'Button';

export default Button;

import React from 'react';
import Image from './image';
import { Divider } from '@components/ui';

export default {
  title: 'Remark/ui/Image',
  component: Image,
};

const Template = (args) => <Image {...args} />;

export const Default = Template.bind({});
Default.args = {
  src: 'https://picsum.photos/1024/400',
};

export const Variants = () => (
  <>
    <Image src={'https://picsum.photos/1024/400'} />
    <Divider height={30} color={'transparent'} />
    <Image
      src={'https://picsum.photos/1024/400'}
      objectFit={true}
      height={200}
    />
  </>
);

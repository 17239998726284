import React from 'react';
import PropTypes from 'prop-types';
import styles from './count.module.scss';
import classNames from 'classnames/bind';
import { Colors, Spacings } from '@components/variables';
import { Title } from '@components/text';
import { Icon } from '@components/ui';
const cx = classNames.bind(styles);

const Count = (props) => {
  const classes = cx(
    {
      count: true,
      round: props.round,
      lighten: props.lighten,
    },
    props.color,
    props.size,
    props.className
  );
  const textColor = props.lighten
    ? props.color
    : props.textColor
      ? props.textColor
      : ['light', 'stable-300', 'stable-500', 'stable-700'].includes(props.color)
        ? 'stable-900'
        : ['positive-300'].includes(props.color)
          ? 'positive'
          : 'light';
  const textSize = props.textSize || 'body';

  return (
    <div className={classes} style={props.style}>
      {props.icon ? (
        <Icon icon={props.icon} color={props.color} {...props.icon} />
      ) : (
          <Title
            text={props.text.toString()}
            color={textColor}
            size={textSize}
            inline={true}
            className={styles.title}
          />
        )}
    </div>
  );
};

Count.propTypes = {
  /** The number inside the badge */
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /** The color of the badge */
  color: PropTypes.oneOf(Colors),
  /** The size of the badge */
  size: PropTypes.oneOf(Spacings),
  /** When a circular shape is desirable */
  round: PropTypes.bool,
  /** When the background-color should have an opacity */
  lighten: PropTypes.bool,
};

Count.defaultProps = {
  size: 's',
  color: 'positive',
  text: 0,
};

//Needed for Storybook
Count.displayName = 'Count';

export default Count;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './steps.module.scss';
import classNames from 'classnames/bind';
import { Colors, Spacings } from '@components/variables';
import { Title } from '@components/text';
const cx = classNames.bind(styles);

const Steps = (props) => {
  const textColor = props.textColor
    ? props.textColor
    : ['stable-300', 'stable-500', 'stable-700'].includes(props.color)
      ? 'stable-900'
      : ['light', 'positive-300'].includes(props.color)
        ? 'positive'
        : ['transparent'].includes(props.color)
          ? 'stable-900'
          : 'light';
  const classes = cx(
    {
      steps: true,
      [`spacing-${props.size}`]: props.size,
    },
    textColor,
    props.className
  );

  const stepClasses = cx(
    {
      step: true,
    },
    textColor
  );

  return (
    <ol className={classes}>
      {props.steps.map((step, index) => {
        const linkClasses = cx(
          {
            link: true,
            finished: step.finished,
            active: step.active,
          },
          step.active || step.finished ? props.color : textColor
        );
        const action = step.action || '#';
        return (
          <li className={stepClasses} key={index}>
            <a href={action} className={linkClasses}>
              {step.text && (
                <Title
                  className={styles.title}
                  inline={true}
                  size={'label'}
                  align={'center'}
                  color={textColor}
                  {...step}
                />
              )}
            </a>
          </li>
        );
      })}
    </ol>
  );
};

Steps.propTypes = {
  /** The background-color of the steps */
  color: PropTypes.oneOf(Colors).isRequired,
  /** The space around the steps */
  size: PropTypes.oneOf(Spacings),
  /** The actual steps */
  steps: PropTypes.arrayOf(PropTypes.shape(Title.propTypes)),
};

Steps.defaultProps = {
  color: 'stable-700',
  size: 'm',
};

//Needed for Storybook
Steps.displayName = 'Steps';

export default Steps;

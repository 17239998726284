import React from 'react';
import PropTypes from 'prop-types';
import styles from './element.module.scss';
import classNames from 'classnames/bind';
import { Button, IconLeft, IconRight } from '@components/button';
import { Badge } from '@components/badge';
import { Title } from '@components/text';
import { Divider, Tooltip } from '@components/ui';
import Simple from '../simple/simple';
import Textarea from '../textarea/textarea';
import Group from '../group/group';
import Option from '../option/option';
import Datepicker from '../datepicker/datepicker';
import Select from '../select/select';
import File from '../file/file';

const cx = classNames.bind(styles);

const Element = (props) => {
  let classes = cx(
    {
      element: true,
      inline: props.inline,
      error: props.errors && props.errors.length > 0,
    },
    props.className
  );
  const inputClasses = cx({
    input: !['checkbox', 'radio', 'button', 'submit', 'file'].includes(
      props.type
    ),
  });
  let extraProps = {};
  const identifier = props.identifier
    ? props.identifier
    : Math.random().toString(36).substr(2, 9);
  const InputElement = ['button', 'submit'].includes(props.type)
    ? props.iconRight
      ? IconRight
      : props.icon || props.iconLeft
      ? IconLeft
      : Button
    : ['datepicker'].includes(props.type)
    ? Datepicker
    : ['select'].includes(props.type)
    ? Select
    : ['radio', 'checkbox'].includes(props.type)
    ? props.options
      ? Group
      : Option
    : ['textarea'].includes(props.type)
    ? Textarea
    : ['file'].includes(props.type)
    ? File
    : Simple;
  if (['button', 'submit'].includes(props.type)) {
    extraProps.color = 'positive-300';
    extraProps.size = 'l';
    extraProps.icon = props.iconRight || props.iconLeft || props.icon;
  }
  if (['datepicker'].includes(props.type)) {
    extraProps.color = 'positive';
  }

  const _customDataToAttr = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, value.toString()])
    );
  };
  const attributes = props.customData
    ? _customDataToAttr(props.customData)
    : [];
  return (
    <div className={classes}>
      {(props.label || props.errors) && (
        <div className={styles.wrapper}>
          <label htmlFor={identifier} className={styles.label}>
            {props.label && (
              <Title
                text={props.label}
                size={'label'}
                inline={true}
                color={
                  props.errors && props.errors.length > 0
                    ? 'assertive'
                    : 'stable-900'
                }
              />
            )}
            {props.required && (
              <Title
                text={'*'}
                size={'label'}
                inline={true}
                color={
                  props.errors && props.errors.length > 0
                    ? 'assertive'
                    : 'stable-900'
                }
              />
            )}
            {props.errors &&
              props.errors.map((error, index) => {
                return (
                  <Badge
                    key={index}
                    color={'assertive'}
                    text={error}
                    size={'xs'}
                    className={styles.badge}
                  />
                );
              })}
            {props.labelAction && (
              <a
                href={props.labelAction.action}
                className={styles.labelAction}
                tabIndex={2}
              >
                <Title
                  text={props.labelAction.text}
                  color={'positive'}
                  size={'label'}
                />
              </a>
            )}
          </label>
          {props.tooltip && (
            <Tooltip
              className={styles.tooltip}
              button={{
                text: '?',
                rounded: true,
                size: 'xs',
                color: 'stable-700',
                outline: true,
              }}
              trigger={'mouseenter'}
              simple={true}
              {...props.tooltip}
            />
          )}
        </div>
      )}
      {props.label && (
        <Divider
          width={props.inline ? 15 : null}
          height={!props.inline ? 5 : null}
          color={'transparent'}
        />
      )}
      <InputElement
        id={identifier}
        className={inputClasses}
        {...extraProps}
        input={(e) => {
          document.documentElement.setAttribute('data-has-changes', true);
        }}
        {...props}
        attributes={attributes}
      />
    </div>
  );
};

Element.propTypes = {
  /** The input type */
  type: PropTypes.oneOf([
    'text',
    'password',
    'tel',
    'email',
    'url',
    'textarea',
    'datepicker',
    'select',
    'checkbox',
    'radio',
    'button',
    'submit',
    'datepicker',
    'file',
  ]).isRequired,
  /** The name of the input */
  name: PropTypes.string.isRequired,
  /** The value of the input */
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  /** True when the label should be placed before the input */
  inline: PropTypes.bool,
  /** The label text */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** The placeholder text */
  placeholder: PropTypes.string,
  /** Whether the input is disabled */
  disabled: PropTypes.bool,
  /** Whether the input is readonly */
  readonly: PropTypes.bool,
  /** Whether the input is required */
  required: PropTypes.bool,
  /** The tooltip */
  tooltip: PropTypes.shape({}),
};

Element.defaultProps = {
  type: 'text',
  name: 'default',
};

//Needed for Storybook
Element.displayName = 'Element';

export default Element;

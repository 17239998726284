/* ============================================== */
/* Explanation pdf folder                         */
/* When a PDF is being rendered, icons need to be */
/* inlined in the files as symbols are not        */
/* supported. Run yarn pdfsvg iconname to generate*/
/* the icon for the pdf. It will be placed in the */
/* pdf folder as a 'reactified' svg               */
/* ==============================================  */

import React from 'react';
import PropTypes from 'prop-types';
import sprite from './sprite.svg';
import styles from './icon.module.scss';
import classNames from 'classnames/bind';
import { Colors } from '@components/variables';
const cx = classNames.bind(styles);
const Icon = (props) => {
  let Svg = false;
  if (!props.symbol) {
    try {
      Svg = require(`./pdf/${props.icon}`).default;
    } catch (error) {}
  }
  let classes = cx(props.color, props.className, {
    icon: true,
  });
  return !Svg && !props.symbol ? (
    <p style={{ color: '#f00' }}>
      Icon missing! Run <code>yarn svgpdf iconname</code> in the root of the
      project
    </p>
  ) : props.symbol ? (
    <svg
      className={classes}
      viewBox={`0 0 ${props.width} ${props.height}`}
      preserveAspectRatio='xMidYMid meet'
      style={{
        width: props.width,
        height: props.height,
      }}
    >
      <use xlinkHref={`${sprite}#symbol-${props.icon}`} />
    </svg>
  ) : (
    <Svg
      style={{
        width: props.width,
        height: props.height,
      }}
    />
  );
};

Icon.propTypes = {
  /** The color of the icon */
  color: PropTypes.oneOf(Colors),
  /** The name of the icon to embed (from the generated sprite) */
  icon: PropTypes.string.isRequired,
  /** The width of the icon */
  width: PropTypes.number,
  /** The height of the icon */
  height: PropTypes.number,
  /** Whether the stroke should be colored instead of the fill */
  stroke: PropTypes.bool,
  /** Whether the stroke should be rendered as symbol vs inline svg */
  symbol: PropTypes.bool,
};

Icon.defaultProps = {
  icon: 'empty',
  width: 22,
  height: 22,
  symbol: true,
};

//Needed for Storybook
Icon.displayName = 'Icon';

export default Icon;

import React from 'react';
import Confirm from './confirm';
import { Button } from '@components/button';
import { Content } from '@components/text';
import { Divider } from '@components/ui';

export default {
  title: 'Remark/modal/Confirm',
  component: Confirm,
};

const Template = (args) => (
  <div>
    <Confirm {...args} open />
    <Content
      text={
        'In rails, this works by adding data-confirm="message" to an element. Rails captures all data-confirms and triggers the window.confirm'
      }
    />
    <Divider height={10} color={'transparent'} />
    <Button
      text={'Delete'}
      color={'assertive'}
      action={() => window.confirm('Are you sure you want to do this?')}
    />
  </div>
);

export const Default = Template.bind({});
Default.args = {
  title: {
    text: 'Are you sure you want to leave?',
  },
  buttons: [
    {
      text: 'Cancel',
      color: 'stable-700',
      type: 'cancel',
    },
    {
      text: 'Ok',
      color: 'positive',
      type: 'continue',
    },
  ],
};

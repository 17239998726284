import React from 'react';
import Steps from './steps';
import { Divider } from '@components/ui';
import { Colors as ColorNames } from '@components/variables';

export default {
  title: 'Remark/progress/Steps',
  component: Steps,
};

const Template = (args) => {
  return (
    <div style={{ backgroundColor: 'rgba(var(--color-stable-300), 1)' }}>
      <Steps {...args} />
    </div>
  );
};

export const Default = Template.bind({});
Default.args = {
  steps: [
    {
      finished: true,
      text: 'Claiming for',
    },
    {
      finished: true,
      text: 'Insured data',
    },
    {
      active: true,
      text: 'Claim',
    },
    {
      text: 'Documents',
    },
    {
      text: 'Review',
    },
    {
      text: 'Legal',
    },
  ],
};

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      const bgColor =
        color == 'positive-300'
          ? `rgba(var(--color-${color}), 0.15)`
          : `rgba(var(--color-${color}), 1)`;
      return (
        <div key={index}>
          <div style={{ backgroundColor: bgColor }}>
            <Steps {...Default.args} color={color} />
          </div>
          <Divider height={15} color={'transparent'} />
        </div>
      );
    })}
  </>
);

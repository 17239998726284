import React from 'react';
import PieChart from './pie';
import BarChart from "../bar/bar";

export default {
  title: 'Remark/chart/PieChart',
  component: PieChart,
};

const Template = (args) => <PieChart {...args} />;

export const Default = Template.bind({});

Default.args = {
  data: [
    {
      id: '1',
      label: 'positive',
      value: 288,
      color: 'positive',
    },
    {
      id: '2',
      label: 'assertive',
      value: 200,
      color: 'assertive',
    },
    {
      id: '3',
      label: 'royal',
      value: 80,
      color: 'royal',
    },
    {
      id: '4',
      label: 'energized',
      value: 240,
      color: 'energized',
    },
    {
      id: '5',
      label: 'balanced',
      value: 200,
      color: 'balanced',
    },
  ],
};

export const WithPercentage = () => {
  return (
    <PieChart
      {...Default.args}
      colors={['positive', 'balanced', 'assertive']}
      data={[
        {
          id: '1',
          label: 'positive',
          value: 288,
          percentage: 20,
          color: 'positive',
        },
        {
          id: '2',
          label: 'assertive',
          value: 200,
          percentage: 30,
          color: 'assertive',
        },
        {
          id: '3',
          label: 'royal',
          value: 80,
          percentage: 10,
          color: 'royal',
        },
        {
          id: '4',
          label: 'energized',
          value: 240,
          percentage: 10,
          color: 'energized',
        },
        {
          id: '5',
          label: 'balanced',
          value: 200,
          percentage: 30,
          color: 'balanced',
        },
      ]}
    />
  );
};

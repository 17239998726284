import React from 'react';
import PropTypes from 'prop-types';
import styles from './icon-title.module.scss';
import classNames from 'classnames/bind';
import { Icon, Divider } from '@components/ui';
import { Title } from '@components/text';
const cx = classNames.bind(styles);

const IconTitle = (props) => {
  let classes = cx({
    iconTitle: true,
    reverse: props.reverse,
  });
  return (
    <div className={classes}>
      {props.icon && <Icon color={props.color} {...props.icon} />}
      {props.icon && props.title && (
        <Divider width={15} color={'transparent'} />
      )}
      {props.title && <Title color={props.color} {...props.title} />}
    </div>
  );
};

IconTitle.propTypes = {
  /** The props of the icon component */
  icon: PropTypes.shape(Icon.propTypes),
  /** The props of the title component */
  title: PropTypes.shape(Title.propTypes),
};

//Needed for Storybook
IconTitle.displayName = 'Title';

export default IconTitle;

import React from 'react';
import Badge from './badge';
import { Colors as ColorNames } from '@components/variables';

export default {
  title: 'Remark/badge/Badge',
  component: Badge,
};

const Template = (args) => <Badge {...args} />;

export const Default = Template.bind({});
Default.args = {
  text: 'Badge',
};

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      return (
        <Badge
          key={index}
          text={color.charAt(0).toUpperCase() + color.slice(1)}
          color={color}
        />
      );
    })}
  </>
);

export const Outline = () => (
  <>
    {ColorNames.map((color, index) => {
      return (
        <Badge
          key={index}
          text={color.charAt(0).toUpperCase() + color.slice(1)}
          color={color}
          outline={true}
        />
      );
    })}
  </>
);

export const Sizes = () => (
  <>
    <Badge text={'Small'} size={'s'} />
    <Badge text={'Medium'} size={'m'} />
    <Badge text={'Large'} size={'l'} />
  </>
);

export const Status = () => (
  <>
    <Badge text={'Draft'} size={'m'} status={true} color={'stable-300'} />
    <Badge text={'Approved'} size={'m'} status={true} color={'balanced'} />
    <Badge text={'Pending'} size={'m'} status={true} color={'energized'} />
    <Badge text={'Declined'} size={'m'} status={true} color={'assertive'} />
  </>
);

import React from 'react';
import PropTypes from 'prop-types';
import styles from './comment.module.scss';
import classNames from 'classnames/bind';
import { Avatar, Divider } from '@components/ui';
import { Wrapper } from '@components/card';
import { Title, Content } from '@components/text';
import { Colors, Spacings } from '@components/variables';
const cx = classNames.bind(styles);

const CommentCard = (props) => {
  const color = props.sender ? 'positive-300' : props.color;
  let classes = cx(
    {
      comment: true,
      reverse: props.sender,
      default: !props.sender,
      note: props.note,
    },
    props.className
  );

  let meta = [];
  if (!props.note && props.date) {
    meta.push(props.date);
  }
  if (!props.note && props.avatar.name) {
    meta.push(props.avatar.name);
  }

  const textColor = [
    'transparent',
    'light',
    'stable-300',
    'stable-500',
    'stable-700',
    'positive-300',
  ].includes(color)
    ? 'dark'
    : 'light';

  return (
    <div className={classes}>
      {props.avatar && <Avatar className={styles.avatar} {...props.avatar} />}
      <div className={styles.message}>
        {props.note && (
          <div className={styles.noteMeta}>
            {props.avatar && props.avatar.name && (
              <Title text={props.avatar.name} size={'h5'} />
            )}
            {props.date && <Content text={props.date} size={'label'} />}
          </div>
        )}
        {props.note ? (
          <Content color={textColor} {...props.content} />
        ) : (
          <Wrapper
            className={styles.card}
            size={props.note ? 'no-p' : props.size}
            color={color}
          >
            {props.content && <Content color={textColor} {...props.content} />}
          </Wrapper>
        )}
        {meta.length > 0 && <Divider height={10} color={'transparent'} />}
        {meta.length > 0 && (
          <Content
            className={styles.date}
            text={meta.join(' / ')}
            size={'label'}
            align={props.sender ? 'right' : 'left'}
          />
        )}
      </div>
    </div>
  );
};

CommentCard.propTypes = {
  /** The size of the card */
  size: PropTypes.oneOf(Spacings),
  /** The color of the card */
  color: PropTypes.oneOf(Colors),
  /** The content of the comment */
  content: PropTypes.shape(Content.propTypes),
  /** The avatar of the sender of the comment */
  avatar: PropTypes.shape(Avatar.propTypes),
  /** The date of the comment */
  date: PropTypes.string,
  /** Whether the user is the sender of the comment */
  sender: PropTypes.bool,
};

CommentCard.defaultProps = {
  color: 'light',
  size: 'l',
};

//Needed for Storybook
CommentCard.displayName = 'CommentCard';

export default CommentCard;

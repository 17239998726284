import React from 'react';
import NavBar from './navbar';
import { Divider } from '@components/ui';
import { Colors as ColorNames } from '@components/variables';

export default {
  title: 'Remark/layout/NavBar',
  component: NavBar,
};

const Template = (args) => <NavBar {...args} />;

export const Default = Template.bind({});
Default.args = {};

export const Colors = () => (
  <>
    <NavBar
      color={'light'}
      left={{
        logo: true,
        buttons: [
          {
            action: '#',
            text: 'Back',
            iconLeft: 'chevron-left-round',
          },
        ],
        tabs: [
          {
            color: 'light',
            icon: 'comment',
            count: {
              text: '12',
              color: 'assertive',
            },
            text: 'Comments',
            active: true,
          },
          {
            color: 'light',
            icon: 'calendar',
            text: 'Schedule',
          },
        ],
      }}
      right={{
        buttons: [
          {
            action: '#',
            text: 'Another button',
            color: 'light',
            iconRight: 'chevron-right-round',
          },
        ],
      }}
    />
    <Divider height={15} color={'light'} />
    <NavBar
      color={'positive'}
      left={{
        logo: true,
        buttons: [
          {
            action: '#',
            text: 'Back',
            iconLeft: 'chevron-left-round',
          },
        ],
        tabs: [
          {
            icon: 'comment',
            count: {
              text: '12',
              color: 'assertive',
            },
            text: 'Comments',
            active: true,
          },
          {
            icon: 'calendar',
            text: 'Schedule',
          },
        ],
      }}
      right={{
        buttons: [
          {
            action: '#',
            text: 'Another button',
            color: 'light',
            iconRight: 'chevron-right-round',
          },
        ],
      }}
    />
  </>
);

export const Variants = () => (
  <>
    <NavBar
      color={'light'}
      left={{
        tabs: [
          {
            color: 'light',
            icon: 'comment',
            count: {
              text: '12',
              color: 'assertive',
            },
            text: 'Comments',
            active: true,
          },
          {
            color: 'light',
            icon: 'calendar',
            text: 'Schedule',
          },
        ],
      }}
      right={{
        profile: {
          name: 'Henk de lip',
          actions: [
            {
              icon: {
                icon: 'warning-square',
                color: 'positive',
              },
              text: 'Change password',
              action: '#',
            },
            {
              icon: {
                icon: 'warning-square',
                color: 'assertive',
              },
              text: 'Logout',
              action: '#',
            },
            {
              icon: {
                icon: 'warning-square',
                color: 'energized',
              },
              text: 'Remove account',
              action: '#',
            },
          ],
        },
      }}
    />
    <Divider height={15} color={'light'} />
    <NavBar
      color={'positive'}
      left={{
        buttons: [
          {
            action: '#',
            text: 'Back',
            iconLeft: 'chevron-left-round',
          },
        ],
        tabs: [
          {
            icon: 'comment',
            count: {
              text: '12',
              color: 'assertive',
            },
            text: 'Comments',
            active: true,
          },
          {
            icon: 'calendar',
            text: 'Schedule',
          },
        ],
      }}
    />
    <Divider height={15} color={'light'} />
    <NavBar
      color={'positive'}
      left={{
        buttons: [
          {
            action: '#',
            text: 'Back',
            iconLeft: 'chevron-left-round',
          },
          {
            action: '#',
            text: 'Another button',
            iconLeft: 'chevron-right-round',
          },
        ],
      }}
    />
    <Divider height={15} color={'light'} />
    <NavBar
      color={'positive'}
      left={{
        tabs: [
          {
            icon: 'comment',
            count: {
              text: '12',
              color: 'assertive',
            },
            text: 'Comments',
            active: true,
          },
          {
            icon: 'calendar',
            text: 'Schedule',
          },
        ],
      }}
    />
    <Divider height={15} color={'light'} />
    <NavBar
      color={'positive'}
      left={{
        buttons: [
          {
            action: '#',
            text: 'Back',
            iconLeft: 'chevron-left-round',
          },
        ],
      }}
      right={{
        buttons: [
          {
            action: '#',
            text: 'Download PDF',
            iconLeft: 'pdf',
          },
          {
            action: '#',
            text: 'Another button',
            color: 'light',
            iconRight: 'chevron-right-round',
          },
        ],
      }}
    />
    <Divider height={15} color={'light'} />
    <NavBar
      color={'positive'}
      left={{
        buttons: [
          {
            action: '#',
            text: 'Back',
            iconLeft: 'chevron-left-round',
          },
        ],
      }}
      center={{
        steps: [
          {
            finished: true,
            text: 'Claiming for',
          },
          {
            active: true,
            text: 'Claim',
          },
          {
            text: 'Documents',
          },
          {
            text: 'Legal',
          },
        ],
      }}
      right={{
        buttons: [
          {
            action: '#',
            text: 'Download PDF',
            iconLeft: 'pdf',
          },
        ],
      }}
    />
  </>
);

export const Sizes = () => (
  <>
    <NavBar
      size={'xs'}
      color={'positive'}
      left={{
        buttons: [
          {
            action: '#',
            text: 'Back',
            iconLeft: 'chevron-left-round',
          },
        ],
      }}
    />
    <Divider height={15} color={'light'} />
    <NavBar
      size={'s'}
      color={'positive'}
      left={{
        buttons: [
          {
            action: '#',
            text: 'Back',
            iconLeft: 'chevron-left-round',
          },
        ],
      }}
    />
    <Divider height={15} color={'light'} />
    <NavBar
      size={'m'}
      color={'positive'}
      left={{
        buttons: [
          {
            action: '#',
            text: 'Back',
            iconLeft: 'chevron-left-round',
          },
        ],
      }}
    />
    <Divider height={15} color={'light'} />
    <NavBar
      size={'l'}
      color={'positive'}
      left={{
        buttons: [
          {
            action: '#',
            text: 'Back',
            iconLeft: 'chevron-left-round',
          },
        ],
      }}
    />
  </>
);

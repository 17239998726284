import React from 'react';
import DetailsCard from './details';

export default {
  title: 'Remark/card/DetailsCard',
  component: DetailsCard,
};

const Template = (args) => <DetailsCard {...args} />;

export const Default = Template.bind({});

Default.args = {
  details: [
    {
      label: {
        text: 'Name',
      },
      value: {
        text: 'Ben Smith',
      },
    },
    {
      label: {
        text: 'E-mail',
      },
      value: {
        text: 'ben.smith@gmail.com',
      },
    },
    {
      label: {
        text: 'Address',
      },
      value: {
        text: '7, Sims Avenue, Star hill',
      },
    },
    {
      label: {
        text: 'Postal code',
      },
      value: {
        text: '57181900',
      },
    },
    {
      label: {
        text: 'City',
      },
      value: {
        text: 'London',
      },
    },
    {
      label: {
        text: 'Date of birth',
      },
      value: {
        text: '26-11-1975',
      },
    },
    {
      label: {
        text: 'Gender',
      },
      value: {
        text: 'Male',
      },
    },
    {
      label: {
        text: 'Insurance policy no.',
      },
      value: {
        text: 'LA7160501-6AD',
      },
    },
    {
      label: {
        text: 'Occupation',
      },
      value: {
        text: 'Chief design officer',
      },
    },
  ],
};

export const Details = () => (
  <>
    <DetailsCard
      {...Default.args}
    />
  </>
);

export const TitleDescriptionAndStatus = () => (
  <>
    <DetailsCard
      {...Default.args}
      title={{
        text: 'Claim #8969131-1',
      }}
      date={{
        text: '16-07-2020',
      }}
      badge={{
        text: 'Approved',
        color: 'balanced',
      }}
      description={{
        label: {
          text: 'Description cause of work leave',
        },
        value: {
          text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed lacinia mauris. Sed nec interdum eros, ac laoreet justo.',
        },
      }}
      button={{
        text: 'Add verdict'
      }}
    />
  </>
);

export const TitleDescriptionCommentAndStatus = () => (
  <>
    <DetailsCard
      {...Default.args}
      title={{
        text: 'Claim #8969131-1',
      }}
      date={{
        text: '16-07-2020',
      }}
      badge={{
        text: 'Approved',
        color: 'balanced',
      }}
      description={{
        label: {
          text: 'Description cause of work leave',
        },
        value: {
          text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed lacinia mauris. Sed nec interdum eros, ac laoreet justo.',
        },
      }}
      comment={{
        label: {
          text: 'Comment',
        },
        value: {
          text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed lacinia mauris. Sed nec.',
        },
      }}
    />
  </>
);

export const Disabled = () => (
  <>
    <DetailsCard
      {...Default.args}
      title={{
        text: 'Claim #8969131-1',
      }}
      date={{
        text: '16-07-2020',
      }}
      badge={{
        text: 'Approved',
        color: 'balanced',
      }}
      description={{
        label: {
          text: 'Description cause of work leave',
        },
        value: {
          text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed lacinia mauris. Sed nec interdum eros, ac laoreet justo.',
        },
      }}
      comment={{
        label: {
          text: 'Comment',
        },
        value: {
          text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed lacinia mauris. Sed nec.',
        },
      }}
      disabled={true}
    />
  </>
);

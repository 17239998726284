import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './expandable.module.scss';
import classNames from 'classnames/bind';
import { Divider } from '@components/ui';
import { Wrapper } from '@components/card';
import { Content } from '@components/text';
import { IconLeft, IconRight, Button } from '@components/button';
import { Colors, Spacings } from '@components/variables';
const cx = classNames.bind(styles);

const ExpandableCard = (props, collapsed) => {
  let classes = cx(props.className);
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  const toggle = () => {
    setIsCollapsed(!isCollapsed);
    document && document.activeElement.blur();
  };
  return (
    <Wrapper
      className={classes || styles.expandable}
      size={props.size}
      color={props.color}
    >
      <div className={styles.head}>
        {props.file && (
          <IconLeft
            color={'positive'}
            link={true}
            customData={{
              download: '',
              target: '_blank',
            }}
            {...props.file}
          />
        )}
        <div className={styles.inner}>
          {props.date && <Content size={'s'} {...props.date} />}
          {props.remove && (
            <Button
              className={styles.remove}
              link={true}
              icon={'cross'}
              color={'stable-900'}
              {...props.remove}
            />
          )}
        </div>
      </div>
      {props.file && <Divider height={15} color={'transparent'} />}
      {props.content &&
        props.content.map((content, index) => {
          return (
            <div key={index}>
              {index > 0 && !isCollapsed && (
                <Divider height={15} color={'transparent'} />
              )}
              {(index == 0 || !isCollapsed) && (
                <Content
                  className={
                    index !== 0 && isCollapsed
                      ? styles.collapsed
                      : styles.expanded
                  }
                  {...content}
                />
              )}
            </div>
          );
        })}
      {props.content.length > 1 && (
        <Divider height={15} color={'transparent'} />
      )}
      {props.content.length > 1 && (
        <IconRight
          action={toggle}
          text={isCollapsed ? 'Expand' : 'Collapse'}
          className={styles.collapse}
          aria-expanded={isCollapsed}
          link={true}
          icon={{
            icon: isCollapsed ? 'arrow-down-thin' : 'arrow-up-thin',
            width: 10,
            height: 10,
          }}
          {...props.button}
        />
      )}
    </Wrapper>
  );
};

ExpandableCard.propTypes = {
  /** The size of the card */
  size: PropTypes.oneOf(Spacings),
  /** The color of the card */
  color: PropTypes.oneOf(['light', 'stable-300']),
  /** The file (title, icon, action) */
  file: PropTypes.shape(Button.propTypes),
  /** The file date */
  date: PropTypes.shape(Button.propTypes),
  /** The remove button */
  remove: PropTypes.shape(Button.propTypes),
  /** The content of the card */
  content: PropTypes.arrayOf(PropTypes.shape(Content.propTypes)),
};

ExpandableCard.defaultProps = {
  color: 'light',
  size: 'l',
};

//Needed for Storybook
ExpandableCard.displayName = 'ExpandableCard';

export default ExpandableCard;

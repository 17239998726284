import React from 'react';
import BarChartCard from './bar-chart';
import { Spacings, Colors as ColorNames } from '@components/variables';
import ActionCard from '../action/action';

export default {
  title: 'Remark/card/BarChartCard',
  component: BarChartCard,
};

const Template = (args) => <BarChartCard {...args} />;

export const Default = Template.bind({});

Default.args = {
  header: {
    text: 'Case history',
    filters: [
      {
        id: 'select_1',
        placeholder: 'All',
        type: 'select',
        name: 'status',
        options: [
          { value: 'chocolate', label: 'Chocolate' },
          { value: 'strawberry', label: 'Strawberry' },
          { value: 'vanilla', label: 'Vanilla' },
        ],
      },
      {
        placeholder: '2020',
        id: 'select_2',
        type: 'select',
        name: 'year',
        options: [
          { value: 'chocolate', label: 'Chocolate' },
          { value: 'strawberry', label: 'Strawberry' },
          { value: 'vanilla', label: 'Vanilla' },
        ],
      },
    ],
  },
  margin: {
    top: 50,
    bottom: 50,
    left: 50,
    right: 50,
  },
  data: [
    {
      label: 'Jan',
      values: [
        {
          color: 'positive',
          label: 'New',
          value: 140,
        },
        {
          color: 'assertive',
          label: 'Declined',
          value: 100,
        },
        {
          color: 'balanced',
          label: 'Approved',
          value: 100,
        },
      ],
    },
    {
      label: 'Feb',
      values: [
        {
          color: 'positive',
          label: 'New',
          value: 150,
        },
        {
          color: 'assertive',
          label: 'Declined',
          value: 100,
        },
        {
          color: 'balanced',
          label: 'Approved',
          value: 100,
        },
      ],
    },
    {
      label: 'Mar',
      values: [
        {
          color: 'positive',
          label: 'New',
          value: 120,
        },
        {
          color: 'assertive',
          label: 'Declined',
          value: 100,
        },
        {
          color: 'balanced',
          label: 'Approved',
          value: 100,
        },
      ],
    },
    {
      label: 'Apr',
      values: [
        {
          color: 'positive',
          label: 'New',
          value: 120,
        },
        {
          color: 'assertive',
          label: 'Declined',
          value: 100,
        },
        {
          color: 'balanced',
          label: 'Approved',
          value: 100,
        },
      ],
    },
  ],
};

export const WithLabelsData = () => (
  <>
    <BarChartCard
      {...Default.args}
      labels={[
        {
          color: 'positive',
          label: 'New',
          value: 120,
        },
        {
          color: 'assertive',
          label: 'Declined',
          value: 120,
        },
        {
          color: 'balanced',
          label: 'Approved',
          value: 100,
        },
        {
          color: 'positive-300',
          label: 'Percentage',
          value: 100,
        },
      ]}
    />
  </>
);

export const WithoutLabelsData = () => (
  <>
    <BarChartCard {...Default.args} />
  </>
);

export const WithPercentage = () => {
  return (
    <BarChartCard
      {...Default.args}
      labels={[
        {
          color: 'positive',
          label: 'New',
          value: 120,
        },
        {
          color: 'assertive',
          label: 'Declined',
          value: 120,
        },
        {
          color: 'balanced',
          label: 'Approved',
          value: 100,
        },
        {
          color: 'positive-300',
          label: 'Percentage',
          value: 100,
        },
      ]}
      data={[
        {
          label: 'Jan',
          values: [
            {
              color: 'positive',
              label: 'New',
              value: 140,
              percentage: 10,
            },
            {
              color: 'assertive',
              label: 'Declined',
              value: 100,
              percentage: 20,
            },
            {
              color: 'balanced',
              label: 'Approved',
              value: 100,
              percentage: 20,
            },
          ],
        },
        {
          label: 'Feb',
          values: [
            {
              color: 'positive',
              label: 'New',
              value: 150,
            },
            {
              color: 'assertive',
              label: 'Declined',
              value: 100,
            },
            {
              color: 'balanced',
              label: 'Approved',
              value: 100,
            },
          ],
        },
        {
          label: 'Mar',
          values: [
            {
              color: 'positive',
              label: 'New',
              value: 120,
            },
            {
              color: 'assertive',
              label: 'Declined',
              value: 100,
            },
            {
              color: 'balanced',
              label: 'Approved',
              value: 100,
            },
          ],
        },
        {
          label: 'Apr',
          values: [
            {
              color: 'positive',
              label: 'New',
              value: 120,
            },
            {
              color: 'assertive',
              label: 'Declined',
              value: 100,
            },
            {
              color: 'balanced',
              label: 'Approved',
              value: 100,
            },
          ],
        },
      ]}
    />
  );
};

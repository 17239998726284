import React from 'react';
import PropTypes from 'prop-types';
import styles from './textarea.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const Textarea = (props) => {
  let classes = cx(
    {
      textarea: true,
    },
    props.className,
  );
  return (
    <textarea
      id={props.id}
      className={classes}
      type={props.type}
      name={props.name}
      disabled={props.disabled}
      readOnly={props.readonly}
      placeholder={props.placeholder}
      defaultValue={props.value}
      onInput={props.input}
      resize={props.resize ? 1 : 0}
      {...props.attributes}
    />
  );
};

Textarea.propTypes = {
  /** The name of the textarea */
  name: PropTypes.string.isRequired,
  /** The value of the textarea */
  value: PropTypes.string,
  /** The placeholder text */
  placeholder: PropTypes.string,
  /** Whether the textarea is disabled */
  disabled: PropTypes.bool,
  /** Whether the textarea is readonly */
  readonly: PropTypes.bool,
  /** Whether the textarea is resizable */
  resize: PropTypes.bool,
  /** A unique id for the textarea */
  id: PropTypes.string.isRequired,
};

Textarea.defaultProps = {
  name: 'textarea',
  resize: false
};

//Needed for Storybook
Textarea.displayName = 'Simple';

export default Textarea;

import React from 'react';

import Count from './count';

export default {
  title: 'Remark/badge/Count',
  component: Count,
};

const Template = (args) => <Count {...args} />;

export const Default = Template.bind({});
Default.args = {
  text: '1',
};

export const Colors = () => (
  <>
    <Count text={'12'} color={'light'} />
    <Count text={'12'} color={'stable-300'} />
    <Count text={'12'} color={'stable-500'} />
    <Count text={'12'} color={'stable-700'} />
    <Count text={'12'} color={'stable-900'} />
    <Count text={'12'} color={'calm'} />
    <Count text={'12'} color={'positive-300'} />
    <Count text={'12'} color={'positive'} />
    <Count text={'12'} color={'energized'} />
    <Count text={'12'} color={'assertive'} />
    <Count text={'12'} color={'royal'} />
    <Count text={'12'} color={'dark'} />
  </>
);
export const Sizes = () => (
  <>
    <Count text={'12'} size={'s'} />
    <Count text={'12'} size={'m'} />
    <Count text={'12'} size={'l'} />
  </>
);
export const Variants = () => (
  <>
    <Count text={'12'} size={'s'} />
    <Count text={'12'} size={'s'} round={true} />
    <Count text={'12'} size={'m'} />
    <Count text={'12'} size={'m'} round={true} />
    <Count text={'12'} size={'l'} />
    <Count text={'12'} size={'l'} round={true} />
  </>
);

import React from 'react';
import PropTypes from 'prop-types';
import styles from './select.module.scss';
import classNames from 'classnames/bind';
import ReactSelect from 'react-select';
const Rails = typeof window !== 'undefined' ? require('@rails/ujs') : null;

const cx = classNames.bind(styles);

const Select = (props) => {
  const classes = cx({
    select: true,
  });
  return (
    <ReactSelect
      instanceId={props.id}
      id={props.id}
      className={classes}
      classNamePrefix={'select'}
      type={props.type}
      name={props.name}
      isDisabled={props.disabled}
      isClearable={props.isClearable}
      placeholder={props.placeholder}
      options={props.options}
      isMulti={props.multi}
      defaultValue={props.options.filter((prop) => prop.checked)}
      form={props.attributes ? props.attributes.form : ''}
      closeMenuOnSelect={!props.attributes || !props.attributes.form}
      menuPlacement={'auto'}
      onChange={(action) => {
        props.input && props.input();
        if (props.data && Object.entries(props.data).length > 0) {
          const elem = document.querySelector(`[name="${action.name}"]`);
          elem.setAttribute('value', action.value);
          Object.entries(props.data).forEach((entry) => {
            elem.setAttribute(`data-${entry[0]}`, entry[1]);
          });
          Rails.fire(elem, 'change');
        }
      }}
    />
  );
};

Select.propTypes = {
  /** The input type */
  type: PropTypes.oneOf(['select']).isRequired,
  /** The name of the input */
  name: PropTypes.string.isRequired,
  /** The value of the input */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The placeholder text */
  placeholder: PropTypes.string,
  /** Whether the input is disabled */
  disabled: PropTypes.bool,
  /** A unique id for the input */
  id: PropTypes.string.isRequired,
};

Select.defaultProps = {
  type: 'select',
  name: 'select',
  placeholder: 'Select...'
};

//Needed for Storybook
Select.displayName = 'Select';

export default Select;

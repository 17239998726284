// This module is used to make railsy UJS form magic work with React. I've just
// exctracted this. Please ask bas@ivaldi.nl for a better explanation should
// you need one.
//
// For a quick tour check the folowing files:
//
// app/controllers/insured/cases/step_3_controller.rb
// app/views/insured/cases/step3/show.html.erb
// app/views/insured/cases/step3/update.js

export default function uShiftForm() {
  window.replaceContent = function (content, target) {
    let elem = document.querySelector(target);

    var wrapper = document.createElement('div');
    wrapper.innerHTML = content;

    let divToReplace = wrapper.querySelector(target);
    if (divToReplace == null) {
      divToReplace = wrapper;
    }

    elem.innerHTML = divToReplace.innerHTML;
    ReactRailsUJS.mountComponents(elem);
  };

  document.addEventListener('ajax:before', (event) => {
    if (!event.target.hasAttribute('data-ujs')) {
      return;
    }
    const formData = new FormData(event.target.form);
    let data = [...formData.entries()];
    data.push(['onChange', true]);
    const asString = data
      .map((x) => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
      .join('&');

    event.target.setAttribute('data-params', asString);
  });
};
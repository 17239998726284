import React from 'react';
import LabelChart from './label';
import { Spacings, Colors as ColorNames } from '@components/variables';

export default {
  title: 'Remark/chart/LabelChart',
  component: LabelChart,
};

const Template = (args) => <LabelChart {...args} />;

export const Default = Template.bind({});

Default.args = {
  color: 'positive',
  label: {
    text: 'Initiated',
  },
  value: {
    text: '56',
  },
};

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      return <LabelChart key={index} {...Default.args} color={color} />;
    })}
  </>
);

export const Sizes = () => (
  <>
    {Spacings.map((space, index) => {
      return <LabelChart key={index} {...Default.args} size={space} />;
    })}
  </>
);

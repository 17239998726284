import React from 'react';
import Title from './title';
import { Colors as ColorNames } from '@components/variables';

export default {
  title: 'Remark/text/Title',
  component: Title,
};

const Template = (args) => <Title {...args} />;

export const Default = Template.bind({});
Default.args = {};

export const Alignment = () => (
  <>
    <Title text={'Left aligned'} />
    <Title text={'Center aligned'} align={'center'} />
    <Title text={'Right aligned'} align={'right'} />
  </>
);

export const Sizes = () => (
  <>
    <Title text={'H1: Lorem ipsum dolor sit amet.'} size={'h1'} />
    <Title text={'H2: Lorem ipsum dolor sit amet.'} size={'h2'} />
    <Title text={'H3: Lorem ipsum dolor sit amet.'} size={'h3'} />
    <Title text={'H4: Lorem ipsum dolor sit amet.'} size={'h4'} />
    <Title text={'H5: Lorem ipsum dolor sit amet.'} size={'h5'} />
    <Title text={'H6: Lorem ipsum dolor sit amet.'} size={'h6'} />
    <Title text={'p: Lorem ipsum dolor sit amet.'} size={'body'} />
    <Title text={'label: Lorem ipsum dolor sit amet.'} size={'label'} />
  </>
);

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      return (
        <Title
          key={index}
          text={'H2: Lorem ipsum dolor sit amet.'}
          size={'h2'}
          color={color}
          margin={[20, 0]}
        />
      );
    })}
  </>
);

export const Inline = () => (
  <>
    <Title text={'Lorem ipsum dolor sit amet.'} inline={true} size={'h3'} />
    <Title
      text={' Lorem ipsum dolor sit amet. '}
      inline={true}
      color={'assertive'}
      size={'h2'}
    />
    <Title text={'Lorem ipsum dolor sit amet.'} inline={true} size={'h3'} />
  </>
);

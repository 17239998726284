import React from 'react';
import Tooltip from './tooltip';
import { Divider, ActionSheet } from '@components/ui';
import { Title } from '@components/text';

export default {
  title: 'Remark/ui/Tooltip',
  component: Tooltip,
  parameters: {
    componentSubtitle:
      'Uses React-Tippy (https://github.com/tvkhoa/react-tippy) under the hood',
  },
};

const Template = (args) => (
  <>
    <Divider height={50} color={'transparent'} />
    <Tooltip {...args}>
      <Title inline={true} text={'Click me to trigger'} />
    </Tooltip>
    <Divider height={50} color={'transparent'} />
  </>
);

export const Default = Template.bind({});
Default.args = {
  content: 'I am a tooltip',
  simple: true,
};

export const Variants = () => (
  <>
    {[
      'top',
      'top-start',
      'top-end',
      'bottom',
      'bottom-start',
      'bottom-end',
      'left',
      'left-start',
      'left-end',
      'right',
      'right-start',
      'right-end',
    ].map((pos, index) => {
      return (
        <div style={{ textAlign: 'center' }} key={index}>
          <Divider height={50} color={'transparent'} />
          <Tooltip
            {...Default.args}
            position={pos}
            content={`I am a tooltip with position: ${pos}`}
          >
            <Title inline={true} text={pos} />
          </Tooltip>
        </div>
      );
    })}
  </>
);

export const Triggers = () => (
  <>
    {['mouseenter', 'click'].map((trigger, index) => {
      return (
        <div style={{ textAlign: 'center' }} key={index}>
          <Divider height={50} color={'transparent'} />
          <Tooltip {...Default.args} trigger={trigger}>
            <Title inline={true} text={`${trigger} me to trigger`} />
          </Tooltip>
        </div>
      );
    })}
  </>
);
const Actions = [
  {
    icon: {
      icon: 'warning-square',
      color: 'positive',
    },
    text: 'Change password',
    action: '#',
  },
  {
    icon: {
      icon: 'warning-square',
      color: 'assertive',
    },
    text: 'Logout',
    action: '#',
  },
  {
    icon: {
      icon: 'warning-square',
      color: 'energized',
    },
    text: 'Remove account',
    action: '#',
  },
];
export const Simple_Vs_Complex = () => (
  <>
    <div style={{ textAlign: 'center' }}>
      <Divider height={50} color={'transparent'} />
      <Tooltip {...Default.args}>
        <Title inline={true} text={'Simple'} />
      </Tooltip>
    </div>
    <div style={{ textAlign: 'center' }}>
      <Divider height={50} color={'transparent'} />
      <Tooltip
        {...Default.args}
        content={<ActionSheet actions={Actions} />}
        simple={false}
      >
        <Title inline={true} text={`Complex (Another component)`} />
      </Tooltip>
    </div>
  </>
);

import React from 'react';
import PropTypes from 'prop-types';
import styles from './title.module.scss';
import classNames from 'classnames/bind';
import { Colors, Alignments } from '@components/variables';
const cx = classNames.bind(styles);

const Title = (props) => {
  let classes = cx(
    {
      title: true,
      emphasis: props.emphasis,
    },
    props.color,
    props.align,
    props.size,
    props.className
  );
  const CustomTag = props.inline
    ? 'span'
    : ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(props.size.toLowerCase())
      ? props.size.toLowerCase()
      : 'p';
  return <CustomTag className={classes}>{props.text}</CustomTag>;
};

Title.propTypes = {
  /** The text of the title */
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** The color of the title */
  color: PropTypes.oneOf(Colors),
  /** The size of the title, will be rendered as element (p in case of body) */
  size: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'body',
    'label',
    'small',
  ]),
  /** The alignment of the title */
  align: PropTypes.oneOf(Alignments),
  /** Whether the title should be rendered inline (inside a span) */
  inline: PropTypes.bool,
};

Title.defaultProps = {
  size: 'h2',
  color: 'dark',
  text: 'Missing text prop',
  align: 'left',
};

//Needed for Storybook
Title.displayName = 'Title';

export default Title;

import * as React from 'react';

function SvgCirclesTopRight(props) {
  return (
    <svg width={506} height={257} fill='none' {...props}>
      <g opacity={0.08}>
        <mask
          id='circles-top-right_svg__a'
          maskUnits='userSpaceOnUse'
          x={0}
          y={0}
          width={507}
          height={257}
        >
          <path
            d='M164.631.995s-145.379 25.159-162 97c-20.127 86.992 79.734 156.052 169 154 78.595-1.806 78.511-121.54 157-126 86.17-4.897 178 131 178 131v-256h-342z'
            fill='#fff'
          />
        </mask>
        <g
          clipPath='url(#circles-top-right_svg__clip0)'
          fill='#E8A32E'
          mask='url(#circles-top-right_svg__a)'
        >
          <g clipPath='url(#circles-top-right_svg__clip1)'>
            <circle cx={6.5} cy={6.501} r={4.5} />
            <circle cx={30.5} cy={6.501} r={4.5} />
            <circle cx={54.5} cy={6.501} r={4.5} />
            <circle cx={78.5} cy={6.501} r={4.5} />
            <circle cx={102.5} cy={6.501} r={4.5} />
            <circle cx={126.5} cy={6.501} r={4.5} />
            <circle cx={150.5} cy={6.501} r={4.5} />
            <circle cx={174.5} cy={6.501} r={4.5} />
            <circle cx={198.5} cy={6.501} r={4.5} />
            <circle cx={222.5} cy={6.501} r={4.5} />
            <circle cx={246.5} cy={6.501} r={4.5} />
            <circle cx={270.5} cy={6.501} r={4.5} />
            <circle cx={294.5} cy={6.501} r={4.5} />
            <circle cx={318.5} cy={6.501} r={4.5} />
            <circle cx={342.5} cy={6.501} r={4.5} />
            <circle cx={366.5} cy={6.501} r={4.5} />
            <circle cx={390.5} cy={6.501} r={4.5} />
            <circle cx={414.5} cy={6.501} r={4.5} />
            <circle cx={438.5} cy={6.501} r={4.5} />
            <circle cx={462.5} cy={6.501} r={4.5} />
            <circle cx={486.5} cy={6.501} r={4.5} />
          </g>
          <g clipPath='url(#circles-top-right_svg__clip2)'>
            <circle cx={6.5} cy={30.501} r={4.5} />
            <circle cx={30.5} cy={30.501} r={4.5} />
            <circle cx={54.5} cy={30.501} r={4.5} />
            <circle cx={78.5} cy={30.501} r={4.5} />
            <circle cx={102.5} cy={30.501} r={4.5} />
            <circle cx={126.5} cy={30.501} r={4.5} />
            <circle cx={150.5} cy={30.501} r={4.5} />
            <circle cx={174.5} cy={30.501} r={4.5} />
            <circle cx={198.5} cy={30.501} r={4.5} />
            <circle cx={222.5} cy={30.501} r={4.5} />
            <circle cx={246.5} cy={30.501} r={4.5} />
            <circle cx={270.5} cy={30.501} r={4.5} />
            <circle cx={294.5} cy={30.501} r={4.5} />
            <circle cx={318.5} cy={30.501} r={4.5} />
            <circle cx={342.5} cy={30.501} r={4.5} />
            <circle cx={366.5} cy={30.501} r={4.5} />
            <circle cx={390.5} cy={30.501} r={4.5} />
            <circle cx={414.5} cy={30.501} r={4.5} />
            <circle cx={438.5} cy={30.501} r={4.5} />
            <circle cx={462.5} cy={30.501} r={4.5} />
            <circle cx={486.5} cy={30.501} r={4.5} />
          </g>
          <g clipPath='url(#circles-top-right_svg__clip3)'>
            <circle cx={6.5} cy={54.501} r={4.5} />
            <circle cx={30.5} cy={54.501} r={4.5} />
            <circle cx={54.5} cy={54.501} r={4.5} />
            <circle cx={78.5} cy={54.501} r={4.5} />
            <circle cx={102.5} cy={54.501} r={4.5} />
            <circle cx={126.5} cy={54.501} r={4.5} />
            <circle cx={150.5} cy={54.501} r={4.5} />
            <circle cx={174.5} cy={54.501} r={4.5} />
            <circle cx={198.5} cy={54.501} r={4.5} />
            <circle cx={222.5} cy={54.501} r={4.5} />
            <circle cx={246.5} cy={54.501} r={4.5} />
            <circle cx={270.5} cy={54.501} r={4.5} />
            <circle cx={294.5} cy={54.501} r={4.5} />
            <circle cx={318.5} cy={54.501} r={4.5} />
            <circle cx={342.5} cy={54.501} r={4.5} />
            <circle cx={366.5} cy={54.501} r={4.5} />
            <circle cx={390.5} cy={54.501} r={4.5} />
            <circle cx={414.5} cy={54.501} r={4.5} />
            <circle cx={438.5} cy={54.501} r={4.5} />
            <circle cx={462.5} cy={54.501} r={4.5} />
            <circle cx={486.5} cy={54.501} r={4.5} />
          </g>
          <g clipPath='url(#circles-top-right_svg__clip4)'>
            <circle cx={6.5} cy={78.501} r={4.5} />
            <circle cx={30.5} cy={78.501} r={4.5} />
            <circle cx={54.5} cy={78.501} r={4.5} />
            <circle cx={78.5} cy={78.501} r={4.5} />
            <circle cx={102.5} cy={78.501} r={4.5} />
            <circle cx={126.5} cy={78.501} r={4.5} />
            <circle cx={150.5} cy={78.501} r={4.5} />
            <circle cx={174.5} cy={78.501} r={4.5} />
            <circle cx={198.5} cy={78.501} r={4.5} />
            <circle cx={222.5} cy={78.501} r={4.5} />
            <circle cx={246.5} cy={78.501} r={4.5} />
            <circle cx={270.5} cy={78.501} r={4.5} />
            <circle cx={294.5} cy={78.501} r={4.5} />
            <circle cx={318.5} cy={78.501} r={4.5} />
            <circle cx={342.5} cy={78.501} r={4.5} />
            <circle cx={366.5} cy={78.501} r={4.5} />
            <circle cx={390.5} cy={78.501} r={4.5} />
            <circle cx={414.5} cy={78.501} r={4.5} />
            <circle cx={438.5} cy={78.501} r={4.5} />
            <circle cx={462.5} cy={78.501} r={4.5} />
            <circle cx={486.5} cy={78.501} r={4.5} />
          </g>
          <g clipPath='url(#circles-top-right_svg__clip5)'>
            <circle cx={6.5} cy={102.501} r={4.5} />
            <circle cx={30.5} cy={102.501} r={4.5} />
            <circle cx={54.5} cy={102.501} r={4.5} />
            <circle cx={78.5} cy={102.501} r={4.5} />
            <circle cx={102.5} cy={102.501} r={4.5} />
            <circle cx={126.5} cy={102.501} r={4.5} />
            <circle cx={150.5} cy={102.501} r={4.5} />
            <circle cx={174.5} cy={102.501} r={4.5} />
            <circle cx={198.5} cy={102.501} r={4.5} />
            <circle cx={222.5} cy={102.501} r={4.5} />
            <circle cx={246.5} cy={102.501} r={4.5} />
            <circle cx={270.5} cy={102.501} r={4.5} />
            <circle cx={294.5} cy={102.501} r={4.5} />
            <circle cx={318.5} cy={102.501} r={4.5} />
            <circle cx={342.5} cy={102.501} r={4.5} />
            <circle cx={366.5} cy={102.501} r={4.5} />
            <circle cx={390.5} cy={102.501} r={4.5} />
            <circle cx={414.5} cy={102.501} r={4.5} />
            <circle cx={438.5} cy={102.501} r={4.5} />
            <circle cx={462.5} cy={102.501} r={4.5} />
            <circle cx={486.5} cy={102.501} r={4.5} />
          </g>
          <g clipPath='url(#circles-top-right_svg__clip6)'>
            <circle cx={6.5} cy={126.501} r={4.5} />
            <circle cx={30.5} cy={126.501} r={4.5} />
            <circle cx={54.5} cy={126.501} r={4.5} />
            <circle cx={78.5} cy={126.501} r={4.5} />
            <circle cx={102.5} cy={126.501} r={4.5} />
            <circle cx={126.5} cy={126.501} r={4.5} />
            <circle cx={150.5} cy={126.501} r={4.5} />
            <circle cx={174.5} cy={126.501} r={4.5} />
            <circle cx={198.5} cy={126.501} r={4.5} />
            <circle cx={222.5} cy={126.501} r={4.5} />
            <circle cx={246.5} cy={126.501} r={4.5} />
            <circle cx={270.5} cy={126.501} r={4.5} />
            <circle cx={294.5} cy={126.501} r={4.5} />
            <circle cx={318.5} cy={126.501} r={4.5} />
            <circle cx={342.5} cy={126.501} r={4.5} />
            <circle cx={366.5} cy={126.501} r={4.5} />
            <circle cx={390.5} cy={126.501} r={4.5} />
            <circle cx={414.5} cy={126.501} r={4.5} />
            <circle cx={438.5} cy={126.501} r={4.5} />
            <circle cx={462.5} cy={126.501} r={4.5} />
            <circle cx={486.5} cy={126.501} r={4.5} />
          </g>
          <g clipPath='url(#circles-top-right_svg__clip7)'>
            <circle cx={6.5} cy={150.501} r={4.5} />
            <circle cx={30.5} cy={150.501} r={4.5} />
            <circle cx={54.5} cy={150.501} r={4.5} />
            <circle cx={78.5} cy={150.501} r={4.5} />
            <circle cx={102.5} cy={150.501} r={4.5} />
            <circle cx={126.5} cy={150.501} r={4.5} />
            <circle cx={150.5} cy={150.501} r={4.5} />
            <circle cx={174.5} cy={150.501} r={4.5} />
            <circle cx={198.5} cy={150.501} r={4.5} />
            <circle cx={222.5} cy={150.501} r={4.5} />
            <circle cx={246.5} cy={150.501} r={4.5} />
            <circle cx={270.5} cy={150.501} r={4.5} />
            <circle cx={294.5} cy={150.501} r={4.5} />
            <circle cx={318.5} cy={150.501} r={4.5} />
            <circle cx={342.5} cy={150.501} r={4.5} />
            <circle cx={366.5} cy={150.501} r={4.5} />
            <circle cx={390.5} cy={150.501} r={4.5} />
            <circle cx={414.5} cy={150.501} r={4.5} />
            <circle cx={438.5} cy={150.501} r={4.5} />
            <circle cx={462.5} cy={150.501} r={4.5} />
            <circle cx={486.5} cy={150.501} r={4.5} />
          </g>
          <g clipPath='url(#circles-top-right_svg__clip8)'>
            <circle cx={6.5} cy={174.501} r={4.5} />
            <circle cx={30.5} cy={174.501} r={4.5} />
            <circle cx={54.5} cy={174.501} r={4.5} />
            <circle cx={78.5} cy={174.501} r={4.5} />
            <circle cx={102.5} cy={174.501} r={4.5} />
            <circle cx={126.5} cy={174.501} r={4.5} />
            <circle cx={150.5} cy={174.501} r={4.5} />
            <circle cx={174.5} cy={174.501} r={4.5} />
            <circle cx={198.5} cy={174.501} r={4.5} />
            <circle cx={222.5} cy={174.501} r={4.5} />
            <circle cx={246.5} cy={174.501} r={4.5} />
            <circle cx={270.5} cy={174.501} r={4.5} />
            <circle cx={294.5} cy={174.501} r={4.5} />
            <circle cx={318.5} cy={174.501} r={4.5} />
            <circle cx={342.5} cy={174.501} r={4.5} />
            <circle cx={366.5} cy={174.501} r={4.5} />
            <circle cx={390.5} cy={174.501} r={4.5} />
            <circle cx={414.5} cy={174.501} r={4.5} />
            <circle cx={438.5} cy={174.501} r={4.5} />
            <circle cx={462.5} cy={174.501} r={4.5} />
            <circle cx={486.5} cy={174.501} r={4.5} />
          </g>
          <g clipPath='url(#circles-top-right_svg__clip9)'>
            <circle cx={6.5} cy={198.501} r={4.5} />
            <circle cx={30.5} cy={198.501} r={4.5} />
            <circle cx={54.5} cy={198.501} r={4.5} />
            <circle cx={78.5} cy={198.501} r={4.5} />
            <circle cx={102.5} cy={198.501} r={4.5} />
            <circle cx={126.5} cy={198.501} r={4.5} />
            <circle cx={150.5} cy={198.501} r={4.5} />
            <circle cx={174.5} cy={198.501} r={4.5} />
            <circle cx={198.5} cy={198.501} r={4.5} />
            <circle cx={222.5} cy={198.501} r={4.5} />
            <circle cx={246.5} cy={198.501} r={4.5} />
            <circle cx={270.5} cy={198.501} r={4.5} />
            <circle cx={294.5} cy={198.501} r={4.5} />
            <circle cx={318.5} cy={198.501} r={4.5} />
            <circle cx={342.5} cy={198.501} r={4.5} />
            <circle cx={366.5} cy={198.501} r={4.5} />
            <circle cx={390.5} cy={198.501} r={4.5} />
            <circle cx={414.5} cy={198.501} r={4.5} />
            <circle cx={438.5} cy={198.501} r={4.5} />
            <circle cx={462.5} cy={198.501} r={4.5} />
            <circle cx={486.5} cy={198.501} r={4.5} />
          </g>
          <g clipPath='url(#circles-top-right_svg__clip10)'>
            <circle cx={6.5} cy={222.501} r={4.5} />
            <circle cx={30.5} cy={222.501} r={4.5} />
            <circle cx={54.5} cy={222.501} r={4.5} />
            <circle cx={78.5} cy={222.501} r={4.5} />
            <circle cx={102.5} cy={222.501} r={4.5} />
            <circle cx={126.5} cy={222.501} r={4.5} />
            <circle cx={150.5} cy={222.501} r={4.5} />
            <circle cx={174.5} cy={222.501} r={4.5} />
            <circle cx={198.5} cy={222.501} r={4.5} />
            <circle cx={222.5} cy={222.501} r={4.5} />
            <circle cx={246.5} cy={222.501} r={4.5} />
            <circle cx={270.5} cy={222.501} r={4.5} />
            <circle cx={294.5} cy={222.501} r={4.5} />
            <circle cx={318.5} cy={222.501} r={4.5} />
            <circle cx={342.5} cy={222.501} r={4.5} />
            <circle cx={366.5} cy={222.501} r={4.5} />
            <circle cx={390.5} cy={222.501} r={4.5} />
            <circle cx={414.5} cy={222.501} r={4.5} />
            <circle cx={438.5} cy={222.501} r={4.5} />
            <circle cx={462.5} cy={222.501} r={4.5} />
            <circle cx={486.5} cy={222.501} r={4.5} />
          </g>
          <g clipPath='url(#circles-top-right_svg__clip11)'>
            <circle cx={6.5} cy={246.501} r={4.5} />
            <circle cx={30.5} cy={246.501} r={4.5} />
            <circle cx={54.5} cy={246.501} r={4.5} />
            <circle cx={78.5} cy={246.501} r={4.5} />
            <circle cx={102.5} cy={246.501} r={4.5} />
            <circle cx={126.5} cy={246.501} r={4.5} />
            <circle cx={150.5} cy={246.501} r={4.5} />
            <circle cx={174.5} cy={246.501} r={4.5} />
            <circle cx={198.5} cy={246.501} r={4.5} />
            <circle cx={222.5} cy={246.501} r={4.5} />
            <circle cx={246.5} cy={246.501} r={4.5} />
            <circle cx={270.5} cy={246.501} r={4.5} />
            <circle cx={294.5} cy={246.501} r={4.5} />
            <circle cx={318.5} cy={246.501} r={4.5} />
            <circle cx={342.5} cy={246.501} r={4.5} />
            <circle cx={366.5} cy={246.501} r={4.5} />
            <circle cx={390.5} cy={246.501} r={4.5} />
            <circle cx={414.5} cy={246.501} r={4.5} />
            <circle cx={438.5} cy={246.501} r={4.5} />
            <circle cx={462.5} cy={246.501} r={4.5} />
            <circle cx={486.5} cy={246.501} r={4.5} />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id='circles-top-right_svg__clip6'>
          <path
            fill='#fff'
            transform='translate(-934 122.001)'
            d='M0 0h1449v9H0z'
          />
        </clipPath>
        <clipPath id='circles-top-right_svg__clip0'>
          <path
            fill='#fff'
            transform='translate(-934 -70)'
            d='M0 0h1449v1041H0z'
          />
        </clipPath>
        <clipPath id='circles-top-right_svg__clip2'>
          <path fill='#fff' transform='translate(-934 26)' d='M0 0h1449v9H0z' />
        </clipPath>
        <clipPath id='circles-top-right_svg__clip3'>
          <path fill='#fff' transform='translate(-934 50)' d='M0 0h1449v9H0z' />
        </clipPath>
        <clipPath id='circles-top-right_svg__clip4'>
          <path fill='#fff' transform='translate(-934 74)' d='M0 0h1449v9H0z' />
        </clipPath>
        <clipPath id='circles-top-right_svg__clip5'>
          <path fill='#fff' transform='translate(-934 98)' d='M0 0h1449v9H0z' />
        </clipPath>
        <clipPath id='circles-top-right_svg__clip1'>
          <path fill='#fff' transform='translate(-934 2)' d='M0 0h1449v9H0z' />
        </clipPath>
        <clipPath id='circles-top-right_svg__clip7'>
          <path
            fill='#fff'
            transform='translate(-934 146.001)'
            d='M0 0h1449v9H0z'
          />
        </clipPath>
        <clipPath id='circles-top-right_svg__clip8'>
          <path
            fill='#fff'
            transform='translate(-934 170.001)'
            d='M0 0h1449v9H0z'
          />
        </clipPath>
        <clipPath id='circles-top-right_svg__clip9'>
          <path
            fill='#fff'
            transform='translate(-934 194.001)'
            d='M0 0h1449v9H0z'
          />
        </clipPath>
        <clipPath id='circles-top-right_svg__clip10'>
          <path
            fill='#fff'
            transform='translate(-934 218.001)'
            d='M0 0h1449v9H0z'
          />
        </clipPath>
        <clipPath id='circles-top-right_svg__clip11'>
          <path
            fill='#fff'
            transform='translate(-934 242.001)'
            d='M0 0h1449v9H0z'
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgCirclesTopRight;

import React from 'react';
import * as Variables from './index';
import { Title, Content } from '../text';
import { Divider } from '../ui';
export default {
  title: 'Remark/ui/Variables',
};

const ColorOutput = () => {
  return (
    <div style={{ display: 'flex', overflow: 'auto', maxWidth: '100%' }}>
      {Variables.Colors.map((color, index) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }} key={index}>
            <div
              className={color}
              style={{
                width: 100,
                height: 100,
                backgroundColor: 'currentColor',
                border: color == 'light' ? '1px solid #ccc' : '1px solid #fff',
                marginRight: index != Variables.Colors.length - 1 ? 10 : 0,
              }}
            ></div>
            <Title text={color} size={'body'} />
            <Content
              text={getComputedStyle(document.documentElement).getPropertyValue(
                `--color-${color}`
              )}
              size={'xs'}
            />
          </div>
        );
      })}
    </div>
  );
};

const FontOutput = () => {
  return (
    <div>
      {Variables.Sizes.map((size, index) => {
        return (
          <div key={index}>
            <Content
              text={`Lorem ipsum dolor sit`}
              size={size}
              color={'dark'}
              className={`font-size-${size}`}
            />
            <Title text={size} size={'body'} />
            <Content
              text={getComputedStyle(document.documentElement).getPropertyValue(
                `--font-size-${size}`
              )}
              size={'xs'}
            />
            <Divider height={20} color={'light'} />
          </div>
        );
      })}
    </div>
  );
};

const RadiusOutput = () => {
  return (
    <div style={{ display: 'flex' }}>
      {Variables.Radius.map((radius, index) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }} key={index}>
            <div
              className={`light radius-${radius}`}
              style={{
                width: 100,
                height: 50,
                backgroundColor: 'currentColor',
                border: '1px solid #ccc',
                marginRight: index != Variables.Colors.length - 1 ? 10 : 0,
              }}
            ></div>
            <Title text={radius} size={'body'} />
            <Content
              text={getComputedStyle(document.documentElement).getPropertyValue(
                `--radius-${radius}`
              )}
              size={'xs'}
            />
          </div>
        );
      })}
    </div>
  );
};

const SpacingOutput = () => {
  return (
    <div style={{ display: 'flex' }}>
      {Variables.Spacings.map((spacing, index) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }} key={index}>
            <div
              style={{
                width: 100,
                height: 100,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #ccc',
                marginRight: index != Variables.Colors.length - 1 ? 10 : 0,
                padding: `var(--spacing-${spacing})`,
              }}
            >
              <div
                className={`stable-700`}
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'currentColor',
                }}
              ></div>
            </div>
            <Title text={spacing} size={'body'} />
            <Content
              text={getComputedStyle(document.documentElement).getPropertyValue(
                `--spacing-${spacing}`
              )}
              size={'xs'}
            />
          </div>
        );
      })}
    </div>
  );
};

const Template = (args) => {
  return (
    <div>
      <Title text={'Colors'} />
      <Divider color={'dark'} />
      <Divider color={'light'} height={10} />
      {ColorOutput()}
      <Divider color={'light'} height={50} />
      <Title text={'Fontsizes'} />
      <Divider color={'dark'} />
      {FontOutput()}
      <Divider color={'light'} height={50} />
      <Title text={'Radius'} />
      <Divider color={'dark'} />
      <Divider color={'light'} height={10} />
      {RadiusOutput()}
      <Divider color={'light'} height={50} />
      <Title text={'Spacings'} />
      <Divider color={'dark'} />
      <Divider color={'light'} height={10} />
      {SpacingOutput()}
    </div>
  );
};

export const Default = Template.bind({});

export const Colors = () => {
  return ColorOutput();
};

export const FontSizes = () => {
  return FontOutput();
};

export const Radius = () => {
  return RadiusOutput();
};

export const Spacings = () => {
  return SpacingOutput();
};

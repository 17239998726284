import React from 'react';
import PropTypes from 'prop-types';
import styles from './simple.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const Simple = (props) => {
  let classes = cx(
    {
      input: true,
    },
    props.className
  );

  return (
    <input
      id={props.id}
      className={classes}
      type={props.type}
      name={props.name}
      disabled={props.disabled}
      readOnly={props.readonly}
      placeholder={props.placeholder}
      defaultValue={props.value ? props.value : ''}
      onInput={props.input}
      {...props.attributes}
    />
  );
};

Simple.propTypes = {
  /** The input type */
  type: PropTypes.oneOf(['text', 'password', 'tel', 'email', 'url']).isRequired,
  /** The name of the input */
  name: PropTypes.string.isRequired,
  /** The value of the input */
  value: PropTypes.string,
  /** The placeholder text */
  placeholder: PropTypes.string,
  /** Whether the input is disabled */
  disabled: PropTypes.bool,
  /** Whether the input is readonly */
  readonly: PropTypes.bool,
  /** A unique id for the input */
  id: PropTypes.string.isRequired,
};

Simple.defaultProps = {
  type: 'text',
  name: 'simple',
};

//Needed for Storybook
Simple.displayName = 'Simple';

export default Simple;

import React from 'react';
import IconLeft from './icon-left';
import { Colors as ColorNames } from '@components/variables';

export default {
  title: 'Remark/button/IconLeft',
  component: IconLeft,
};

const Template = (args) => <IconLeft {...args} />;

export const Default = Template.bind({});
Default.args = {};

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      return (
        <IconLeft
          key={index}
          text={color.charAt(0).toUpperCase() + color.slice(1)}
          color={color}
        />
      );
    })}
  </>
);

export const Outline = () => (
  <>
    {ColorNames.map((color, index) => {
      return (
        <IconLeft
          key={index}
          text={color.charAt(0).toUpperCase() + color.slice(1)}
          color={color}
          outline={true}
        />
      );
    })}
  </>
);

export const Link = () => (
  <>
    {ColorNames.map((color, index) => {
      return (
        <IconLeft
          key={index}
          text={color.charAt(0).toUpperCase() + color.slice(1)}
          color={color}
          link={true}
        />
      );
    })}
  </>
);

export const Size = () => (
  <>
    <IconLeft text={'Small'} size={'s'} />
    <IconLeft text={'Medium'} size={'m'} />
    <IconLeft text={'Large'} size={'l'} />
  </>
);

export const Rounded = () => (
  <>
    <IconLeft text={'Rounded Small'} rounded={true} size={'s'} />
    <IconLeft text={'Rounded Medium'} rounded={true} size={'m'} />
    <IconLeft
      text={'Rounded Medium'}
      rounded={true}
      color={'assertive'}
      size={'m'}
    />
    <IconLeft
      text={'Rounded Medium'}
      rounded={true}
      color={'energized'}
      size={'m'}
    />
    <IconLeft text={'Rounded Large'} rounded={true} size={'l'} />
  </>
);

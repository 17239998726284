import React from 'react';
import Modal from './modal';

export default {
  title: 'Remark/modal/Modal',
  component: Modal,
};

const Template = (args) => <Modal {...args} />;

export const Default = Template.bind({});
Default.args = {
  trigger: {
    text: 'Open modal',
  },
  title: {
    text: 'Modal title',
  },
  details: [
    {
      row: true,
      label: {
        text: 'Returned to work',
      },
      value: {
        text: 'No',
      },
    },
    {
      row: true,
      label: {
        text: 'Expected return to work',
      },
      value: {
        text: '01-11-2020',
      },
    },
    {
      label: {
        text: 'Description cause of work leave',
      },
      value: {
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed lacinia mauris. Sed nec interdum.',
      },
    },
    {
      row: true,
      label: {
        text: 'Expected return to work',
      },
      value: {
        text: '01-11-2020',
      },
    },
  ],
  inverted: true,
};

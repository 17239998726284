// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from '@rails/ujs';
import WebFont from 'webfontloader';
import uShiftForm from 'lib/u_shift_form'
require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
// requires for remotipart
require('remotipart/jquery.iframe-transport.js');
require('remotipart/jquery.remotipart.js');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context('components', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

const fontStack = `${getComputedStyle(document.documentElement)
  .getPropertyValue('--font-stack')
  .trim()}:400,500,700`;
const fontStackAlt = `${getComputedStyle(document.documentElement)
  .getPropertyValue('--font-stack-alt')
  .trim()}:400,500,700`;

const fontFamilies =
  fontStack != fontStackAlt ? [fontStack, fontStackAlt] : [fontStack];

WebFont.load({
  google: {
    families: fontFamilies,
  },
});

function initialLoad() {
  Rails.refreshCSRFTokens();
}

window.launchModal = function (content) {
  if (document.querySelector('.portal-node')) {
    document.querySelector('.portal-node').remove();
  }

  var container = document.createElement('div');
  container.innerHTML = content;
  document.body.appendChild(container);
  ReactRailsUJS.mountComponents(container);
  container.remove();
};

document.addEventListener('turbolinks:load', initialLoad);
uShiftForm()
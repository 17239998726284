import React from 'react';
import Avatar from './avatar';
import { Colors as ColorNames } from '@components/variables';

export default {
  title: 'Remark/ui/Avatar',
  component: Avatar,
};

const Template = (args) => <Avatar {...args} />;

export const Default = Template.bind({});
Default.args = {
  name: 'Stefan',
};

export const AvatarColors = () => (
  <div style={{ display: 'flex' }}>
    {ColorNames.map((color, index) => {
      return <Avatar key={index} color={color} {...Default.args} />;
    })}
  </div>
);

export const AvatarImage = () => (
  <Avatar
    image={{
      src: 'https://picsum.photos/400',
    }}
  />
);

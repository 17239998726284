import React from 'react';
import PropTypes from 'prop-types';
import styles from './tab.module.scss';
import classNames from 'classnames/bind';
import { Button, IconLeft } from '@components/button';
import { Count } from '@components/badge';
import { Divider } from '@components/ui';
const cx = classNames.bind(styles);

const Tab = (props) => {
  const classes = cx(
    {
      tab: true,
      active: props.active,
    },
    props.className
  );
  const dividerColor = props.textColor
    ? props.textColor
    : ['stable-300', 'stable-500', 'stable-700'].includes(props.color)
    ? 'stable-900'
    : ['light', 'positive-300'].includes(props.color)
    ? 'positive'
    : ['transparent'].includes(props.color)
    ? 'stable-900'
    : 'light';

  if (props.count) {
    props.count.className = props.icon ? styles.iconCount : styles.count;
  }
  return (
    <div className={classes}>
      <Divider height={4} color={'transparent'} margin={[0, 0, 10, 0]} />
      <IconLeft column={true} {...props} className={styles.button} />
      <Divider
        height={4}
        color={props.active ? dividerColor : 'transparent'}
        margin={[10, 0, 0, 0]}
        style={{ opacity: 0.9 }}
      />
    </div>
  );
};

Tab.propTypes = {
  /** Enables the active state of the tab */
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** The icon before the title */
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  /** The notification dot in the title */
  count: PropTypes.shape(Count.propTypes),
  /** All props of the button/Button component */
  button: PropTypes.shape(Button.propTypes),
};

Tab.defaultProps = {
  active: false,
  color: 'positive',
  icon: 'empty',
};

//Needed for Storybook
Tab.displayName = 'Tab';

export default Tab;

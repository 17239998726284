import React from 'react';
import KpiCard from './kpi';
import { Divider } from '@components/ui';
import { Spacings, Colors as ColorNames } from '@components/variables';

export default {
  title: 'Remark/card/KpiCard',
  component: KpiCard,
};

const Template = (args) => <KpiCard {...args} />;

export const Default = Template.bind({});

Default.args = {
  action: 'https://ivaldi.nl',
  label: {
    text: 'Unassigned cases',
  },
  icon: {
    icon: 'question',
  },
  title: {
    text: '6',
  },
};

export const Variants = () => (
  <>
    <KpiCard
      label={{ text: 'Card with action' }}
      icon={{ icon: 'database-systems' }}
      title={{ text: '176' }}
      action={'cases'}
    />
    <Divider height={20} color={'transparent'} />
    <KpiCard
      label={{ text: 'Card without action' }}
      icon={{ icon: 'clock' }}
      title={{ text: '5.2 days' }}
    />
  </>
);

export const LabelColor = () => (
  <>
    <KpiCard
      label={{ text: 'Card with action', color: 'assertive' }}
      icon={{ icon: 'database-systems' }}
      title={{ text: '176' }}
      action={'cases'}
    />
  </>
);

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      return (
        <div key={index}>
          <KpiCard
            label={{ text: 'Card with action' }}
            icon={{ icon: 'database-systems' }}
            title={{ text: '176' }}
            action={'cases'}
            iconColor={color}
          />
          <Divider height={15} color={'transparent'} />
        </div>
      );
    })}
  </>
);

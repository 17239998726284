import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@components/button';
import { Icon } from '@components/ui';

const IconLeft = (props) => {
  return <Button {...props} />;
};

IconLeft.propTypes = {
  /** The icon in front of the text */
  icon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.shape(Icon.propTypes),
  ]).isRequired,
  /** All props of the button component */
  button: PropTypes.shape(Button.propTypes),
};

IconLeft.defaultProps = {
  icon: 'empty',
};

//Needed for Storybook
IconLeft.displayName = 'IconLeft';

export default IconLeft;

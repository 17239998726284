import React from 'react';
import BarChart from './bar';
import { Spacings, Colors as ColorNames } from '@components/variables';

export default {
  title: 'Remark/chart/BarChart',
  component: BarChart,
};

const Template = (args) => <BarChart {...args} />;

export const Default = Template.bind({});

Default.args = {
  colors: ['positive', 'balanced', 'assertive'],
  group: 'grouped',
  padding: 0.95,
  innerPadding: 0,
  margin: {
    top: 50,
    bottom: 50,
    left: 50,
    right: 50
  },
  axisBottom: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legendPosition: 'middle',
  },
  axisLeft: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legendPosition: 'middle',
  },
  data: [
    {
      label: 'Jan',
      values: [
        {
          label: 'Pending',
          value: 150,
        },
        {
          label: 'Approved',
          value: 120,
        },
        {
          label: 'Declined',
          value: 100,
        },
      ],
    },
    {
      label: 'Feb',
      values: [
        {
          label: 'Pending',
          value: 150,
        },
        {
          label: 'Approved',
          value: 120,
        },
        {
          label: 'Declined',
          value: 100,
        },
      ],
    },
  ],
};

export const StackedBarChart = () => {
  return (
    <BarChart
      colors={['positive', 'balanced', 'assertive']}
      group={'stacked'}
      padding={0.7}
      innerPadding={4}
      reverse={false}
      margin={{
        top: 50,
        bottom: 50,
        left: 50,
        right: 50
      }}
      data={[
        {
          label: 'Jan',
          values: [
            {
              label: 'Pending',
              value: 150,
            },
            {
              label: 'Approved',
              value: 120,
            },
            {
              label: 'Declined',
              value: 100,
            },
          ],
          colors: ['royal', 'energized', 'positive'],
        },
        {
          label: 'Feb',
          values: [
            {
              label: 'Pending',
              value: 150,
            },
            {
              label: 'Approved',
              value: 120,
            },
            {
              label: 'Declined',
              value: 100,
            },
          ],
        },
      ]}
    />
  );
};


export const Color_Overrides = () => {
  return (
    <BarChart
      {...Default.args}
      colors={['positive', 'balanced', 'assertive']}
      data={[
        {
          label: 'Jan',
          values: [
            {
              label: 'Pending',
              value: 150,
            },
            {
              label: 'Approved',
              value: 120,
            },
            {
              label: 'Declined',
              value: 100,
            },
          ],
          colors: ['royal', 'energized', 'positive'],
        },
        {
          label: 'Feb',
          values: [
            {
              label: 'Pending',
              value: 150,
            },
            {
              label: 'Approved',
              value: 120,
            },
            {
              label: 'Declined',
              value: 100,
            },
          ],
        },
      ]}
    />
  );
};

export const WithPercentage = () => {
  return (
    <BarChart
      {...Default.args}
      colors={['positive', 'balanced', 'assertive']}
      data={[
        {
          label: 'Jan',
          values: [
            {
              label: 'Pending',
              value: 150,
              percentage: 20
            },
            {
              label: 'Approved',
              value: 120,
              percentage: 10
            },
            {
              label: 'Declined',
              value: 100,
              percentage: 20
            },
          ],
          colors: ['royal', 'energized', 'positive'],
        }
      ]}
    />
  );
};


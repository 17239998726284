import React from 'react';
import Table from './table';

export default {
  title: 'Remark/table/Table',
  component: Table,
};

const Template = (args) => (
  <>
    <div id='hidden_div' className='u-hide'>
      <p>I'm in a dropdown</p>
    </div>
    <Table {...args} />
  </>
);

export const Default = Template.bind({});
Default.args = {
  selectable: true,
  head: [
    {
      action: '?q=x&y=z',
      text: 'Case no.',
    },
    {
      action: '?q=x&y=z',
      text: 'Name.',
      dropdown: 'hidden_div',
    },
    {
      action: '?q=x&y=z',
      text: 'Claim',
    },
    {
      action: '?q=x&y=z',
      text: 'Last update',
    },
    {
      action: '?q=x&y=z',
      text: 'Product',
    },
    {
      action: '?q=x&y=z',
      text: 'Status',
    },
    {},
  ],
  body: [
    {
      key: 'index_1',
      data: [
        {
          action: 'url',
          text: '3510396',
        },
        {
          text: 'Ben Smith',
        },
        {
          text: '14-07-2020',
        },
        {
          text: '08-08-2020',
        },
        {
          text: 'Work disability',
        },
        {
          text: 'Ready for review',
          color: 'energized',
        },
        {
          text: 'Assign CM',
          action: '#',
          icon: 'profile-round',
        },
      ],
    },
    {
      key: 'index_2',
      data: [
        {
          action: 'url',
          text: '3510396',
        },
        {
          text: 'Ben Smith',
        },
        {
          text: '14-07-2020',
        },
        {
          text: '08-08-2020',
        },
        {
          text: 'Work disability',
        },
        {
          text: 'Ready for review',
          color: 'energized',
        },
        {
          text: 'Assign CM',
          action: '#',
          icon: 'profile-round',
        },
      ],
    },
  ],
};

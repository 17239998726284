import React from 'react';
import Notifications from './notifications';

export default {
  title: 'Remark/layout/Notifications',
  component: Notifications,
};

const Template = (args) => <Notifications {...args} />;

export const Default = Template.bind({});
Default.args = {
  notifications: {
    count: {
      text: 2,
    },
    actions: [
      {
        text: 'Notification 1',
        action: '#',
      },
      {
        text: 'Notification 2',
        action: '#',
      },
    ],
  },
};

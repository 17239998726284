import React from 'react';
import PropTypes from 'prop-types';
import styles from './wrapper.module.scss';
import classNames from 'classnames/bind';
import { Title } from '@components/text';
import { Divider } from '@components/ui';
import { Element } from '@components/form';
import { Colors, Spacings } from '@components/variables';
const cx = classNames.bind(styles);

const Wrapper = (props) => {
  let classes = cx(
    {
      card: true,
      disabled: props.disabled,
      loading: props.loading,
      action: props.action,
    },
    props.color,
    props.size,
    props.className
  );

  const lineClass = cx(props.className, {
    [`line-${props.size}`]: true,
  });

  return props.action ? (
    <a href={props.action} className={classes}>
      {props.children}
    </a>
  ) : (
      <div className={classes}>
        {props.header && (
          <div className={styles.header}>
            {props.header.text && <Title {...props.header} />}
            <div className={styles.filters}>
              {props.header.filters &&
              props.header.filters.map((filter, index) => {
                return (
                  <Element
                    className={styles.filter}
                    key={index}
                    type={'select'}
                    {...filter}
                  />
                );
              })}
            </div>
          </div>
        )}
        {props.header &&  <Divider height={1} className={lineClass} />}
        {props.children}
      </div>
    );
};
Wrapper.propTypes = {
  /** The color variant of the card */
  color: PropTypes.oneOf(Colors).isRequired,
  /** To size of the card (spacing between card & content) */
  size: PropTypes.oneOf(['no-p', ...Spacings]).isRequired,
  header: PropTypes.any
};

Wrapper.defaultProps = {
  color: 'light',
  size: 'xl',
};

//Needed for Storybook
Wrapper.displayName = 'Wrapper';

export default Wrapper;

import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={20}
      height={21}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 10.5c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm3.758.673A5.983 5.983 0 0016 6.5a6 6 0 10-9.758 4.673C2.583 12.548 0 15.945 0 20.5h2c0-5 3.589-8 8-8s8 3 8 8h2c0-4.555-2.583-7.952-6.242-9.327z'
        fill='currentColor'
      />
    </svg>
  );
}

export default SvgComponent;


import React from 'react';
import ActionSheet from './action-sheet';
import Tooltip from '../tooltip/tooltip';

export default {
  title: 'Remark/ui/ActionSheet',
  component: ActionSheet,
};

const Template = (args) => <ActionSheet {...args} />;

export const Default = Template.bind({});
Default.args = {
  actions: [
    {
      icon: {
        icon: 'warning-square',
        color: 'positive',
      },
      text: 'Change password',
      action: '#',
    },
    {
      icon: {
        icon: 'warning-square',
        color: 'assertive',
      },
      text: 'Logout',
      action: '#',
    },
    {
      icon: {
        icon: 'warning-square',
        color: 'energized',
      },
      text: 'Remove account',
      action: '#',
    },
  ],
};

export const As_Tooltip = () => {
  return (
    <Tooltip
      actions={Default.args.actions}
      position={'right-start'}
      button={{ text: 'Click to show' }}
    />
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import styles from './timeline.module.scss';
import classNames from 'classnames/bind';
import { Colors, Spacings } from '@components/variables';
import { Detail, Content } from '@components/text';
import { Icon } from '@components/ui';
import { Button } from '../../button';
const cx = classNames.bind(styles);

const Timeline = (props) => {
  const classes = cx(
    {
      timeline: true,
    },
    props.className
  );

  return (
    <div>
      <ul className={classes}>
        {props.steps &&
          props.steps.map((step, index) => {
            step.label.emphasis = true;
            let color = 'positive';
            if (!step.status || step.status === 'open') {
              color = 'stable-900';
              step.label.color = color;
            }
            const status = cx(
              {
                detail: true,
                dots: index == props.steps.length - 1 && props.endless,
              },
              color,
              step.status
            );
            return (
              <li className={status} key={index}>
                <span className={styles.circle}>
                  {step.status == 'done' && (
                    <Icon
                      icon={'checkmark-small'}
                      color={step.label.color}
                      stroke={true}
                      width={8}
                      height={7}
                    />
                  )}
                </span>
                <Detail reverse={true} {...step} />
                {step.date && <Content size={'s'} {...step.date} />}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

Timeline.propTypes = {
  /** The background-color of the steps */
  color: PropTypes.oneOf(Colors).isRequired,
  /** The space around the steps */
  size: PropTypes.oneOf(Spacings),
  /** The actual steps */
  steps: PropTypes.arrayOf(PropTypes.shape(Detail.propTypes)),
  /** The status option per step in steps */
  status: PropTypes.oneOf(['open', 'active', 'done']),
  /** The button link */
  button: PropTypes.shape(Button.propTypes),
  /** Whether the last item in the timeline should show a dotted line */
  endless: PropTypes.bool,
  // /** The date */
  // date: PropTypes.shape(Content.propTypes),

};

Timeline.defaultProps = {
  color: 'stable-700',
  size: 'm',
};

//Needed for Storybook
Timeline.displayName = 'Timeline';

export default Timeline;

import React from 'react';
import IconTitle from './icon-title';
import { Colors as ColorNames } from '@components/variables';

export default {
  title: 'Remark/text/IconTitle',
  component: IconTitle,
};

const Template = (args) => <IconTitle {...args} />;

export const Default = Template.bind({});
Default.args = {
  icon: {
    icon: 'bell',
  },
  title: {
    text: 'Default title',
  },
};

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      return (
        <IconTitle
          key={index}
          color={color}
          icon={{
            icon: 'bell',
          }}
          title={{
            text: 'H2: Lorem ipsum dolor sit amet.',
            size: 'h2',
          }}
        />
      );
    })}
  </>
);

import React from 'react';
import Select from './select';
import { Divider, Snackbar } from '@components/ui';
import { Title } from '@components/text';

export default {
  title: 'Remark/form/Select',
  component: Select,
  parameters: {
    componentSubtitle:
      'Uses React-Select (https://github.com/JedWatson/react-select) under the hood',
  },
};

const Template = (args) => (
  <>
    <Snackbar
      title={{
        text:
          'This story is only for showing the select props. When using this element in Rails, make sure to use form.Element',
      }}
      type='warning'
    />
    <Divider height={20} color={'transparent'} />
    <Select {...args} />
    <Divider height={100} color={'transparent'} />
  </>
);

export const Default = Template.bind({});

Default.args = {
  id: 'select',
  options: [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ],
};

export const Variants = (args) => (
  <>
    <Title text={'Disabled'} size={'label'} />
    <Select {...Default.args} disabled={true} />
    <Divider height={20} color={'transparent'} />
    <Title text={'Multiple'} size={'label'} />
    <Select {...Default.args} multi={true} />
    <Divider height={100} color={'transparent'} />
  </>
);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './snackbar.module.scss';
import classNames from 'classnames/bind';
import { Count, Badge } from '@components/badge';
import { Button } from '@components/button';
import { Title, Content } from '@components/text';
import Wrapper from '@components/card/wrapper/wrapper';
import Divider from '../divider/divider';

const cx = classNames.bind(styles);

const Snackbar = (props) => {
  const [dismissed, setDismissed] = useState(false);
  let classes = cx(
    {
      snackbar: true,
      fixed: props.fixed,
      fadeOut: props.fadeOut,
    },
    props.className
  );

  const iconClass = cx({
    inline: props.inline,
  });

  const icon = props.icon
    ? props.icon
    : props.type == 'info'
    ? 'comment'
    : props.type == 'success'
    ? 'checkmark'
    : props.type == 'error'
    ? 'cross'
    : 'warning-square';
  const iconColor = props.icon
    ? 'dark'
    : props.type == 'info'
    ? 'positive'
    : props.type == 'success'
    ? 'balanced'
    : props.type == 'error'
    ? 'assertive'
    : 'energized';

  if (dismissed) {
    return null;
  }
  return (
    <Wrapper color={'light'} size={props.size || 'm'} className={classes}>
      {(props.icon || props.type != 'none') && (
        <Count
          size={props.inline ? 's' : 'l'}
          lighten={true}
          icon={icon}
          color={iconColor}
          round={!props.inline}
          className={iconClass}
        />
      )}
      {props.type != 'none' && <Divider width={15} color={'transparent'} />}
      <div>
        {props.title && (
          <Title size={'body'} emphasis={true} {...props.title} />
        )}
        {props.content && <Content {...props.content} />}
      </div>
      {(props.badge || props.action || props.dismissable) && (
        <div className={styles.actions}>
          <Divider width={15} color={'transparent'} />
          {props.badge && <Badge {...props.badge} />}
          {props.action && <Divider width={15} color={'transparent'} />}
          {props.action && <Button {...props.action} />}
          {props.dismissable && <Divider width={15} color={'transparent'} />}
          {props.dismissable && (
            <Button
              text={'Fermer'}
              color={'positive'}
              size={'m'}
              action={() => {
                setDismissed(true);
              }}
            />
          )}
        </div>
      )}
    </Wrapper>
  );
};

Snackbar.propTypes = {
  /** The type of the bar */
  type: PropTypes.oneOf(['none', 'info', 'success', 'error', 'warning']),
  /** The name of the user */
  title: PropTypes.shape(Title.propTypes),
  /** The name of the icon, shown before the title */
  icon: PropTypes.string,
  /** Whether the bar can be dismissed */
  dismissable: PropTypes.bool,
  /** Whether the bar is fixed in the view */
  fixed: PropTypes.bool,
  /** The badge */
  badge: PropTypes.shape({}),
  /** The custom action (button) in the snackbar */
  action: PropTypes.shape(Button.propTypes),
  /** The fade out animation (after 5 seconds) */
  fadeOut: PropTypes.bool,
};

Snackbar.defaultProps = {
  type: 'none',
};

//Needed for Storybook
Snackbar.displayName = 'Snackbar';

export default Snackbar;

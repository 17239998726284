import React from 'react';
import Logo from './logo';

export default {
  title: 'Remark/ui/Logo',
  component: Logo,
};

const Template = (args) => <Logo {...args} />;

export const Default = Template.bind({});
Default.args = {};

export const Variants = () => (
  <>
    <div style={{ padding: 20 }}>
      <Logo />
    </div>
    <div style={{ padding: 20 }}>
      <Logo inline={true} />
    </div>
    <div
      style={{ padding: 20, backgroundColor: 'rgba(var(--color-positive),1)' }}
    >
      <Logo color={'light'} />
    </div>
  </>
);

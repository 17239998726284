import React from 'react';
import Tab from './tab';
import { Divider } from '@components/ui';
import { Colors as ColorNames } from '@components/variables';

export default {
  title: 'Remark/button/Tab',
  component: Tab,
};

const Template = (args) => {
  return (
    <div
      style={{
        backgroundColor: 'rgba(var(--color-positive),1)',
        display: 'flex',
      }}
    >
      <Tab {...args} />
    </div>
  );
};

export const Default = Template.bind({});
Default.args = {};

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      const bgColor =
        color == 'positive-300'
          ? `rgba(var(--color-${color}), 0.15)`
          : `rgba(var(--color-${color}), 1)`;
      return (
        <div key={index}>
          <div style={{ backgroundColor: bgColor, display: 'flex' }}>
            <Tab
              active={true}
              text={color.charAt(0).toUpperCase() + color.slice(1)}
              color={color}
              icon={'bell'}
            />
            <Tab
              text={color.charAt(0).toUpperCase() + color.slice(1)}
              color={color}
              icon={'bell'}
            />
          </div>
          <Divider height={15} color={'light'} />
        </div>
      );
    })}
  </>
);

export const Count = () => (
  <>
    <div
      style={{
        backgroundColor: 'rgba(var(--color-positive), 1)',
        display: 'flex',
      }}
    >
      <Tab
        active={true}
        text={'Tab'}
        icon={'bell'}
        count={{
          text: '5',
          color: 'energized',
        }}
      />
      <Tab
        text={'Tab'}
        icon={'bell'}
        count={{
          text: '!',
        }}
      />
    </div>
    <Divider height={15} color={'light'} />
    <div
      style={{
        backgroundColor: 'rgba(var(--color-positive), 1)',
        display: 'flex',
      }}
    >
      <Tab
        active={true}
        text={'Tab'}
        icon={false}
        count={{
          text: '5',
          color: 'energized',
        }}
      />
      <Tab
        text={'Tab'}
        icon={false}
        count={{
          text: '!',
        }}
      />
    </div>
  </>
);

import React from 'react';
import PropTypes from 'prop-types';
import styles from './action-sheet.module.scss';
import classNames from 'classnames/bind';
import { Divider, Icon } from '@components/ui';
import { Count } from '@components/badge';
import { Title } from '@components/text';
import Wrapper from '../../card/wrapper/wrapper';
const cx = classNames.bind(styles);

const _dataToAttr = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [`data-${key}`, value])
  );
};

const ActionSheet = (props) => {
  let classes = cx({
    actionSheet: true,
  });
  return (
    <Wrapper size={'no-p'} color={'light'} className={classes}>
      {props.actions.map((action, index) => {
        const attributes = action.data ? _dataToAttr(action.data) : [];
        return (
          <div key={index} className={styles.actionWrapper}>
            <a href={action.url} className={styles.action} {...attributes}>
              {action.icon && (
                <Count
                  size={'l'}
                  lighten={true}
                  icon={action.icon.icon}
                  color={action.icon.color}
                  round={true}
                />
              )}
              {action.icon && <Divider width={15} color={'transparent'} />}
              {action.text && (
                <Title inline={true} size={'body'} text={action.text} />
              )}
            </a>
            {index < props.actions.length - 1 && <Divider height={1} />}
          </div>
        );
      })}
    </Wrapper>
  );
};

ActionSheet.propTypes = {
  /** The actions in the sheet */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.shape(Icon.propTypes),
      text: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};

ActionSheet.defaultProps = {};

//Needed for Storybook
ActionSheet.displayName = 'ActionSheet';

export default ActionSheet;

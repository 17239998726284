import React from 'react';
import Divider from './divider';
import { Colors as ColorNames } from '@components/variables';

export default {
  title: 'Remark/ui/Divider',
  component: Divider,
};

const Template = (args) => <Divider {...args} />;

export const Default = Template.bind({});
Default.args = {};

export const Variants = () => (
  <>
    <Divider height={5} margin={[20, 0]} />
    <Divider height={5} margin={[40, 0]} />
    <Divider height={5} margin={[60, 0]} />
    <Divider width={5} height={100} margin={[0, 20]} />
    <Divider width={5} height={100} margin={[0, 40]} />
    <Divider width={5} height={100} margin={[0, 60]} />
    <Divider height={5} margin={[20, 30, 40, 50]} />
  </>
);

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      return <Divider key={index} height={5} color={color} margin={[20, 0]} />;
    })}
  </>
);

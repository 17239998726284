import React from 'react';
import PropTypes from 'prop-types';
import styles from './detail.module.scss';
import classNames from 'classnames/bind';
import Title from '../title/title';
const cx = classNames.bind(styles);

const Detail = (props) => {
  let classes = cx(
    {
      detail: true,
      row: props.row,
    },
    props.className
  );
  const labelProps = {
    size: props.reverse ? 'body' : 'label',
    color: props.reverse ? 'dark' : 'stable-900',
    ...props.label,
  };
  const valueProps = {
    size: props.reverse ? 'label' : 'body',
    color: props.reverse ? 'stable-900' : 'dark',
    ...props.value,
  };

  if(props.disabled) {
    valueProps.color = 'stable-900';
  }
  return (
    <div className={classes}>
      {props.label && (
        <Title {...labelProps} className={props.reverse && styles.valueTop} />
      )}
      {props.value && (
        <Title
          {...valueProps}
          className={!props.reverse && styles.valueBottom}
        />
      )}
    </div>
  );
};

Detail.propTypes = {
  /** All the props of the title component */
  label: PropTypes.shape(Title.propTypes),
  /** All the props of the title component */
  value: PropTypes.shape(Title.propTypes),
  /** Label & value next to each other*/
  row: PropTypes.bool
};

Detail.defaultProps = {
  label: {},
  value: { text: '' },
  row: false,
};

//Needed for Storybook
Detail.displayName = 'Detail';

export default Detail;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './details.module.scss';
import { Badge } from '@components/badge';
import { Button } from '@components/button';
import { Divider } from '@components/ui';
import { Wrapper } from '@components/card';
import { Detail, Title, Content } from '@components/text';
import { Spacings, Colors } from '@components/variables';
import classNames from 'classnames/bind';
import { ContainerQuery } from 'react-container-query';
const cx = classNames.bind(styles);
const queries = {
  'small-card': {
    maxWidth: 350,
  },
  'medium-card': {
    maxWidth: 600,
  },
};

const DetailsCard = (props) => {
  const classes = cx(props.className, {
    verdict: props.comment,
    disabled: props.disabled,
  });
  const detailsClass = cx(props.color, props.size, 'details');
  const commentClass = cx(props.size, 'comment');
  const descriptionClass = cx(props.size, 'description');
  const badgeClass = cx({
    badge: true,
    [`badge-${props.size}`]: true,
  });

  if (props.button) {
    props.button.color = props.button.color || 'positive';
  }

  if (props.disabled === true) {
    props.title.color = 'stable-900';
    props.date.color = 'stable-900';
  }

  let color;

  if (!props.comment) {
    color = 'light';
  }

  if (!props.comment && !props.description) {
    color = 'stable-300';
  }

  const count = props.details.length;
  return (
    <ContainerQuery query={queries}>
      {(params) => (
        <Wrapper
          size={'no-p'}
          color={color}
          className={`${classes} ${cx(params)}`}
        >
          <div className={detailsClass}>
            {(props.title || props.badge) && (
              <div className={styles.heading}>
                <div>
                  {props.title && <Title size={'h4'} {...props.title} />}
                  {props.date && <Content size={'s'} {...props.date} />}
                </div>
                {props.badge && (
                  <Badge
                    className={badgeClass}
                    status={true}
                    size={'m'}
                    {...props.badge}
                  />
                )}
              </div>
            )}
            {props.details &&
              props.details.map((detail, index) => {
                return (
                  <div className={styles.detail} key={index}>
                    <Detail disabled={props.disabled} {...detail} key={index} />
                    {
                      /*Adds spacing if there is more than 1 row & the index is smaller or equal to 5
                      (specific amount that is visible in the design: max 3 rows) */
                      (!params ||
                        (!params['medium-card'] && !params['small-card'])) &&
                        count > 3 &&
                        index <= count - 4 && (
                          <Divider height={20} color={'transparent'} />
                        )
                    }
                    {params &&
                      params['medium-card'] &&
                      !params['small-card'] &&
                      count > 2 &&
                      index <= count - 3 && (
                        <Divider height={20} color={'transparent'} />
                      )}
                    {params &&
                      params['small-card'] &&
                      count > 1 &&
                      index <= count - 2 && (
                        <Divider height={20} color={'transparent'} />
                      )}
                  </div>
                );
              })}
          </div>
          {props.description && (
            <Detail
              disabled={props.disabled}
              className={descriptionClass}
              {...props.description}
            />
          )}
          {props.description && (props.comment || props.button) && (
            <Divider height={1} />
          )}
          {props.comment && (
            <Detail
              disabled={props.disabled}
              className={commentClass}
              {...props.comment}
            />
          )}
          {props.button && (
            <div className={styles.buttonWrapper}>
              <Button {...props.button} />
            </div>
          )}
        </Wrapper>
      )}
    </ContainerQuery>
  );
};

DetailsCard.propTypes = {
  /** The color of the card */
  color: PropTypes.oneOf(Colors),
  /** Padding of the card */
  size: PropTypes.oneOf(Spacings),
  /** Details information */
  details: PropTypes.arrayOf(PropTypes.shape(DetailsCard.propTypes)),
  /** Title of the Details information */
  title: PropTypes.shape(Title.propTypes),
  /** Date of the Details information */
  date: PropTypes.shape(Content.propTypes),
  /** Description of the Details information */
  description: PropTypes.shape(Detail.propTypes),
  /** Comment of the Details information */
  comment: PropTypes.shape(Detail.propTypes),
  /** Badge status of the card */
  badge: PropTypes.shape(Badge.propTypes),
  /** Disable the card */
  disabled: PropTypes.bool,
};

DetailsCard.defaultProps = {
  size: 'l',
};

//Needed for Storybook
DetailsCard.displayName = 'DetailsCard';

export default DetailsCard;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ongoing-cases.module.scss';
import OngoingCaseCard from '../ongoing-case/ongoing-case';
import { Divider } from '@components/ui';
import { Wrapper } from '@components/card';
import { Badge, Count } from '@components/badge';
import { Title, Content } from '@components/text';
import { Button, IconRight, IconLeft } from '@components/button';
import { Spacings, Colors } from '@components/variables';

const OngoingCasesCard = (props) => {
  const count = props.cases.length;

  if (props.title) {
    props.title.size = props.title.size || 'h1';
  }
  return (
    <Wrapper size={props.size}>
      <div className={styles.heading}>
        {props.title && <Title {...props.title} />}
        {count > 0 && <Divider width={10} color={'transparent'} />}
        {count > 0 && (
          <Count text={count} size={'m'} color={props.color} round={true} />
        )}
      </div>
      {props.content && <Content {...props.content} />}
      <div className={styles.cases}>
        {props.cases &&
          props.cases.slice(0, 3).map((ecase, index) => {
            ecase.color = ecase.color
              ? ecase.color
              : index % 2 == 1
                ? 'stable-300'
                : 'light';
            if (
              ecase.badge &&
              index % 2 == 1 &&
              ['stable-300', 'stable-500'].includes(ecase.badge.color)
            ) {
              ecase.badge.color = 'stable-700';
            }
            return (
              <div key={index}>
                <OngoingCaseCard {...ecase} key={index} />
                <Divider height={10} color={'transparent'} />
              </div>
            );
          })}
      </div>
      {count > 3 && (
        <>
          <Badge
            text={`${count - 3} ${props.overflow}`}
            color={'transparent'}
            outline={true}
            size={'s'}
          />
          <Divider height={20} color={'transparent'} />
        </>
      )}
      {props.buttons && (
        <div className={styles.buttons}>
          {props.buttons.map((button, index) => {
            const CustomComponent = button.iconLeft
              ? IconLeft
              : button.IconRight
                ? IconRight
                : Button;
            button.icon =
              button.iconLeft || button.iconRight || button.iconOnly || '';
            return (
              <div key={index}>
                {index > 0 && <Divider height={20} color={'transparent'} />}
                <CustomComponent
                  {...button}
                  className={button.color == 'light' && styles.border}
                />
              </div>
            );
          })}
        </div>
      )}
    </Wrapper>
  );
};

OngoingCasesCard.propTypes = {
  /** The color of the card */
  color: PropTypes.oneOf(Colors),
  /** Padding of the card */
  size: PropTypes.oneOf(Spacings),
  /** The title in the header */
  title: PropTypes.shape(Title.propTypes),
  /** The subtitle in the header */
  content: PropTypes.shape(Content.propTypes),
  /** Case information details */
  cases: PropTypes.arrayOf(PropTypes.shape(OngoingCaseCard.propTypes)),
  /** Badge in a case */
  badge: PropTypes.shape(Badge.propTypes),
  /** The buttons of the card*/
  buttons: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
  /** If there is an overflow */
  overflow: PropTypes.string,
};

OngoingCasesCard.defaultProps = {
  color: 'light',
  size: 'xl',
  overflow: 'more',
};

//Needed for Storybook
OngoingCasesCard.displayName = 'OngoingCasesCard';

export default OngoingCasesCard;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './case.module.scss';
import classNames from 'classnames/bind';
import { Wrapper } from '@components/card';
import { Badge } from '@components/badge';
import { Title, Content, Detail } from '@components/text';
import { Button, IconLeft, IconRight } from '@components/button';
import { Spacings } from '@components/variables';
import { Divider, Tooltip } from '@components/ui';
import { ContainerQuery } from 'react-container-query';

const cx = classNames.bind(styles);
const queries = {
  'small-card': {
    maxWidth: 279,
  },
  'medium-card': {
    maxWidth: 320,
  },
};

const CaseCard = (props) => {
  const classes = cx(props.containerQuery);
  const lineClass = cx({
    [`line-${props.size}`]: true,
  });
  return (
    <ContainerQuery query={queries}>
      {(params) => (
        <Wrapper
          className={`${classes} ${cx(params)}`}
          color={props.color}
          size={props.size}
        >
          <div className={styles.head}>
            <div className={styles.headContent}>
              {props.title && <Title color={'positive'} {...props.title} />}
              {props.content && <Content {...props.content} />}
            </div>
            {props.badge &&
              (props.badge.tooltip ? (
                <Tooltip
                  simple={true}
                  trigger={'mouseenter'}
                  position={'top'}
                  content={props.badge.tooltip}
                >
                  <Badge className={styles.badge} {...props.badge} />
                </Tooltip>
              ) : (
                <Badge className={styles.badge} {...props.badge} />
              ))}
          </div>
          <Divider height={1} className={lineClass} />
          {props.details && (
            <div className={styles.details}>
              {props.details.map((detail, index) => {
                return (
                  <Detail className={styles.detail} key={index} {...detail} />
                );
              })}
            </div>
          )}
          <Divider height={1} className={lineClass} />
          {props.buttons && (
            <div className={styles.buttons}>
              {props.buttons.map((button, index) => {
                const CustomComponent = button.iconLeft
                  ? IconLeft
                  : button.IconRight
                  ? IconRight
                  : Button;
                if (
                  props.color == 'stable-300' &&
                  button.color == 'stable-300'
                ) {
                  button.color = 'light';
                }
                button.icon =
                  button.iconLeft || button.iconRight || button.iconOnly || '';
                const btnClass = cx({
                  button: true,
                  border: button.color == 'light',
                });
                return (
                  <div key={index} className={styles.buttonWrapper}>
                    {index > 0 && <Divider width={10} color={'transparent'} />}
                    <CustomComponent {...button} className={btnClass} />
                  </div>
                );
              })}
            </div>
          )}
        </Wrapper>
      )}
    </ContainerQuery>
  );
};

CaseCard.propTypes = {
  /** The color variant of the card */
  color: PropTypes.oneOf(['light', 'stable-300']),
  /** The size of the card */
  size: PropTypes.oneOf(Spacings),
  /** The title in the header */
  title: PropTypes.shape(Title.propTypes),
  /** The subtitle in the header */
  content: PropTypes.shape(Content.propTypes),
  /** The content of the badge in the header */
  badge: PropTypes.shape(Badge.propTypes),
  /** The data inside the card */
  details: PropTypes.arrayOf(PropTypes.shape(Detail.propTypes)),
  /** The buttons inside the footer of the card */
  buttons: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
};

CaseCard.defaultProps = {
  color: 'light',
  size: 'l',
};

//Needed for Storybook
CaseCard.displayName = 'CaseCard';

export default CaseCard;

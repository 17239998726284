import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from '@components/card';
import { Colors, Spacings } from '@components/variables';

const HtmlCard = (props) => {
  const divWrapper = useRef();
  useEffect(() => {
    if (divWrapper.current) {
      const id = props.id.startsWith('#') ? props.id : `#${props.id}`;
      const elem = document.querySelector(id);
      divWrapper.current && divWrapper.current.appendChild(elem);
      elem.classList.remove('u-hide');
      ReactRailsUJS.mountComponents(elem);
    }
  });
  return (
    <Wrapper size={props.size} color={props.color}>
      {props.id && <div ref={divWrapper} />}
    </Wrapper>
  );
};

HtmlCard.propTypes = {
  /** The id of the hidden div to be placed inside this card */
  id: PropTypes.string.isRequired,
  /** The color of the card */
  color: PropTypes.oneOf(Colors),
  /** The title of the card */
  size: PropTypes.oneOf(['no-p', ...Spacings]),
};

HtmlCard.defaultProps = {
  color: 'stable-300',
  size: 'l',
};

//Needed for Storybook
HtmlCard.displayName = 'HtmlCard';

export default HtmlCard;

import React from 'react';

import CaseCard from './case';

export default {
  title: 'Remark/card/CaseCard',
  component: CaseCard,
};

const Template = (args) => <CaseCard {...args} />;

export const Default = Template.bind({});

Default.args = {
  title: {
    text: '3510396',
  },
  content: {
    text: 'LA7160501-6AD',
  },
  badge: {
    text: 'Action required',
    color: 'energized',
  },
  details: [
    {
      label: {
        text: 'Name of the insured',
      },
      value: {
        text: 'Ben smith',
      },
    },
    {
      label: {
        text: 'Case manager',
      },
      value: {
        text: 'Candice Owens',
      },
    },
    {
      label: {
        text: 'Date of loss',
      },
      value: {
        text: '12-07-2020',
      },
    },
    {
      label: {
        text: 'Submission',
      },
      value: {
        text: '14-07-2020',
      },
    },
  ],
  buttons: [
    {
      text: 'View details',
      iconLeft: 'external',
      action: '#',
    },
    {
      action: '#',
      iconLeft: 'download',
      text: false,
      color: 'stable-300',
    },
  ],
};

export const Tooltip = () => (
  <>
    <CaseCard
      {...Default.args}
      badge={{
        text: 'Action required',
        color: 'energized',
        tooltip: 'I am a tooltip',
      }}
    />
  </>
);

export const Colors = () => (
  <>
    <CaseCard {...Default.args} />

    <CaseCard
      {...Default.args}
      color={'stable-300'}
      buttons={[
        {
          text: 'View details',
          iconLeft: 'external',
          action: '#',
        },
        {
          action: '#',
          iconLeft: 'download',
          text: false,
          color: 'light',
        },
      ]}
    />
  </>
);

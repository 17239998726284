import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from '../modal/modal.module.scss';
import classNames from 'classnames/bind';
import { Button } from '@components/button';
import { Divider } from '@components/ui';
import { Wrapper } from '@components/card';
import { Title, Content } from '@components/text';
import ReactModal from 'react-modal';
const cx = classNames.bind(styles);

const Confirm = ({ title, buttons }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [finalTitle, setFinalTitle] = useState(title);
  const [finalButtons, setFinalButtons] = useState(buttons);
  let window_confirm = null;

  const classes = cx({
    modal: true,
  });
  const overlayClasses = cx({
    overlay: true,
  });

  useEffect(() => {
    //catch rails data-confirm
    window_confirm = window.confirm;
    window.confirm = (title) => {
      const clickedButton = document.activeElement;
      const whiteLabelledAttr = ['type', 'name', 'value', 'href', 'form'];
      finalButtons
        .filter((b) => b.type == 'continue')
        .map((b) => {
          b.customData = {};
          Array.from(clickedButton.attributes).forEach((attr) => {
            if (
              (attr.nodeName.startsWith('data-') &&
                !attr.nodeName.includes('confirm')) ||
              whiteLabelledAttr.includes(attr.nodeName)
            ) {
              b.customData[attr.nodeName] = attr.nodeValue;
            }
            if (attr.nodeName === 'href') {
              b.action = attr.nodeValue;
            }
          });
          return b;
        });
      setIsOpen(true);
      setFinalTitle({ ...finalTitle, text: title });
      return false;
    };
    return () => {
      window.confirm = window_confirm;
    };
  }, []);

  const closeForm = () => {
    setIsOpen(false);
    setFinalTitle(title);
    setFinalButtons(buttons);
  };

  const getActionForButton = (button) => {
    return button.type == 'cancel'
      ? closeForm
      : button.type == 'continue'
      ? null
      : null;
  };

  return (
    <ReactModal
      isOpen={isOpen}
      className={classes}
      overlayClassName={overlayClasses}
      onRequestClose={closeForm}
      ariaHideApp={false}
      portalClassName={'confirm-node'}
    >
      <Divider height={20} color={'transparent'} />
      <Wrapper>
        {finalTitle && <Title align={'center'} {...finalTitle} />}
        {title && <Divider height={15} color={'transparent'} />}
        <div className={styles.buttons}>
          {finalButtons.map((button, index) => {
            const action = getActionForButton(button);
            return (
              <div key={index} className={styles.button}>
                {index > 0 && <Divider width={10} color={'transparent'} />}
                <Button action={action} {...button} />
              </div>
            );
          })}
        </div>
      </Wrapper>
      <Divider height={20} color={'transparent'} />
    </ReactModal>
  );
};

Confirm.propTypes = {
  /** The title of the modal */
  title: PropTypes.shape(Title.propTypes),
  /** The actions of the modal */
  buttons: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
};

Confirm.defaultProps = {
  title: {},
  buttons: [],
};

//Needed for Storybook
Confirm.displayName = 'Confirm';

export default Confirm;

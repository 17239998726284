import React from 'react';
import Datepicker from './datepicker';
import { Divider, Snackbar } from '@components/ui';

export default {
  title: 'Remark/form/Datepicker',
  component: Datepicker,
  parameters: {
    componentSubtitle:
      'Uses React-Flatpickr (https://github.com/haoxins/react-flatpickr) under the hood',
  },
};

const Template = (args) => (
  <>
    <Snackbar
      title={{
        text:
          'This story is only for showing the datepicker props. When using this element in Rails, make sure to use form.Element',
      }}
      type='warning'
    />
    <Divider height={20} color={'transparent'} />
    <Datepicker {...args} />
  </>
);
export const Default = Template.bind({});

Default.args = {
  id: 'datepicker',
};

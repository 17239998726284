import React from 'react';
import Shape from './shape';
import { Divider } from '@components/ui';
import * as ShapeNames from './react-shapes';

export default {
  title: 'Remark/layout/Shape',
  component: Shape,
};

const Template = (args) => (
  <div
    style={{
      position: 'relative',
      height: 325,
      border: '1px solid rgba(var(--color-stable-700), 1)',
    }}
  >
    <Shape {...args} />
  </div>
);

export const Default = Template.bind({});
Default.args = {};

export const Variants = () => (
  <>
    {Object.keys(ShapeNames).map((name, index) => {
      return (
        <div key={index}>
          <div
            style={{
              position: 'relative',
              height: 325,
              border: '1px solid rgba(var(--color-stable-700), 1)',
            }}
          >
            <Shape key={index} shape={name} />
          </div>
          <Divider height={20} color={'transparent'} />
        </div>
      );
    })}
  </>
);

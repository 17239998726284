import React from 'react';
import PropTypes from 'prop-types';
import styles from './stacked-bar-chart.module.scss';
import classNames from 'classnames/bind';
import { Divider } from '@components/ui';
import { Wrapper } from '@components/card';
import { BarChart, LabelChart } from '@components/chart';
import { Title } from '@components/text';
import { Colors, Spacings } from '@components/variables';
const cx = classNames.bind(styles);

const StackedBarChartCard = (props) => {
  return (
    <Wrapper size={props.size} header={props.header}>
      <div className={styles.body}>
        {props.data && (
          <div className={styles.chart}>
            <BarChart
              group={'stacked'}
              reverse={true}
              padding={0}
              innerPadding={4}
              margin={{
                top: 50,
                bottom: 50
              }}
              gridX={false}
              gridY={false}
              axisBottom={null}
              axisLeft={null}
              data={[props.data]}
            />
          </div>
        )}
        {props.data.values && <Divider width={20} color={'transparent'}/>}
        {props.data && props.data.values && (
          <div>
            {props.data.values.map((item, index) => {
              const detail = {
                color: item.color,
                label: {
                  text: item.label,
                },
                value: {
                  text: item.value,
                },
              };
              return (
                <div key={index}>
                  {index !== 0 && <Divider height={1} />}
                  <LabelChart {...detail} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Wrapper>
  );
};

StackedBarChartCard.propTypes = {
  /** The size of the card */
  size: PropTypes.oneOf(Spacings),
  /** The color of the card */
  color: PropTypes.oneOf(Colors),
  /** The title of the card */
  title: PropTypes.shape(Title.propTypes),
  /** The data of the card */
  data: PropTypes.shape(BarChart.propTypes),
};

StackedBarChartCard.defaultProps = {
  color: 'light',
  size: 'l',
};

//Needed for Storybook
StackedBarChartCard.displayName = 'StackedBarChartCard';

export default StackedBarChartCard;

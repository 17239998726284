import React from 'react';
import PropTypes from 'prop-types';
import styles from './content.module.scss';
import classNames from 'classnames/bind';
import { Wrapper } from '@components/card';
import { Title, Content } from '@components/text';
import { Button, IconLeft, IconRight } from '@components/button';
import { Colors, Spacings } from '@components/variables';
import { Divider, Image } from '@components/ui';
const cx = classNames.bind(styles);
import { ContainerQuery } from 'react-container-query';
const queries = {
  'medium-card': {
    maxWidth: 600,
  },
};

const ContentCard = (props) => {
  const imageStyles = cx(props.size, {
    image: true,
  });

  let classes = cx('content',
    {
      inline: props.inline
    });

  let textColor = 'light';
  if (['transparent', 'light'].includes(props.color)) {
    textColor = 'dark';
  } else if (['stable-300', 'stable-500', 'stable-700'].includes(props.color)) {
    textColor = 'stable-900';
  } else if (['positive-300'].includes(props.color)) {
    textColor = 'positive';
  }

  let contentColor = 'light';
  if (
    ['transparent', 'light', 'stable-300', 'stable-500', 'stable-700'].includes(
      props.color
    )
  ) {
    contentColor = 'stable-900';
  } else if (['positive-300'].includes(props.color)) {
    contentColor = 'positive';
  }
  return (
    <ContainerQuery query={queries}>
      {(params) => (
        <Wrapper color={props.color} size={props.size} className={`${classes} ${cx(params)}`}>
          {props.image && (
            <Image src={props.image} objectFit={true} className={imageStyles} />
          )}
          <div className={styles.body}>
            {props.title && <Title size={'h1'} color={textColor} {...props.title} />}
            {props.content && <Divider height={5} color={'transparent'} />}
            {props.content && <Content color={contentColor} {...props.content} />}
            {props.buttons && <Divider height={15} color={'transparent'} />}
            {props.buttons && (
              <div className={styles.buttons}>
                {props.buttons.map((button, index) => {
                  const Component = button.iconLeft
                    ? IconLeft
                    : button.iconRight
                      ? IconRight
                      : Button;
                  button.icon = button.iconLeft
                    ? button.iconLeft
                    : button.iconRight
                      ? button.iconRight
                      : null;
                  return <Component key={index} size={'l'} {...button} />;
                })}
              </div>
            )}
          </div>
        </Wrapper>
      )}
    </ContainerQuery>
  );
};
ContentCard.propTypes = {
  /** The color variant of the card */
  color: PropTypes.oneOf(Colors),
  /** To size of the card */
  size: PropTypes.oneOf(Spacings),
  /** The path to the image */
  image: PropTypes.string,
  /** The title in the card */
  title: PropTypes.shape(Title.propTypes),
  /** The content in the card */
  content: PropTypes.shape(Content.propTypes),
  /** The buttons in the card */
  buttons: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
  /** The content is inline */
  inline: PropTypes.bool
};

ContentCard.defaultProps = {
  color: 'light',
  size: 'xl',
};

//Needed for Storybook
ContentCard.displayName = 'ContentCard';

export default ContentCard;

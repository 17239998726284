import React from 'react';
import PropTypes from 'prop-types';
import styles from './badge.module.scss';
import classNames from 'classnames/bind';
import { Colors, Spacings } from '@components/variables';
import { Title } from '@components/text';
const cx = classNames.bind(styles);

const Badge = (props) => {
  const classes = cx(props.color, props.size, props.className, {
    badge: true,
    outline: props.outline,
    status: props.status,
  });
  const textColor = props.textColor
    ? props.textColor
    : ['transparent', 'stable-300', 'stable-500', 'stable-700'].includes(
      props.color
    )
      ? 'stable-900'
      : ['light', 'positive-300'].includes(props.color)
        ? 'positive'
        : props.status
          ? 'light'
          : props.color;

  return (
    <div className={classes}>
      <Title
        text={props.text}
        color={textColor}
        size={props.size == 'xs' ? 'small' : 'label'}
        inline={true}
        className={styles.title}
        emphasis={props.emphasis || props.status}
      />
    </div>
  );
};

Badge.propTypes = {
  /** The text of the badge */
  text: PropTypes.string.isRequired,
  /** The color of the badge */
  color: PropTypes.oneOf(Colors),
  /** The size of the badge */
  size: PropTypes.oneOf(Spacings),
  /** Displays the status of an item */
  status: PropTypes.bool,
};

Badge.defaultProps = {
  size: 'm',
  color: 'positive',
  text: 'Missing text prop',
  status: false,
};

//Needed for Storybook
Badge.displayName = 'Badge';

export default Badge;

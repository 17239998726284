import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from '../modal/modal.module.scss';
import classNames from 'classnames/bind';
import { Button } from '@components/button';
import { Divider } from '@components/ui';
import { Wrapper } from '@components/card';
import { Title, Content } from '@components/text';
import ReactModal from 'react-modal';
const cx = classNames.bind(styles);

const Alert = ({ title, content, buttons, open }) => {
  const [isOpen, setIsOpen] = useState(open);
  const [url, setUrlTo] = useState('');

  const classes = cx({
    modal: true,
  });
  const overlayClasses = cx({
    overlay: true,
  });

  useEffect(() => {
    document.addEventListener('turbolinks:before-visit', handleChange);

    return () => {
      document.removeEventListener('turbolinks:before-visit', handleChange);
    };
  }, []);

  const handleChange = (event) => {
    if (document.documentElement.dataset.hasChanges) {
      event.preventDefault();
      setIsOpen(true);
      setUrlTo(event.data.url);
    }
  };

  const closeForm = () => {
    setIsOpen(false);
  };

  const visitUrl = () => {
    delete document.documentElement.dataset.hasChanges;
    Turbolinks.visit(url);
  };

  const getActionForButton = (button) => {
    return button.type == 'cancel'
      ? closeForm
      : button.type == 'continue'
      ? visitUrl
      : null;
  };

  return (
    <ReactModal
      isOpen={isOpen}
      className={classes}
      overlayClassName={overlayClasses}
      onRequestClose={closeForm}
      ariaHideApp={false}
      portalClassName={'alert-node'}
    >
      <Divider height={20} color={'transparent'} />
      <Wrapper>
        {title && <Title align={'center'} {...title} />}
        {title && <Divider height={15} color={'transparent'} />}
        {content && <Content align={'center'} {...content} />}
        {content && <Divider height={25} color={'transparent'} />}
        <div className={styles.buttons}>
          {buttons.map((button, index) => {
            const action = getActionForButton(button);
            return (
              <div key={index} className={styles.button}>
                {index > 0 && <Divider width={10} color={'transparent'} />}
                <Button action={action} {...button} />
              </div>
            );
          })}
        </div>
      </Wrapper>
      <Divider height={20} color={'transparent'} />
    </ReactModal>
  );
};

Alert.propTypes = {
  /** The title of the alert */
  title: PropTypes.shape(Title.propTypes),
  /** The content of the alert */
  content: PropTypes.shape(Content.propTypes),
  /** The actions of the alert */
  buttons: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
  /** Whether the alert should be visible on initial load */
  open: PropTypes.bool,
};

Alert.defaultProps = {
  title: {},
  content: {},
  buttons: [],
  open: false,
};

//Needed for Storybook
Alert.displayName = 'Alert';

export default Alert;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './option.module.scss';
import classNames from 'classnames/bind';
import { Divider } from '@components/ui';
import { Title } from '@components/text';

const cx = classNames.bind(styles);

const Option = (props) => {
  let classes = cx(
    {
      wrapper: true,
      radio: props.type == 'radio',
      checkbox: props.type == 'checkbox',
      disabled: props.disabled,
      readonly: props.readonly,
    },
    props.className
  );
  const label = props.text ? props.text : props.label ? props.label : false;

  const value =
    props.value !== undefined
      ? props.value
      : props.type == 'radio'
      ? 'missing_value'
      : 1;

  return (
    <label className={classes}>
      <input
        ref={props.reference}
        id={props.id}
        className={styles.input}
        type={props.type}
        name={props.name}
        value={value}
        disabled={props.disabled}
        readOnly={props.readonly}
        defaultChecked={props.checked}
        onInput={props.input}
        onChange={props.onChange}
        {...props.attributes}
      />
      <span className={styles.fakeInput} />
      <Divider width={10} color={'transparent'} />
      {label && <Title text={label} size={'body'} inline={true} />}
    </label>
  );
};

Option.propTypes = {
  /** The input type */
  type: PropTypes.oneOf(['checkbox', 'radio']),
  /** The name of the input */
  name: PropTypes.string.isRequired,
  /** The value of the input */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  /** The placeholder text */
  placeholder: PropTypes.string,
  /** Whether the input is disabled */
  disabled: PropTypes.bool,
  /** Whether the input is readonly */
  readonly: PropTypes.bool,
  /** A unique id for the input */
  id: PropTypes.string,
};

Option.defaultProps = {
  type: 'checkbox',
  name: 'checkBox',
};

//Needed for Storybook
Option.displayName = 'Option';

export default Option;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './bar.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);
import { Title, Content } from '@components/text';
import { Colors } from '@components/variables';
import { ResponsiveBar } from '@nivo/bar';

const BarChart = (props) => {
  let classes = cx('chart', props.className, props.data);
  const barColors = props.colors;

  //map data to nivo format
  const keys = [];
  const data = props.data.map((d) => {
    const colorsToUse = d.colors ? d.colors : barColors;
    const values = [];
    d.values.forEach((v, i) => {
      if (v.skip) {
        return {};
      }
      const index = v.label ? v.label : `index_${i}`;
      if (!keys.includes(index)) {
        keys.push(index);
      }
      values[index] = v.label ? v.value : v;
      values[`${index}Color`] = v.color ? v.color : colorsToUse[i];
      values[`${index}Percentage`] = v.percentage ? v.percentage : false;
    });
    return {
      label: d.label ? d.label : '',
      ...values,
    };
  });

  return (
    <div className={classes}>
      <ResponsiveBar
        colors={(d) => {
          return `rgba(var(--color-${d.data[`${d.id}Color`]}), 1)`;
        }}
        indexBy='label'
        keys={keys}
        data={data}
        margin={props.margin}
        padding={props.padding}
        innerPadding={props.innerPadding}
        groupMode={props.group}
        reverse={props.reverse}
        enableGridY={props.gridY}
        enableGridX={props.gridX}
        enableLabel={false}
        borderRadius={props.borderRadius || 3}
        axisTop={null}
        axisRight={null}
        axisBottom={props.axisBottom}
        axisLeft={props.axisLeft}
        labelSkipWidth={10}
        labelSkipHeight={12}
        legends={[]}
        tooltip={({ id, value, data }) => (
          <div className={styles.tooltip}>
            <Content text={id} align={'center'} />
            <Title text={data[`${id}Percentage`] ? data[`${id}Percentage`] + '%' : value} size={'h2'} align={'center'} />
          </div>
        )}
        theme={{
          tooltip: {
            container: {
              borderRadius: 'var(--radius-m)',
              boxShadow: 'var(--shadow-s)',
              padding: 'var(--spacing-xxs) var(--spacing-s)',
              border: '1px solid rgba(var(--color-stable-700), 1)',
            },
          },
        }}
      />
    </div>
  );
};

BarChart.propTypes = {
  /** The colors of the bars, should contain the same number of colors as data points */
  colors: PropTypes.arrayOf(PropTypes.oneOf(Colors)),
  /** The chart group type: 'stacked' or 'grouped' */
  group: PropTypes.string,
  /** Reverses the chart */
  reverse: PropTypes.bool,
  /** The padding of the bars in the chart (width) */
  padding: PropTypes.number,
  /** The innerPadding of the bars in the chart (width) */
  innerPadding: PropTypes.number,
  /** The margin around the chart */
  margin: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    right: PropTypes.number,
    left: PropTypes.number,
  }),
  /** Display gridY */
  gridY: PropTypes.bool,
  /** Display gridX */
  gridX: PropTypes.bool,
  /** The axisBottom of the chart, to disable use null */
  axisBottom: PropTypes.shape({
    tickSize: PropTypes.number,
    tickPadding: PropTypes.number,
    tickRotation: PropTypes.number,
    legendPosition: PropTypes.string,
  }),
  /** The axisLeft of the chart, to disable use null */
  axisLeft: PropTypes.shape({
    tickSize: PropTypes.number,
    tickPadding: PropTypes.number,
    tickRotation: PropTypes.number,
    legendPosition: PropTypes.string,
  }),
  /** The data of the chart */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          skip: PropTypes.bool,
          label: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
      ),
      colors: PropTypes.array,
    })
  ),
};

BarChart.defaultProps = {
  colors: Colors,
  padding: 10,
};

//Needed for Storybook
BarChart.displayName = 'BarChart';

export default BarChart;

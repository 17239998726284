import React from 'react';
import StackedBarChartCard from './stacked-bar-chart';
import { Spacings, Colors as ColorNames } from '@components/variables';
import KpiCard from "../kpi/kpi";

export default {
  title: 'Remark/card/StackedBarChartCard',
  component: StackedBarChartCard,
};

const Template = (args) => <StackedBarChartCard {...args} />;

export const Default = Template.bind({});

Default.args = {
  header: {
    text: 'Claims approved',
  },
  data: {
    values: [
      {
        id: '1',
        color: 'balanced',
        label: 'Approved',
        value: '100',
      },
      {
        id: '2',
        color: 'assertive',
        label: 'Declined',
        value: '120',
      },
    ],
  },
};

export const Colors = () => (
 <StackedBarChartCard
 data={{
   colors: ['assertive', 'balanced', 'energized'],
   values: [
     {
       id: '1',
       color: 'royal',
       label: 'Royal',
       value: '100',
     },
     {
       id: '2',
       color: 'positive',
       label: 'Positive',
       value: '120',
     },
     {
       id: '3',
       color: 'energized',
       label: 'Energized',
       value: '120',
     },
   ],
 }}
 />
);

export const WithPercentageTooltip = () => (
  <StackedBarChartCard
    percentage={true}
    data={{
      colors: ['assertive', 'balanced', 'energized'],
      values: [
        {
          id: '1',
          color: 'royal',
          label: 'Royal',
          value: '100',
          percentage: 33
        },
        {
          id: '2',
          color: 'positive',
          label: 'Positive',
          value: '120',
          percentage: 33
        },
        {
          id: '3',
          color: 'energized',
          label: 'Energized',
          value: '120',
          percentage: 33
        },
      ],
    }}
  />
);




import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './file.module.scss';
import classNames from 'classnames/bind';
import { Icon, Divider } from '@components/ui';
import { Title } from '@components/text';
import { Count } from '@components/badge';

const cx = classNames.bind(styles);

const File = (props) => {
  const [enter, setEnter] = useState(false);
  const [file, setFile] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [typeError, setTypeError] = useState(false);

  const classes = cx(
    {
      file: true,
      multiple: props.multiple,
      enter: enter,
      sizeError: sizeError,
      typeError: typeError
    },
    props.className
  );

  const files = props.multiple ? props.strings.multiple : props.strings.single;
  const handleDrop = (e) => {
    setEnter(false);
    const dt = e.dataTransfer;
    const files = dt.files;
    [...files].forEach((file, index) => {
      setFile(file.name);
    });
  };

  const handleChange = (e) => {
    setEnter(false);

    const fileBytes = e.currentTarget.files[0].size;
    const fileSize = fileBytes / Math.pow(1024,2);
    if(fileSize > 50) {
      setSizeError(true);
    } else {
      setSizeError(false);
    }

    const fullPath = e.currentTarget.value;
    const startIndex =
      fullPath.indexOf('\\') >= 0
        ? fullPath.lastIndexOf('\\')
        : fullPath.lastIndexOf('/');
    let filename = fullPath.substring(startIndex);
    if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
      filename = filename.substring(1);
    }
    setFile(filename);

    const fileType = filename.substr(filename.lastIndexOf('.')).toLowerCase();
    if(fileType === '.pdf') {
      setTypeError(false);
    } else {
      setTypeError(true);
    }
  };
  return (
    <div className={classes}>
      <div className={styles.inner}>
        <Icon
          icon={'add-file'}
          width={50}
          height={50}
          color={'positive'}
          stroke={true}
        />
        <Divider height={20} color={'transparent'} />
        {file ? (
          <Title
            text={file}
            inline={true}
            size={'body'}
            emphasis={true}
            color={'stable-900'}
          />
        ) : (
          <div>
            <Title
              text={files}
              inline={true}
              size={'body'}
              emphasis={true}
              color={'stable-900'}
            />
            <label htmlFor={props.id}>
              <Title
                text={' ' + props.strings.button}
                inline={true}
                size={'body'}
                emphasis={true}
                color={'positive'}
              />
            </label>
          </div>
        )}
      </div>
      <input
        id={props.id}
        className={styles.input}
        type={props.type}
        name={props.name}
        disabled={props.disabled}
        readOnly={props.readonly}
        placeholder={props.placeholder}
        onInput={props.input}
        onDragEnter={() => setEnter(true)}
        onDragLeave={() => setEnter(false)}
        onDrop={handleDrop}
        onChange={handleChange}
      />
    </div>
  );
};

File.propTypes = {
  /** The input type */
  type: PropTypes.oneOf(['file']).isRequired,
  /** The name of the input */
  name: PropTypes.string.isRequired,
  /** The placeholder text */
  placeholder: PropTypes.string,
  /** Whether the input is disabled */
  disabled: PropTypes.bool,
  /** Whether the input is readonly */
  readonly: PropTypes.bool,
  /** A unique id for the input */
  id: PropTypes.string.isRequired,
  /** Single vs multi file upload */
  multiple: PropTypes.bool,
  strings: PropTypes.shape({
    multiple: PropTypes.string,
    single: PropTypes.string,
    button: PropTypes.string,
  })
};

File.defaultProps = {
  type: 'file',
  name: 'file',
  strings: {
    multiple: 'Drag and drop document files here to upload or',
    single: 'Drag and drop document file here to upload or',
    button: 'choose a file'
  }
};

//Needed for Storybook
File.displayName = 'File';

export default File;

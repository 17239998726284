/*
const registeredCssVariables = Array.from(document.styleSheets)
  .filter(
    (sheet) =>
      sheet.href === null || sheet.href.startsWith(window.location.origin)
  )
  .reduce(
    (acc, sheet) =>
      (acc = [
        ...acc,
        ...Array.from(sheet.cssRules).reduce(
          (def, rule) =>
            (def =
              rule.selectorText === ':root'
                ? [
                    ...def,
                    ...Array.from(rule.style).filter((name) =>
                      name.startsWith('--')
                    ),
                  ]
                : def),
          []
        ),
      ]),
    []
  );

let styles = '';
const colorVar = '--color-';
const Colors = registeredCssVariables
  .filter((rule) => rule.indexOf(colorVar) == 0)
  .map((r) => {
    const color = r.replace(colorVar, '');
    if (color == `transparent`) {
      styles += `.${color}{color: rgba(var(${r}), 0);}`;
    } else if (color == `positive-300`) {
      styles += `.${color}{color: rgba(var(${r}), 0.15);}`;
    } else {
      styles += `.${color}{color: rgba(var(${r}), 1);}`;
    }
    return color;
  });

const fontVar = '--font-stack';
const Fonts = registeredCssVariables
  .filter((rule) => rule.indexOf(fontVar) == 0)
  .map((r) => {
    styles += `.${r.replace('--', '')}{font-family: var(${r});}`;
    return r.replace(`${fontVar}-`, '');
  });

const sizeVar = '--font-size-';
const Sizes = registeredCssVariables
  .filter((rule) => rule.indexOf(sizeVar) == 0)
  .map((r) => {
    styles += `.${r.replace('--', '')}{font-size: var(${r});}`;
    return r.replace(sizeVar, '');
  });

const spacingVar = '--spacing-';
const Spacings = registeredCssVariables
  .filter((rule) => rule.indexOf(spacingVar) == 0)
  .map((r) => {
    styles += `.${r.replace('--', '')}{padding: var(${r});}`;
    return r.replace(spacingVar, '');
  });

const radiusVar = '--radius-';
const Radius = registeredCssVariables
  .filter((rule) => rule.indexOf(radiusVar) == 0)
  .map((r) => {
    styles += `.${r.replace('--', '')}{border-radius: var(${r});}`;
    return r.replace(radiusVar, '');
  });

const shadowVar = '--shadow-';
const Shadows = registeredCssVariables
  .filter((rule) => rule.indexOf(shadowVar) == 0)
  .map((r) => {
    styles += `.${r.replace('--', '')}{box-shadow: var(${r});}`;
    return r.replace(shadowVar, '');
  });

const Alignments = ['left', 'center', 'right'];
Alignments.map((r) => {
  styles += `.${r}{text-align: ${r}}`;
});

const style = document.createElement('style');
style.innerHTML = styles;
document.head.appendChild(style);
*/
const Colors = [
  'transparent',
  'light',
  'stable-300',
  'stable-500',
  'stable-700',
  'stable-900',
  'positive-300',
  'positive',
  'calm',
  'balanced',
  'energized',
  'assertive',
  'royal',
  'dark',
];
const Spacings = ['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl'];
const Alignments = ['left', 'center', 'right'];
const Fonts = ['--font-stack', 'alt', 'handwriting'];
const Sizes = ['xxxl', 'xxl', 'xl', 'l', 'm', 's', 'xs', 'xxs'];
const Radius = ['xs', 's', 'm', 'l', 'xl'];
const Shadows = ['s'];
export { Colors, Spacings, Alignments, Fonts, Sizes, Radius, Shadows };

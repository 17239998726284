import React from 'react';
import PropTypes from 'prop-types';
import styles from './selectable.module.scss';
import classNames from 'classnames/bind';
import { Element } from '@components/form';
import { Divider, Icon } from '@components/variables';
import { Wrapper } from '@components/card';
import { Title, Content } from '@components/text';
import { Button, IconRight, IconLeft } from '@components/button';
import { Colors, Spacings } from '@components/variables';
const cx = classNames.bind(styles);

const SelectableCard = (props) => {
  let classes = cx('selectable', props.className);

  if (props.title) {
    props.title.size = props.title.size || 'body';
  }

  if (props.select) {
    props.select.type = props.select.type || 'checkbox';
  }

  return (
    <Wrapper className={classes} size={props.size} color={props.color}>
      <div className={styles.select}>
        {props.select && <Element {...props.select} />}
        {props.title && <Title emphasis={true} {...props.title} />}
      </div>
      {props.remove && (
        <Button
          className={styles.remove}
          link={true}
          text={false}
          icon={'cross'}
          {...props.remove}
        />
      )}
    </Wrapper>
  );
};

SelectableCard.propTypes = {
  /** The select options of the checkbox */
  select: PropTypes.shape(Element.propTypes),
  /** The size of the card */
  size: PropTypes.oneOf(Spacings),
  /** The color of the card */
  color: PropTypes.oneOf(Colors),
  /** The title of the card */
  title: PropTypes.shape(Title.propTypes),
  /** The remove button of the card */
  remove: PropTypes.shape(Button.propTypes),
};

SelectableCard.defaultProps = {
  color: 'light',
  size: 'l',
};

//Needed for Storybook
SelectableCard.displayName = 'SelectableCard';

export default SelectableCard;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './navbar.module.scss';
import classNames from 'classnames/bind';
import { Logo, Divider, Tooltip, Icon } from '@components/ui';
import { Steps } from '@components/progress';
import { Count } from '@components/badge';
import { Title, Content } from '@components/text';
import { Button, IconLeft, IconRight, Tab } from '@components/button';
import Notifications from '../notifications/notifications';
import { ContainerQuery } from 'react-container-query';
const cx = classNames.bind(styles);
const queries = {
  'small-card': {
    maxWidth: 350,
  },
  'medium-card': {
    maxWidth: 600,
  },
  'large-card': {
    maxWidth: 1024,
  },
};

const NavBar = (props) => {
  let classes = cx(
    {
      navbar: true,
      [props.size]:
        !props.center &&
        (!props.left || !props.left.tabs) &&
        (!props.right || !props.right.profile),
      hasLogo: props.left && props.left.logo,
    },
    props.color
  );
  const formatButtonProps = (button, color) => {
    button.icon = button.iconLeft || button.iconRight || button.iconOnly || '';
    button.outline =
      button.outline || (color != 'light' && button.color != 'light');
    button.color = button.color
      ? button.color
      : color != 'light'
      ? 'light'
      : 'transparent';
    button.column = true;
    return button;
  };

  const formatButtonComponent = (button) => {
    return button.iconLeft ? IconLeft : button.IconRight ? IconRight : Button;
  };
  const renderTitle = (title) => {
    const breadCrumbs =
      props.left && props.left.title && props.left.title.split(' > ');
    if (breadCrumbs.length > 0) {
      return (
        <div className={styles.breadCrumbs}>
          {breadCrumbs.map((breadcrumb, index) => {
            return (
              <div className={styles.breadCrumbItem} key={index}>
                {index > 0 && (
                  <>
                    <Divider width={15} color={'transparent'} />
                    <Icon
                      icon={'chevron-right'}
                      color={props.color == 'positive' ? 'light' : 'dark'}
                      width={8}
                      height={13}
                    />
                    <Divider width={15} color={'transparent'} />
                  </>
                )}
                <Title
                  text={breadcrumb}
                  color={props.color == 'positive' ? 'light' : false}
                />
              </div>
            );
          })}
        </div>
      );
    }
    return (
      <Title text={title} color={props.color == 'positive' ? 'light' : false} />
    );
  };
  return (
    <ContainerQuery query={queries}>
      {(params) => (
        <>
          <div className={`${classes} ${cx(params)}`}>
            {params && params['medium-card'] && props.center && (
              <div className={styles.steps}>
                <div className={styles.center}>
                  {props.center.steps && (
                    <Steps color={props.color} steps={props.center.steps} />
                  )}
                </div>
              </div>
            )}
            {props.left && (
              <div className={styles.left}>
                {props.left.logo && (
                  <div className={styles.logoContainer}>
                    <Logo
                      url={props.logo_url}
                      color={props.color == 'positive' ? 'light' : false}
                    />
                    <Divider width={30} color={props.color} />
                  </div>
                )}
                {(props.left.title || props.left.content) && (
                  <div className={styles.content}>
                    {props.left.title && renderTitle(props.left.title)}
                    {props.left.title && props.left.content && (
                      <Divider height={15} color={'transparent'} />
                    )}
                    {props.left.content && (
                      <Content
                        text={props.left.content}
                        color={props.color == 'positive' ? 'light' : false}
                        emphasis={true}
                      />
                    )}
                  </div>
                )}
                {props.left.buttons &&
                  props.left.buttons.map((button, index) => {
                    const CustomComponent = formatButtonComponent(button);
                    button = formatButtonProps(button, props.color);
                    return (
                      <div key={index} className={styles.btnWrapper}>
                        {index > 0 && (
                          <Divider width={10} color={'transparent'} />
                        )}
                        <CustomComponent {...button} />
                      </div>
                    );
                  })}
                {props.left.tabs &&
                  props.left.tabs.map((tab, index) => {
                    return <Tab key={index} className={styles.tab} {...tab} />;
                  })}
              </div>
            )}
            {params &&
              !params['small-card'] &&
              !params['medium-card'] &&
              props.center && (
                <div className={styles.center}>
                  {props.center.steps && (
                    <Steps color={props.color} steps={props.center.steps} />
                  )}
                </div>
              )}
            {props.right && (
              <div className={styles.right}>
                {props.right.buttons &&
                  props.right.buttons.map((button, index) => {
                    const CustomComponent = formatButtonComponent(button);
                    button = formatButtonProps(button, props.color);
                    return (
                      <div key={index} className={styles.btnWrapper}>
                        {index > 0 && (
                          <Divider width={10} color={'transparent'} />
                        )}
                        <CustomComponent {...button} />
                      </div>
                    );
                  })}

                {props.right.notifications && (
                  <Notifications notifications={props.right.notifications} />
                )}

                {props.right.profile && (
                  <div className={styles.profile}>
                    {props.right.profile.actions ? (
                      <Tooltip
                        actions={props.right.profile.actions}
                        position={'bottom-end'}
                      >
                        <Count
                          text={props.right.profile.name[0].toUpperCase()}
                          size={'l'}
                          textSize={'h4'}
                          round={true}
                          color={'assertive'}
                          lighten={true}
                        />
                      </Tooltip>
                    ) : props.right.profile.name ? (
                      <Count
                        text={props.right.profile.name[0].toUpperCase()}
                        size={'l'}
                        textSize={'h4'}
                        round={true}
                        color={'assertive'}
                        lighten={true}
                      />
                    ) : (
                      <div />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          {props.color == 'light' && (
            <Divider height={1} color={'stable-700'} />
          )}
        </>
      )}
    </ContainerQuery>
  );
};

NavBar.propTypes = {
  /** The url of the logo */
  logo_url: PropTypes.string,
  /** The backgroundColor of the bar */
  color: PropTypes.oneOf(['light', 'positive']),
  /** The size of the bar (vertical space) */
  size: PropTypes.oneOf(['xs', 's', 'm', 'l']),
  /** The components in the left side of the bar */
  left: PropTypes.shape({
    logo: PropTypes.bool,
    buttons: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
    tabs: PropTypes.arrayOf(PropTypes.shape(Tab.propTypes)),
  }),
  /** The components in the right side of the bar */
  right: PropTypes.shape({
    buttons: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
  }),
};

NavBar.defaultProps = {
  logo_url: '/',
  color: 'positive',
  size: 'm',
};

//Needed for Storybook
NavBar.displayName = 'NavBar';

export default NavBar;

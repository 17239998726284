import React from 'react';
import PropTypes from 'prop-types';
import styles from './bar-chart.module.scss';
import classNames from 'classnames/bind';
import { Element } from '@components/form';
import { Wrapper } from '@components/card';
import { BarChart, LabelChart } from '@components/chart';
import { Title } from '@components/text';
import { Colors, Spacings } from '@components/variables';
const cx = classNames.bind(styles);

const BarChartCard = (props) => {
  const bodyClass = cx(props.className, 'body', {
    [`body-${props.size}`]: true,
  });

  const getLabels = (data) => {
    const results = [];
    data.forEach((items) => {
      items.forEach((v) => {
        if (results[v.color]) {
          results[v.color].value += Number(v.value);
        } else {
          results[v.color] = {
            color: v.color,
            label: v.label,
            value: Number(v.value),
          };
        }
      });
    });
    return Object.values(results);
  };

  const labelArray = props.labels
    ? props.labels
    : props.data
      ? getLabels(props.data.map((v) => v.values))
      : [];
  return (
    <Wrapper size={props.size} header={props.header}>
      <div className={bodyClass}>
        <div className={styles.labels}>
          {props.data &&
            labelArray.map((item, index) => {
              const detail = {
                color: item.color,
                label: {
                  text: item.label,
                },
                value: {
                  text: item.value,
                },
              };

              return (
                <div className={styles.sections} key={index}>
                  <LabelChart className={styles.label} {...detail} />
                </div>
              );
            })}
        </div>
        <div className={styles.chart}>
          {props.data && (
            <BarChart
              gridX={false}
              group={'grouped'}
              padding={0.85}
              margin={{
                top: 50,
                bottom: 50,
                left: 50,
                right: 50,
              }}
              borderRadius={5}
              data={props.data}
            />
          )}
        </div>
      </div>
    </Wrapper>
  );
};

BarChartCard.propTypes = {
  /** The size of the card */
  size: PropTypes.oneOf(['no-p', ...Spacings]),
  /** The color of the card */
  color: PropTypes.oneOf(Colors),
  /** The title of the card */
  title: PropTypes.shape(Title.propTypes),
  /** The filters of the card*/
  filters: PropTypes.arrayOf(PropTypes.shape(Element.propTypes)),
  /** The data of the barchart*/
  data: PropTypes.arrayOf(PropTypes.shape(BarChart.propTypes)),
};

BarChartCard.defaultProps = {
  color: 'light',
  size: 'l',
};

//Needed for Storybook
BarChartCard.displayName = 'BarChartCard';

export default BarChartCard;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './datepicker.module.scss';
import classNames from 'classnames/bind';
import Flatpickr from 'react-flatpickr';
import { Icon } from '@components/ui';

const cx = classNames.bind(styles);

const Datepicker = (props) => {
  const classes = cx(
    {
      datepicker: true,
    },
    props.className
  );
  const innerStyles = cx(
    {
      inner: true,
    },
    props.color
  );

  const _customDataToAttr = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, value])
    );
  };
  const attributes = props.customData
    ? _customDataToAttr(props.customData)
    : [];
  return (
    <Flatpickr
      defaultValue={props.placeholder}
      id={props.id}
      name={props.name}
      className={classes}
      value={props.value}
      disabled={props.disabled}
      readOnly={props.readonly}
      onChange={props.input}
      render={({ defaultValue, value, ...props }, ref) => {
        return (
          <div className={innerStyles}>
            <input
              {...props}
              defaultValue={defaultValue}
              ref={ref}
              {...attributes}
            />
            <Icon
              icon={'calendar'}
              color={props.color}
              className={styles.icon}
            />
          </div>
        );
      }}
      options={{
        dateFormat: 'd-m-Y',
        ...props.options,
      }}
    />
  );
};

Datepicker.propTypes = {
  /** The input type */
  type: PropTypes.oneOf(['datepicker']).isRequired,
  /** The name of the input */
  name: PropTypes.string.isRequired,
  /** The value of the input */
  value: PropTypes.string,
  /** The placeholder text */
  placeholder: PropTypes.string,
  /** Whether the input is disabled */
  disabled: PropTypes.bool,
  /** Whether the input is readonly */
  readonly: PropTypes.bool,
  /** A unique id for the input */
  id: PropTypes.string.isRequired,
  /** All Flatpickr options (https://flatpickr.js.org/options/) */
  options: PropTypes.object,
};

Datepicker.defaultProps = {
  type: 'datepicker',
  name: 'datepicker',
};

//Needed for Storybook
Datepicker.displayName = 'Datepicker';

export default Datepicker;

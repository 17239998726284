import React from 'react';
import Element from './element';
import { Divider } from '@components/ui';

export default {
  title: 'Remark/form/Element',
  component: Element,
};

const Template = (args) => <Element {...args} />;

export const Default = Template.bind({});

export const Variants = () => (
  <>
    <Element label={'This is a label'} {...Default.args} />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'This is a label with a tooltip'}
      tooltip={{ content: 'I am a Tooltip' }}
    />
    <Divider height={20} color={'transparent'} />
    <Element label={'This is an inline label'} inline={true} />
    <Divider height={20} color={'transparent'} />
    <Element label={'Password'} type={'password'} />
    <Divider height={20} color={'transparent'} />
    <Element label={'E-mail'} type={'email'} />
    <Divider height={20} color={'transparent'} />
    <Element label={'Phone'} type={'tel'} />
    <Divider height={20} color={'transparent'} />
    <Element label={'Disabled'} disabled={true} />
    <Divider height={20} color={'transparent'} />
    <Element label={'Readonly'} readonly={true} />
    <Divider height={20} color={'transparent'} />
    <Element label={'Textarea'} type={'textarea'} resize={true} />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'Select'}
      type={'select'}
      options={[
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'Checkbox'}
      type={'checkbox'}
      options={[
        {
          checked: false,
          label: 'Male',
          name: 'case[gender]',
          value: 'male',
        },
        {
          checked: false,
          label: 'Female',
          name: 'case[gender]',
          value: 'female',
        },
        {
          checked: false,
          label: 'Other',
          name: 'case[gender]',
          value: 'other',
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'Radio'}
      type={'radio'}
      options={[
        {
          checked: false,
          label: 'Male',
          name: 'case[gender]',
          value: 'male',
        },
        {
          checked: false,
          label: 'Female',
          name: 'case[gender]',
          value: 'female',
        },
        {
          checked: false,
          label: 'Other',
          name: 'case[gender]',
          value: 'other',
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <Element label={'Datepicker'} type={'datepicker'} />
  </>
);

import React from 'react';
import Icon from './icon';
import { Colors as ColorNames } from '@components/variables';
import { Content } from '@components/text';
const iconNames = require('./icons.json');

export default {
  title: 'Remark/ui/Icon',
  component: Icon,
};

const Template = (args) => <Icon {...args} />;

export const Default = Template.bind({});
Default.args = {};

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      return <Icon key={index} icon={'bell'} color={color} />;
    })}
  </>
);
export const Available = () => (
  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
    {iconNames.children.map((icon, index) => {
      const name = icon.name.replace(icon.extension, '');
      const w = ['folder-file', 'user', 'users', 'add-file'].includes(name)
        ? 50
        : name == 'warning'
          ? 149
          : name == 'checkmark-star'
            ? 77
            : 22;
      const h = ['folder-file', 'user', 'users', 'add-file'].includes(name)
        ? 50
        : name == 'warning'
          ? 149
          : name == 'checkmark-star'
            ? 77
            : 22;
      return (
        <div key={index}>
          <div
            style={{
              width: 100,
              height: 100,
              margin: 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid rgba(var(--color-stable-500), 1)',
            }}
          >
            <Icon icon={name} width={w} height={h} />
          </div>
          <Content text={name} align={'center'} size={'xs'} />
        </div>
      );
    })}
  </div>
);

import React from 'react';
import CommentCard from './comment';
import { Colors as ColorNames } from '@components/variables';

export default {
  title: 'Remark/card/CommentCard',
  component: CommentCard,
};

const Template = (args) => <CommentCard {...args} />;

export const Default = Template.bind({});

Default.args = {
  avatar: {
    name: 'Stefan Bilic',
  },
  content: {
    text: 'May I have a copy of your CT Scan as well? Thank you.',
  },
  date: 'October 15, 2020, 8:18 AM',
};

export const Avatar_Variants = () => (
  <>
    <CommentCard
      color={'light'}
      date={'October 15, 2020, 8:18 AM'}
      avatar={{
        name: 'Stefan Bilic',
      }}
      content={{
        text: 'May I have a copy of your CT Scan as well? Thank you.',
      }}
    />
    <CommentCard
      color={'light'}
      date={'October 15, 2020, 8:18 AM'}
      avatar={{
        name: 'Stefan Bilic',
        image: {
          src: 'https://picsum.photos/400',
        },
      }}
      content={{
        text: 'May I have a copy of your CT Scan as well? Thank you.',
      }}
    />
  </>
);

export const Sender_vs_Receiver_vs_Note = () => (
  <>
    <CommentCard color={'positive-300'} sender={true} {...Default.args} />
    <CommentCard color={'light'} {...Default.args} />
    <CommentCard note={true} {...Default.args} />
  </>
);

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      return <CommentCard color={color} {...Default.args} key={index} />;
    })}
  </>
);

import React from 'react';
import Snackbar from './snackbar';
import { Divider } from '@components/ui';

export default {
  title: 'Remark/ui/Snackbar',
  component: Snackbar,
};

const Template = (args) => <Snackbar {...args} />;

export const Default = Template.bind({});
Default.args = {
  type: 'success',
  title: {
    text: 'Successfull request for consultation for case 3510396',
  },
  dismissable: true,
};

export const Types = () => (
  <>
    <Snackbar {...Default.args} type={'none'} />
    <Divider height={15} color={'transparent'} />
    <Snackbar {...Default.args} type={'info'} />
    <Divider height={15} color={'transparent'} />
    <Snackbar {...Default.args} />
    <Divider height={15} color={'transparent'} />
    <Snackbar {...Default.args} dismissable={false} />
    <Divider height={15} color={'transparent'} />
    <Snackbar {...Default.args} type={'warning'} />
    <Divider height={15} color={'transparent'} />
    <Snackbar {...Default.args} type={'error'} />
    <Divider height={15} color={'transparent'} />
  </>
);

export const Badges_And_Actions = () => (
  <>
    <Snackbar
      {...Default.args}
      badge={{ text: 'New request', size: 'm' }}
      dismissable={false}
    />
    <Divider height={15} color={'transparent'} />
    <Snackbar
      {...Default.args}
      badge={{ text: 'New request', size: 'm', color: 'energized' }}
      dismissable={false}
    />
    <Divider height={15} color={'transparent'} />
    <Snackbar
      {...Default.args}
      badge={{ text: 'New request', size: 'm', color: 'assertive' }}
      dismissable={false}
    />
    <Divider height={15} color={'transparent'} />
    <Snackbar
      {...Default.args}
      badge={{ text: 'New request', size: 'm', color: 'assertive' }}
    />
    <Divider height={15} color={'transparent'} />
    <Snackbar
      {...Default.args}
      action={{ text: 'Custom action', action: '#' }}
    />
    <Divider height={15} color={'transparent'} />
  </>
);

export const WithFadeOut = () => (
  <Snackbar
    {...Default.args}
    badge={{ text: 'New request', size: 'm' }}
    dismissable={false}
    fadeOut={true}
  />
);

export const WithContent = () => (
  <Snackbar
    {...Default.args}
    content={{
      text: 'Please confirm or extend your return to work date by 01-10-2020',
    }}
    action={{
      text: 'Update now',
      action: '#',
      color: 'positive',
    }}
    dismissable={false}
  />
);

import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={20}
      height={21}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 10.5h6v-2H7v2zm0-4h6v-2H7v2zm9-2v-2c1.103 0 2 .898 2 2h-2zm-2 12c0 1.103-.897 2-2 2v-4H6v-10c0-1.102.897-2 2-2h6v14zm-4 2H4c-1.103 0-2-.897-2-2h8v2zm6-18H8a4 4 0 00-4 4v10H0v2a4 4 0 004 4h8a4 4 0 004-4v-10h4v-2a4 4 0 00-4-4z'
        fill='currentColor'
      />
    </svg>
  );
}

export default SvgComponent;


import React from 'react';
import Detail from './detail';
import { Divider } from '@components/ui';
import { Colors as ColorNames } from '@components/variables';

export default {
  title: 'Remark/text/Detail',
  component: Detail,
};

const Template = (args) => <Detail {...args} />;

export const Default = Template.bind({});
Default.args = {
  label: {
    text: 'Default label',
  },
  value: {
    text: 'Default value',
  },
};

export const Variants = () => (
  <>
    <Detail
      label={{ text: 'Default label' }}
      value={{ text: 'Default value' }}
    />
    <Detail
      label={{ text: 'Default label', color: 'dark' }}
      value={{ text: 'Default value', color: 'stable-900' }}
      reverse={true}
    />
    <Detail
      label={{ text: 'Default label' }}
      value={{ text: 'Default value', emphasis: true }}
    />
    <Detail
      label={{ text: 'Default label', color: 'dark', emphasis: true }}
      value={{ text: 'Default value', color: 'stable-900' }}
      reverse={true}
    />
  </>
);

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      return (
        <div key={index} style={{ flexDirection: 'row', display: 'flex' }}>
          <Detail
            label={{ text: 'Default label' }}
            value={{ text: 'Default value', color: color }}
          />
          <Divider width={15} color={'light'} />
          <Detail
            label={{ text: 'Default label' }}
            value={{ text: 'Default value', color: color, emphasis: true }}
          />
          <Divider width={15} color={'light'} />
          <Detail
            label={{ text: 'Default label', color: color }}
            value={{ text: 'Default value' }}
            reverse={true}
          />
        </div>
      );
    })}
  </>
);

export const Sizes = () => (
  <>
    <Detail
      label={{ text: 'Default label' }}
      value={{ text: 'Default value (h2)', size: 'h2' }}
    />
    <Detail
      label={{ text: 'Default label' }}
      value={{ text: 'Default value (h3)', size: 'h3' }}
    />
    <Detail
      label={{ text: 'Default label' }}
      value={{ text: 'Default value (h4)', size: 'h4' }}
    />
    <Detail
      label={{ text: 'Default label' }}
      value={{ text: 'Default value (h5)', size: 'h5' }}
    />
    <Detail
      label={{ text: 'Default label' }}
      value={{ text: 'Default value (h6)', size: 'h6' }}
    />
  </>
);

export const Disabled = () => (
  <Detail
    disabled={true}
    label={{ text: 'Default label' }}
    value={{ text: 'Default value' }}
  />
);

export const Row = () => (
  <Detail
    label={{ text: 'Default label' }}
    value={{ text: 'Default value' }}
    row={true}
  />
);
import React from 'react';
import SelectableCard from './selectable';
import { Spacings, Colors as ColorNames } from '@components/variables';
import ExpandableCard from '../expandable/expandable';
import { Divider } from '@components/ui';

export default {
  title: 'Remark/card/SelectableCard',
  component: SelectableCard,
};

const Template = (args) => <SelectableCard {...args} />;

export const Default = Template.bind({});

Default.args = {
  select: {
    name: 'name',
    value: 'value',
  },
  title: {
    text: 'Action required',
  },
  remove: {
    action: '#',
  },
};

export const Colors = () => (
  <>
    <SelectableCard {...Default.args} />
    <Divider height={15} color={'transparent'} />
    <SelectableCard {...Default.args} color={'stable-300'} />
  </>
);

import React from 'react';
import ContentCard from './content';
import { Colors as ColorNames } from '@components/variables';

export default {
  title: 'Remark/card/ContentCard',
  component: ContentCard,
};

const Template = (args) => <ContentCard {...args} />;

export const Default = Template.bind({});
Default.args = {
  image: 'https://picsum.photos/1024/400',
  title: {
    text: 'This is a title',
    color: 'dark',
  },
  content: {
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas posuere sapien quis leo tempus, eget ultrices ipsum sagittis. Sed consectetur ipsum sit amet erat feugiat, a cursus lorem tempor. ',
  },
  buttons: [
    {
      text: 'This is a button',
      action: '#',
    },
    {
      text: 'This is a button',
      action: '#',
      iconRight: 'bell',
    },
    {
      text: 'This is a button',
      action: '#',
      iconLeft: 'bell',
    },
  ],
};

export const Inline = () => (
  <>
    <ContentCard
      {...Default.args}
      title={{
        text: 'Title',
      }}
      content={{
        text:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas posuere sapien quis leo tempus, eget ultrices ipsum sagittis.',
      }}
      inline={true}
    />
  </>
);

export const ContentOnly = () => (
  <>
    <ContentCard
      title={{
        text: 'Title',
      }}
      content={{
        text:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas posuere sapien quis leo tempus, eget ultrices ipsum sagittis.',
      }}
    />
  </>
);

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      return (
        <ContentCard
          key={index}
          color={color}
          title={{
            text: 'Title',
          }}
          content={{
            text:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas posuere sapien quis leo tempus, eget ultrices ipsum sagittis.',
          }}
        />
      );
    })}
  </>
);

export const Sizes = () => (
  <>
    <ContentCard
      title={{
        text: 'Title',
      }}
      content={{
        text:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas posuere sapien quis leo tempus, eget ultrices ipsum sagittis.',
      }}
      size={'s'}
    />
    <ContentCard
      title={{
        text: 'Title',
      }}
      content={{
        text:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas posuere sapien quis leo tempus, eget ultrices ipsum sagittis.',
      }}
      size={'m'}
    />
    <ContentCard
      title={{
        text: 'Title',
      }}
      content={{
        text:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas posuere sapien quis leo tempus, eget ultrices ipsum sagittis.',
      }}
      size={'l'}
    />
    <ContentCard
      title={{
        text: 'Title',
      }}
      content={{
        text:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas posuere sapien quis leo tempus, eget ultrices ipsum sagittis.',
      }}
      size={'xl'}
    />
  </>
);

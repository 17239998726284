import React from 'react';
import PropTypes from 'prop-types';
import styles from './action.module.scss';
import classNames from 'classnames/bind';
import { Divider, Icon } from '@components/ui';
import { Wrapper } from '@components/card';
import { Title, Content } from '@components/text';
import { Button, IconRight, IconLeft } from '@components/button';
import { Colors, Spacings } from '@components/variables';
import { ContainerQuery } from 'react-container-query';
const queries = {
  small: {
    maxWidth: 300,
  },
};
const ActionCard = (props) => {
  // Define default size, colors here or in CSS?
  if (props.title) {
    props.title.size = props.title.size || 'h1';
    props.title.color = props.title.color || 'light';
  }

  if (props.content) {
    props.content.size = props.content.size || 's';
    props.content.color = props.content.color || 'light';
  }

  if (props.button) {
    props.button.color = props.button.color || 'light';
  }

  return (
    <ContainerQuery query={queries}>
      {(params) => (
        <Wrapper
          className={styles.action}
          size={params.small ? 'm' : props.size}
          color={props.color}
        >
          {props.title && <Title {...props.title} />}
          {props.content && <Content {...props.content} />}
          {props.buttons && <Divider height={15} color={'transparent'} />}
          {props.buttons && (
            <div className={styles.buttons}>
              {props.buttons.map((button, index) => {
                const Component = button.iconLeft
                  ? IconLeft
                  : button.iconRight
                  ? IconRight
                  : Button;
                button.icon = button.iconLeft
                  ? button.iconLeft
                  : button.iconRight
                  ? button.iconRight
                  : null;
                button.color = button.color || 'light';
                button.size = button.size || 'm';
                return <Component key={index} {...button} />;
              })}
            </div>
          )}
          {props.icon && (
            <Icon
              className={styles.icon}
              color={'light'}
              width={130}
              height={130}
              {...props.icon}
            />
          )}
        </Wrapper>
      )}
    </ContainerQuery>
  );
};

ActionCard.propTypes = {
  /** The size of the card */
  size: PropTypes.oneOf(Spacings),
  /** The color of the card */
  color: PropTypes.oneOf(Colors),
  /** The title of the card */
  title: PropTypes.shape(Title.propTypes),
  /** The content of the card */
  content: PropTypes.shape(Content.propTypes),
  /** The actions of the card */
  buttons: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
  /** The icon in the card */
  icon: PropTypes.shape(Icon.propTypes),
};

ActionCard.defaultProps = {
  color: 'energized',
  size: 'xl',
};

//Needed for Storybook
ActionCard.displayName = 'ActionCard';

export default ActionCard;

import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './insurance.module.scss';
import classNames from 'classnames/bind';
import { Icon, Divider } from '@components/ui';
import { Wrapper } from '@components/card';
import { Title, Detail, Content } from '@components/text';
import { IconRight, IconLeft, Button } from '@components/button';
import { Colors, Spacings } from '@components/variables';
const cx = classNames.bind(styles);
import { ContainerQuery } from 'react-container-query';

const queries = {
  'medium-card': {
    maxWidth: 420,
  },
};

const InsuranceCard = (props) => {
  const divWrapper = useRef();
  const classes = cx(props.className, props.containerQuery);
  const headClasses = cx('positive-300', {
    head: true,
  });

  useEffect(() => {
    if (divWrapper.current) {
      const id = props.id.startsWith('#') ? props.id : `#${props.id}`;
      const elem = document.querySelector(id);
      divWrapper.current && divWrapper.current.appendChild(elem);
      elem.classList.remove('u-hide');
      ReactRailsUJS.mountComponents(elem);
    }
  });

  return (
    <ContainerQuery query={queries}>
      {(params) => (
        <Wrapper
          className={`${classes} ${cx(params)}`}
          size={props.size}
          color={props.color}
        >
          <div className={headClasses}>
            {props.icon && (
              <Icon
                className={styles.icon}
                color={'positive'}
                {...props.icon}
              />
            )}
          </div>
          <div className={styles.body}>
            {props.title && <Title size={'h1'} {...props.title} />}
            {props.title && <Divider height={5} color={'transparent'} />}
            {props.content && <Content {...props.content} />}
            {props.content && <Divider height={20} color={'transparent'} />}
            {!props.content && props.id && (
              <Divider height={15} color={'transparent'} />
            )}
            {props.id && <div ref={divWrapper} />}
            {props.details && (
              <div className={styles.details}>
                {props.details.map((detail, index) => {
                  return (
                    <Detail
                      key={index}
                      className={index != 0 && styles.detail}
                      {...detail}
                    />
                  );
                })}
              </div>
            )}
            {props.buttons && <Divider height={20} color={'transparent'} />}
            {props.buttons && (
              <div className={styles.buttons}>
                {props.buttons.map((button, index) => {
                  const Component = button.iconLeft
                    ? IconLeft
                    : button.iconRight
                    ? IconRight
                    : Button;
                  button.icon = button.iconLeft
                    ? button.iconLeft
                    : button.iconRight
                    ? button.iconRight
                    : null;
                  button.color = button.color || 'positive-300';
                  return <Component key={index} size={'l'} {...button} />;
                })}
              </div>
            )}
          </div>
        </Wrapper>
      )}
    </ContainerQuery>
  );
};

InsuranceCard.propTypes = {
  /** The size of the card */
  size: PropTypes.oneOf(['no-p', ...Spacings]),
  /** The color of the card */
  color: PropTypes.oneOf(Colors),
  /** The title */
  title: PropTypes.shape(Title.propTypes),
  /** The content below the title */
  content: PropTypes.shape(Content.propTypes),
  /** The insurance details */
  details: PropTypes.arrayOf(PropTypes.shape(Detail.propTypes)),
  /** The buttons in the card */
  buttons: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
  /** The icon in the card header */
  icon: PropTypes.shape(Icon.propTypes),
  /** The id of the hidden div on the page, to be injected in the card */
  id: PropTypes.string,
};

InsuranceCard.defaultProps = {
  color: 'light',
  size: 'no-p',
};

//Needed for Storybook
InsuranceCard.displayName = 'InsuranceCard';

export default InsuranceCard;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './pie-chart.module.scss';
import classNames from 'classnames/bind';
import { Divider } from '@components/ui';
import { Wrapper } from '@components/card';
import { PieChart, LabelChart } from '@components/chart';
import { Title } from '@components/text';
import { Colors, Spacings } from '@components/variables';
const cx = classNames.bind(styles);

const PieChartCard = (props) => {

  return (
    <Wrapper size={props.size} color={props.color} header={props.header}>
      <div className={styles.body}>
        {props.data && <PieChart data={props.data} />}
        <div className={styles.labels}>
          {props.data &&
            props.data.map((item, index) => {
              const detail = {
                color: item.color,
                label: {
                  text: item.label,
                },
                value: {
                  text: item.value,
                },
              };
              return (
                <LabelChart key={index} className={styles.label} {...detail} />
              );
            })}
        </div>
      </div>
    </Wrapper>
  );
};

PieChartCard.propTypes = {
  /** The size of the card */
  size: PropTypes.oneOf(Spacings),
  /** The color of the card */
  color: PropTypes.oneOf(Colors),
  /** The title of the card */
  title: PropTypes.shape(Title.propTypes),
  pieChart: PropTypes.shape(PieChart.propTypes),
};

PieChartCard.defaultProps = {
  color: 'light',
  size: 'l',
};

//Needed for Storybook
PieChartCard.displayName = 'PieChartCard';

export default PieChartCard;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './logo.module.scss';
import classNames from 'classnames/bind';

/* ============================================== */
/* Explanation                                     */
/* run npx @svgr/cli < logo.svg > react-logo.js    */
/* or npx @svgr/cli < inline-logo.svg > react-inline-logo.js    */
/* in this folder to generate a 'reactified' logo  */
/* ==============================================  */
import ReactLogo from './react-logo.js';
import ReactInlineLogo from './react-inline-logo.js';

const cx = classNames.bind(styles);
const Logo = (props) => {
  let classes = cx(
    {
      logo: true,
    },
    props.color
  );

  return (
    <a className={classes} href={props.url} id="logo" data-turbolinks-permanent>
      {props.inline ? <ReactInlineLogo /> : <ReactLogo />}
    </a>
  );
};

Logo.propTypes = {
  /** The url of the logo */
  url: PropTypes.string.isRequired,
  /** When the inline variant of the logo is needed */
  inline: PropTypes.bool,
};

Logo.defaultProps = {
  url: '/',
};

//Needed for Storybook
Logo.displayName = 'Logo';

export default Logo;

import React from 'react';
import { Divider } from '@components/ui';
import OngoingCaseCard from './ongoing-case';

export default {
  title: 'Remark/card/OngoingCaseCard',
  component: OngoingCaseCard,
};

const Template = (args) => <OngoingCaseCard {...args} />;

export const Default = Template.bind({});

Default.args = {
  action: 'www.remarkgroup.com',
  badge: {
    text: 'Action required',
    color: 'energized',
  },
  details: [
    {
      label: {
        text: 'Case no.',
      },
      value: {
        text: '3510396',
      },
    },
    {
      label: {
        text: 'Policy contract no.',
      },
      value: {
        text: 'LA7160501-6AD',
      },
    },
  ],
};

export const Colors = () => (
  <>
    <OngoingCaseCard {...Default.args} />
    <Divider height={20} color={'transparent'} />
    <OngoingCaseCard
      color={'stable-300'}
      badge={{
        text: 'Action required',
        color: 'energized',
      }}
      details={[
        {
          label: {
            text: 'Case no.',
          },
          value: {
            text: '3510396',
          },
        },
        {
          label: {
            text: 'Policy contract no.',
          },
          value: {
            text: 'LA7160501-6AD',
          },
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <OngoingCaseCard
      color={'stable-300'}
      badge={{
        text: 'Action required',
        color: 'assertive',
      }}
      details={[
        {
          label: {
            text: 'Case no.',
          },
          value: {
            text: '3510396',
          },
        },
        {
          label: {
            text: 'Policy contract no.',
          },
          value: {
            text: 'LA7160501-6AD',
          },
        },
      ]}
    />
  </>
);

import React from 'react';
import PropTypes from 'prop-types';
import styles from './shape.module.scss';
import classNames from 'classnames/bind';
import * as Shapes from './react-shapes';
/* ============================================== */
/* Explanation                                     */
/* run npx @svgr/cli -d react-shapes svg-shapes    */
/* in the root of this folder to generate the      */
/* 'reactified' shapes                             */
/* ==============================================  */

const cx = classNames.bind(styles);

const Shape = (props) => {
  let classes = cx(
    {
      shape: true,
      single: props.navbar == 'single',
      double: props.navbar == 'double',
    },
    props.shape.charAt(0).toLowerCase() + props.shape.slice(1)
  );
  const SVG = Shapes[props.shape];
  return (
    <div className={classes}>
      <SVG />
    </div>
  );
};

Shape.propTypes = {
  /** The shape to use */
  shape: PropTypes.oneOf([
    'CirclesBottomLeft',
    'CirclesBottomRight',
    'CirclesTopRight',
    'TrianglesBottomLeft',
    'TrianglesTopLeft',
    'TrianglesTopRight',
  ]),
  /** Whether the shape is shown below the navbar */
  navbar: PropTypes.oneOf(['single', 'double']),
};

Shape.defaultProps = {
  shape: 'CirclesTopRight',
};

//Needed for Storybook
Shape.displayName = 'Shape';

export default Shape;

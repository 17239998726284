import React from 'react';
import PropTypes from 'prop-types';
import styles from './ongoing-case.module.scss';
import classNames from 'classnames/bind';
import { Divider } from '@components/ui';
import { Wrapper } from '@components/card';
import { Badge } from '@components/badge';
import { Detail } from '@components/text';
import { Colors, Spacings } from '@components/variables';
import { ContainerQuery } from 'react-container-query';

const cx = classNames.bind(styles);
const queries = {
  'small-card': {
    maxWidth: 320,
  },
  'medium-card': {
    minWidth: 321,
    maxWidth: 420,
  },
};

const OngoingCaseCard = (props) => {
  const classes = cx(props.className, props.containerQuery);
  if (props.badge) {
    props.badge.size = props.badge.size ? props.badge.size : 'm';
  }
  return (
    <ContainerQuery query={queries}>
      {(params) => (
        <Wrapper
          className={`${classes} ${cx(params)}`}
          size={props.size}
          color={props.color}
          action={props.action}
        >
          <div className={styles.ongoingCase}>
            <div className={styles.details}>
              {props.details &&
                props.details.map((detail, index) => {
                  if (index == 0) {
                    detail.value.emphasis = true;
                    detail.value.color = detail.value.color
                      ? detail.value.color
                      : 'positive';
                  }
                  return (
                    <Detail
                      className={index != 0 && styles.detail}
                      key={index}
                      {...detail}
                    />
                  );
                })}
            </div>
            {props.badge && <Divider width={15} color={'transparent'} />}
            {props.badge && <Badge {...props.badge} />}
          </div>
        </Wrapper>
      )}
    </ContainerQuery>
  );
};

OngoingCaseCard.propTypes = {
  /** The size of the card */
  size: PropTypes.oneOf(Spacings),
  /** The color of the card */
  color: PropTypes.oneOf(Colors),
  /** The badge inside the case */
  badge: PropTypes.shape(Badge.propTypes),
  /** The case details */
  details: PropTypes.arrayOf(PropTypes.shape(Detail.propTypes)),
};

OngoingCaseCard.defaultProps = {
  color: 'light',
  size: 'l',
};

//Needed for Storybook
OngoingCaseCard.displayName = 'OngoingCaseCard';

export default OngoingCaseCard;

import React from 'react';
import Timeline from './timeline';
import { Divider } from '@components/ui';
import { Colors as ColorNames } from '@components/variables';
import Steps from '../timeline/timeline';

export default {
  title: 'Remark/progress/Timeline',
  component: Timeline,
};

const Template = (args) => <Timeline {...args} />;

export const Default = Template.bind({});
Default.args = {
  steps: [
    {
      label: {
        text: 'Under review',
      },
      value: {
        text: 'Extra content',
      },
      date: {
        text: '08-07-2020, 2:35PM'
      },
      status: 'active',
    },
    {
      label: {
        text: 'Ready for review',
      },
      date: {
        text: '08-07-2020, 2:35PM'
      },
      status: 'done',
    },
    {
      label: {
        text: 'Draft',
      },
      date: {
        text: '08-07-2020, 2:35PM'
      },
      status: 'done',
    },
  ],
  button: {
    text: 'View details',
  },
};

export const WithDate = () =>
  <Timeline
    steps={[
      {
        label: {
          text: 'Ready for review',
        },
        date: {
          text: '08-07-2020, 2:35PM'
        },
        status: 'active',
      },
      {
        label: {
          text: 'Draft',
        },
        date: {
          text: '08-07-2020, 2:35PM'
        },
        status: 'done',
      },
    ]}
  />;

export const Progress = () => (
  <Timeline
    {...Default.args}
    type={'progress'}
    steps={[
      {
        label: {
          text: 'Accepted or declined',
        },
        value: {
          text: '',
        },
        status: 'open',
      },
      {
        label: {
          text: 'Under review',
        },
        value: {
          text: '08-07-2020, 2:35PM',
        },
        status: 'active',
      },
      {
        label: {
          text: 'Ready for review',
        },
        value: {
          text: '08-07-2020, 2:35PM',
        },
        status: 'done',
      },
      {
        label: {
          text: 'Draft',
        },
        value: {
          text: '08-07-2020, 2:35PM',
        },
        status: 'done',
      },
    ]}
  />
);

export const Endless = () => <Timeline endless={true} {...Default.args} />;

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      const bgColor =
        color == 'positive-300'
          ? `rgba(var(--color-${color}), 0.15)`
          : `rgba(var(--color-${color}), 1)`;
      return (
        <div key={index}>
          <div style={{ backgroundColor: bgColor }}>
            <Divider height={15} color={'transparent'} />
            <Timeline {...Default.args} color={color} />
          </div>
          <Divider height={15} color={'transparent'} />
        </div>
      );
    })}
  </>
);

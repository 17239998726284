import React from 'react';
import PropTypes from 'prop-types';
import styles from './content.module.scss';
import classNames from 'classnames/bind';
import { Colors, Alignments, Sizes } from '@components/variables';
const cx = classNames.bind(styles);

const Content = (props) => {
  let classes = cx(
    {
      content: true,
      emphasis: props.emphasis,
      [`font-size-${props.size}`]: props.size,
    },
    props.color,
    props.align,
    props.className
  );
  return <p className={classes}>{props.text}</p>;
};

Content.propTypes = {
  /** The text of the content */
  text: PropTypes.string.isRequired,
  /** The color of the content */
  color: PropTypes.oneOf(Colors),
  /** The alignment of the content */
  align: PropTypes.oneOf(Alignments),
};

Content.defaultProps = {
  text: 'Missing text prop',
  align: 'left',
  color: 'stable-900',
  size: 'm',
};

//Needed for Storybook
Content.displayName = 'Content';

export default Content;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './label.module.scss';
import classNames from 'classnames/bind';
import { Detail } from '@components/text';
import { Colors, Spacings } from '@components/variables';
const cx = classNames.bind(styles);

const LabelChart = (props) => {
  let classes = cx(props.className, props.color, props.size, {
    label: props.label,
  });
  let value = props.value;
  value.size = props.value.size || 'h2';
  return (
    <div className={classes}>
      <Detail className={styles.detail} label={props.label} value={value} />
    </div>
  );
};

LabelChart.propTypes = {
  /** The size of the label */
  size: PropTypes.oneOf(Spacings),
  /** The color of the label */
  color: PropTypes.oneOf(Colors),
  /** The details of the label */
  detail: PropTypes.shape(Detail.propTypes),
};

LabelChart.defaultProps = {
  color: 'positive',
  size: 's',
};

//Needed for Storybook
LabelChart.displayName = 'LabelChart';

export default LabelChart;

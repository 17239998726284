import React from 'react';
import PropTypes from 'prop-types';
import styles from './group.module.scss';
import classNames from 'classnames/bind';
import Option from '../option/option';

const cx = classNames.bind(styles);

const Group = (props) => {
  const classes = cx({
    wrapper: true,
  });

  const optionClasses = cx({
    option: true,
    fullWidth: props.fullWidth,
  });
  return (
    <div className={classes}>
      {props.options &&
        props.options.map((option, index) => {
          return (
            <Option
              key={index}
              id={index > 0 ? `${props.id}_${index}` : props.id}
              name={props.name}
              type={props.type}
              disabled={props.disabled}
              className={optionClasses}
              attributes={props.attributes}
              input={props.input}
              {...option}
            />
          );
        })}
    </div>
  );
};

Group.propTypes = {
  /** The options  */
  options: PropTypes.arrayOf(PropTypes.shape(Option.propTypes)),
};

Group.defaultProps = {};

//Needed for Storybook
Group.displayName = 'Group';

export default Group;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './kpi.module.scss';
import classNames from 'classnames/bind';
import { Wrapper } from '@components/card';
import { Title, Content } from '@components/text';
import { Colors, Spacings } from '@components/variables';
import { Icon } from '@components/ui';
const cx = classNames.bind(styles);

const KpiCard = (props) => {
  const color = props.iconColor
    ? props.iconColor
    : props.action
      ? 'positive'
      : 'dark';
  const classes = cx(
    {
      kpi: true,
      action: props.action,
    },
    props.className
  );

  const iconClasses = cx(color, {
    icon: true,
  });

  return (
    <Wrapper
      className={classes}
      size={props.size}
      color={props.color}
      action={props.action}
    >
      {props.icon && (
        <div className={iconClasses}>
          <Icon color={color} {...props.icon} />
        </div>
      )}
      <div>
        {props.label && <Content {...props.label} />}
        {props.title && (
          <div className={styles.count}>
            <Title color={color} {...props.title} />
            {props.action && (
              <Icon
                width={12}
                height={12}
                icon={'chevron-right'}
                color={color}
                className={styles.arrow}
              />
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
};

KpiCard.propTypes = {
  /** The size of the card */
  size: PropTypes.oneOf(Spacings),
  /** The color of the card */
  color: PropTypes.oneOf(Colors),
  /** The color of the icon & text in a clickable card */
  iconColor: PropTypes.oneOf(Colors),
  /** The label of the card */
  label: PropTypes.shape(Content.propTypes),
  /** The title (count) of the card */
  title: PropTypes.shape(Title.propTypes),
  /** The icon in the card */
  icon: PropTypes.shape(Icon.propTypes),
  /** The action of the card */
  action: PropTypes.string,
};

KpiCard.defaultProps = {
  color: 'light',
  size: 'l',
};

//Needed for Storybook
KpiCard.displayName = 'KpiCard';

export default KpiCard;
